import { useMemo } from 'react';
import uniq from 'lodash/uniq';
import useSegments from '@piano-reports/summary-widgets/src/api/useSegments';
import { SegmentCategories } from '@piano-data/cx-client';

const identity = () => true;

export const categoriesToDisplay = [
  SegmentCategories.COMPOSER,
  SegmentCategories.CUSTOM,
  SegmentCategories.INTEREST,
];

export default function useSegmentGroups(
  filter?: (group: string | undefined) => boolean,
): string[] {
  const segments = useSegments({});

  return useMemo<string[]>(() => {
    return segments
      ? (uniq(
          segments
            .filter(
              (segment) =>
                segment.category &&
                categoriesToDisplay.includes(segment.category),
            )
            .map((segment) => segment.group),
        ).filter(filter ?? identity) as string[])
      : [];
  }, [segments, filter]);
}
