import { Preset } from '../interfaces';

export const vxPresets: Preset[] = [
  {
    name: 'High frequency subscribers',
    description: '',
    items: ['Subscribers', '10+ visit days last month'],
  },
  {
    name: 'Low recency subscribers',
    description: '',
    items: ['Subscribers', 'Active last 15+ days'],
  },
];
