import { Segment as SegmentTypes } from '@piano-data/cx-client';
import { Period } from '@piano-react/utils/dates/types';
export { Period };

export enum HistoryResolution {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
}

export interface DateRange {
  start?: Date;
  stop?: Date;
  offset?: string;
  historyResolution?: HistoryResolution;
  period?: Period | null;
}

export type Segment = SegmentTypes.TrafficSegment;

export type ChartValue = Record<string, number | string | null>;

export type ChartData = ChartValue[];
