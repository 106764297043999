import React from 'react';
import SegmentReportError from '../SegmentReportError';
import styles from './SegmentSummary.module.css';

export interface Props {
  error: Error;
  resetErrorBoundary: () => void;
  className?: string;
}

const SegmentSummaryError = (props: Props) => (
  <SegmentReportError {...props} className={styles.error} />
);

export default SegmentSummaryError;
