import React from 'react';

const BarSkeleton = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1064 190"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <rect y="63" width="30" height="128" fill="#0A0E12" fillOpacity="0.05" />
    <rect
      x="35"
      y="53"
      width="29"
      height="138"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="69"
      y="39"
      width="30"
      height="152"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="104"
      y="15"
      width="29"
      height="176"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="138"
      y="53"
      width="30"
      height="138"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="173"
      y="87"
      width="29"
      height="104"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="207"
      y="68"
      width="30"
      height="123"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="242"
      y="63"
      width="29"
      height="128"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="276"
      y="49"
      width="30"
      height="142"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="311"
      y="25"
      width="29"
      height="166"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="345"
      y="73"
      width="30"
      height="118"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="380"
      y="87"
      width="29"
      height="104"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="414"
      y="97"
      width="30"
      height="94"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="449"
      y="87"
      width="29"
      height="104"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="483"
      y="68"
      width="30"
      height="123"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="518"
      y="49"
      width="29"
      height="142"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="552"
      y="53"
      width="30"
      height="138"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="587"
      y="63"
      width="29"
      height="128"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="621"
      y="73"
      width="30"
      height="118"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="656"
      y="77"
      width="29"
      height="114"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="690"
      y="39"
      width="30"
      height="152"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="725"
      y="20"
      width="29"
      height="171"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect x="759" width="30" height="191" fill="#0A0E12" fillOpacity="0.05" />
    <rect
      x="794"
      y="20"
      width="29"
      height="171"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="828"
      y="29"
      width="30"
      height="162"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="863"
      y="53"
      width="29"
      height="138"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="897"
      y="44"
      width="30"
      height="147"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="932"
      y="34"
      width="29"
      height="157"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="966"
      y="49"
      width="30"
      height="142"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="1001"
      y="24"
      width="29"
      height="167"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="1034"
      y="73"
      width="30"
      height="118"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
  </svg>
);

export default BarSkeleton;
