import React from 'react';
import cn from 'classnames';
import withClass from '@piano-react/components/withClass';
import styles from './Table.module.css';

export interface TableProps {
  children: React.ReactNode;
  className?: string;
}

export const Table = ({ children, className }: TableProps) => (
  <table
    className={cn(styles.table, className)}
    cellPadding={0}
    cellSpacing={0}
  >
    {children}
  </table>
);

export const TableBody = withClass.tbody('');
