import env from './env';

export const isIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const sendVXMessage = (type: string, data?: Record<string, any>) => {
  window.parent.postMessage(
    JSON.stringify({
      eventType: type,
      eventData: data,
    }),
    env.REACT_APP_VX_API_HOST,
  );
};

export const isTargetMode = () => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.has('cardId');
};
