import React from 'react';
import withClass from '@piano-react/components/withClass';
import useSegmentData, { SegmentData } from '../../../api/useSegmentData';
import { DateRange, Segment } from '../../../types';
import usePageviewPercent from '../../../hooks/usePageviewPercent';
import Gauge from '../../GaugeChart';
import styles from './GaugeChart.module.css';

export const GaugeContainer = withClass.div(styles.container);

export interface Props {
  segment: Segment;
  filterSegmentId?: string;
  range: DateRange;
}

const GaugeChart = ({ segment, filterSegmentId, range }: Props) => {
  const { data } = useSegmentData({
    segmentId: segment.id,
    filterSegmentId,
    range,
  });

  const percent = usePageviewPercent(data as SegmentData, range);

  return (
    <GaugeContainer>
      <Gauge
        type="middle"
        pageViewPercent={percent}
        coveragePercent={data?.data.audienceCoverage}
      />
    </GaugeContainer>
  );
};

export default GaugeChart;
