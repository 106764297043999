import React from 'react';
import SegmentContentSkeleton from './SegmentContent/SegmentContentSkeleton';
import { Container } from './index';

export interface Props {
  className?: string;
}

const SegmentTileSkeleton = ({ className }: Props) => (
  <Container className={className}>
    <SegmentContentSkeleton />
  </Container>
);

export default SegmentTileSkeleton;
