import React from 'react';
import AnimatedBar from '@piano-insight/charts/components/Bar/AnimatedBar';
import { BarItemProps } from '@piano-insight/charts/components/Bar/StaticBar';
import styles from './StyledBar.module.css';

const StyledBar = (props: BarItemProps) => (
  <AnimatedBar {...props} color={''} className={styles.bar} />
);

export default StyledBar;
