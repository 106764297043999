import { SegmentData } from '../api/useSegmentData';
import { DateRange } from '../types';
import useTotalTrafficQuery from '../api/useTotalTrafficQuery';

export default function usePageviewPercent(
  data: SegmentData,
  range: DateRange,
): number {
  const { data: totalData } = useTotalTrafficQuery({ range });

  return data?.data.events && totalData?.data.events
    ? data?.data.events / totalData.data.events
    : 0;
}
