import React, { Suspense } from 'react';
import { DateRange, Segment } from '../../types';
import SegmentReportError from '../SegmentReportError';
import ErrorBoundary from '../ErrorBoundary';
import DataTable from './DataTable';
import DataTableSkeleton from './DataTable/DataTableSkeleton';

export interface Props {
  segmentIds?: string[];
  segments?: Segment[];
  filterSegmentId?: string;
  className?: string;
  range: DateRange;
  headerTop?: number;
}

const SegmentTable = React.memo((props: Props) => {
  return (
    <ErrorBoundary FallbackComponent={SegmentReportError}>
      <Suspense fallback={<DataTableSkeleton />}>
        <DataTable {...props} />
      </Suspense>
    </ErrorBoundary>
  );
});

export default SegmentTable;
