import React from 'react';
import cn from 'classnames';
import styles from './Rows.module.css';

export interface RowProps {
  children?: React.ReactNode;
  className?: string;
}

export const Row = ({ children, className }: RowProps) => (
  <tr className={cn(styles.row, className)}>{children}</tr>
);

export interface CellProps {
  children?: React.ReactNode;
  className?: string;
  width?: number | string;
  withBorder?: boolean;
}

export const Cell = ({ children, className, width, withBorder }: CellProps) => (
  <td
    className={cn(styles.cell, { [styles.withBorder]: withBorder }, className)}
    style={{ width }}
  >
    {children}
  </td>
);
