import React from 'react';
import withClass from '@piano-react/components/withClass';
import StickyHeaderStackProvider from '@piano-reports/ui-kit/src/StickyHeaderStack/StickyHeaderStackProvider';
import useReport from '../../hooks/useReport';
import SegmentSummaryTile from '../SegmentSummaryTile';
import Segments from '../Segments';
import EmptyReport from './EmptyReport';
import styles from './Report.module.css';

export const Container = withClass.div(styles.container);

const Report: React.FC = React.memo(() => {
  const { report } = useReport();

  return (
    <Container>
      <StickyHeaderStackProvider className={styles.sticky}>
        {report ? (
          <>
            <SegmentSummaryTile report={report} />
            {report.segments.length > 0 ? <Segments report={report} /> : null}
          </>
        ) : (
          <EmptyReport />
        )}
      </StickyHeaderStackProvider>
    </Container>
  );
});

export default Report;
