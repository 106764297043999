import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RangeSelector,
  PeriodItem,
} from '@piano-react/components/RangeSelector';
import { Period } from '@piano-reports/summary-widgets/src/types';
import useDateRange from '../../hooks/useDateRange';
import { getDateRange } from '../../utils/datepicker';
import SidebarSelector from './SidebarSelector';

const DateRangePicker = React.memo(() => {
  const { t, i18n } = useTranslation();
  const { range, setRange } = useDateRange();

  const handleClick = (periodItem: PeriodItem) => {
    setRange(getDateRange(periodItem.period as Period));
  };

  const periods: PeriodItem[] = useMemo(
    () => [
      {
        id: Period.LAST_24_HOURS,
        period: Period.LAST_24_HOURS,
        label: t('datepicker.lastDay', 'Last 24 hours'),
      },
      {
        id: Period.LAST_7_DAYS,
        period: Period.LAST_7_DAYS,
        label: t('datepicker.lastWeek', 'Last 7 days'),
      },
      {
        id: Period.LAST_30_DAYS,
        period: Period.LAST_30_DAYS,
        label: t('datepicker.lastMonth', 'Last 30 days'),
      },
    ],
    [t],
  );

  const selected = useMemo(
    () => periods.find((item) => item.period === range.period),
    [periods, range.period],
  );

  return (
    <RangeSelector
      periods={periods}
      selected={selected}
      onChange={handleClick}
      locale={i18n.language}
      trigger={({ triggerProps }) => (
        <SidebarSelector {...triggerProps}>
          {selected
            ? selected.label
            : t('datepicker.selectPeriod', 'Select period')}
        </SidebarSelector>
      )}
    />
  );
});

export default DateRangePicker;
