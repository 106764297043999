import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { defaultTransition } from '@piano-react/components/animateConfig';
import GhostButton from '@piano-dc/react/button-ghost';
import Icon from '@piano-reports/ui-kit/src/Icon';
import { PopoverContext } from '@piano-dc/react/popover';
import { BRAND_RELATIONSHIP } from '../../../constants';
import useSegmentGroups from '../../../hooks/useSegmentGroups';
import { SegmentGroupDroplist } from '../../SegmentGroupsDroplist';
import { GroupWithKey } from '../index';
import styles from './AddGroup.module.css';

const variants = {
  collapsed: { width: 'auto', height: '24px' },
  expanded: { width: '100%', height: '30px' },
};

export interface Props {
  onSelect(group: string): void;
  selectedGroups?: GroupWithKey[];
}

const AddGroup = ({ onSelect, selectedGroups }: Props) => {
  const { t } = useTranslation();
  const { hidePopover } = useContext(PopoverContext);

  const allGroups = useSegmentGroups((group) => group !== BRAND_RELATIONSHIP);

  const handleSelect = (group: string) => {
    onSelect(group);
    hidePopover();
  };

  return allGroups.length !== selectedGroups?.length ? (
    <SegmentGroupDroplist
      onSelect={handleSelect}
      selectedGroups={selectedGroups?.map(g => g.id)}
      position="right top"
      withTriggerAnchor
      trigger={({ opened, triggerProps, originProps }) => (
        <motion.div
          className={styles.buttonContainer}
          {...originProps}
          layout="position"
          transition={defaultTransition(0.2)}
        >
          <motion.div
            className={cn(styles.button, { [styles.active]: opened })}
            variants={variants}
            initial="collapsed"
            animate={opened ? 'expanded' : 'collapsed'}
            transition={defaultTransition(0.3)}
          >
            <GhostButton
              {...triggerProps}
              size="11pt"
              icon={<Icon name="plus-small" data-tour="group-add" />}
              className={cn({ [styles.buttonActive]: opened })}
            >
              {t('customize.addGroup', 'Add a group')}
            </GhostButton>
          </motion.div>
        </motion.div>
      )}
    />
  ) : null;
};

export default AddGroup;
