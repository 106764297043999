import React from 'react';
import { useTranslation } from 'react-i18next';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import Tooltip from '@piano-insight/charts/components/HTMLTooltip/Tooltip';
import formatPercent from '@piano-react/utils/numberFormat/formatPercent';
import formatInteger from '@piano-react/utils/numberFormat/formatInteger';
import { Modal } from '@piano-react/components/Modal';
import { DATA_1, DATA_2 } from '../../../colors';
import { AUDIENCE_COVERAGE_KEY, EVENTS_KEY } from '../../../consts';
import TooltipContainer from './TooltipContainer';
import styles from './ChartTooltip.module.css';

const Content = withClass.div(styles.content);
const Header = withClass(Typography, styles.header);
const Row = withClass.div(styles.row);
const Key = withClass(Typography, styles.key);
const Value = withClass(Typography, styles.value);
const LegendIcon = withClass.div(styles.icon);

interface Props {
  timeFormat: (value: string) => string;
}

const ChartTooltip = ({ timeFormat }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal id="tooltip">
      <Tooltip Container={TooltipContainer}>
        {(tooltipData) => {
          const audienceCoverage = tooltipData?.[AUDIENCE_COVERAGE_KEY];
          const pageviews = tooltipData?.[EVENTS_KEY];

          return tooltipData ? (
            <Content>
              <Header variant="single-9-5-pt">
                {timeFormat(tooltipData.index as string)}
              </Header>
              <Row>
                <LegendIcon style={{ background: DATA_2 }} />
                <Key variant="single-11-pt">
                  {t('chartTooltip.audienceCoverage', 'Audience coverage')}
                </Key>
                <Value variant="single-11-pt">
                  {audienceCoverage ? formatPercent(+audienceCoverage) : '-'}
                </Value>
              </Row>
              <Row>
                <LegendIcon style={{ background: DATA_1 }} />
                <Key variant="single-11-pt">
                  {t('chartTooltip.pageviews', 'Pageviews')}
                </Key>
                <Value variant="single-11-pt">
                  {pageviews ? formatInteger(+pageviews) : '-'}
                </Value>
              </Row>
            </Content>
          ) : null;
        }}
      </Tooltip>
    </Modal>
  );
};

export default ChartTooltip;
