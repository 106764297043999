import { createContext } from 'react';
import { Preset, Report } from '../../interfaces';

export interface HistoricalPresetsContextType {
  setPreset(report: Report, saveOrigin?: boolean): void;
  savedPresets: Preset[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const HistoricalPresetsContext = createContext<HistoricalPresetsContextType>({
  setPreset: noop,
  savedPresets: [],
});

export default HistoricalPresetsContext;
