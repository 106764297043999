import { useMemo } from 'react';
import { SegmentData, SegmentDataFields } from '../api/useSegmentData';
import { ChartData, ChartValue } from '../types';

export default function useSegmentChartData(
  segmentData: SegmentData,
): ChartData {
  return useMemo<ChartData>(() => {
    if (!segmentData) return [] as ChartData;

    const keys = Object.keys(segmentData.historyData) as SegmentDataFields[];

    const data: ChartData = [];

    segmentData.history.forEach((date, i) => {
      // history has N+1 value
      if (i < segmentData.history.length - 1) {
        const item: ChartValue = { index: date.toISOString() };

        keys.forEach((key) => {
          const values = segmentData.historyData[key];
          item[key] = values ? values[i] : null;
        });

        data.push(item);
      }
    });

    return data;
  }, [segmentData]);
}
