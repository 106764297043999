import React, { useContext } from 'react';
import { Droplist } from '@piano-dc/react/droplist';
import { Sorting } from '@piano-reports/summary-widgets/src/hooks/useSortedSegments';
import { PopoverContext } from '@piano-dc/react/popover';
import SortingTrigger from './SotringTrigger';
import SortingVariants from './SortingVariants';

export interface Props {
  sorting: Sorting | undefined;
  onSelect(sorting: Sorting | undefined): void;
}

export const SortingSelector = ({ sorting, onSelect }: Props) => {
  const { hidePopover } = useContext(PopoverContext);

  const handleSelect = (newSorting: Sorting | undefined) => {
    onSelect(newSorting);
    hidePopover();
  };

  return (
    <Droplist
      maxHeight={350}
      position="bottom right"
      trigger={({ triggerProps }) => (
        <SortingTrigger field={sorting?.field ?? null} {...triggerProps} />
      )}
    >
      <SortingVariants sorting={sorting} onSelect={handleSelect} />
    </Droplist>
  );
};

export default SortingSelector;
