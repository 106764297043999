import React from 'react';
import cn from 'classnames';
import capitialize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import Icon from '@piano-reports/ui-kit/src/Icon';
import GhostButton from '@piano-dc/react/button-ghost';
import Typography from '@piano-dc/react/typography';
import withClass from '@piano-react/components/withClass';
import SidebarPanel from '../SidebarPanel';
import useReport from '../../hooks/useReport';
import { formatSegments } from '../../utils/segments';
import PresetSelect from './PresetSelect';
import styles from './ReportDescription.module.css';

export interface Props {
  onEditClick(): void;
  onHeaderClick(): void;
}

const EditCriteriaButton = withClass(GhostButton, styles.button);

const ReportDescription = React.memo(
  ({ onEditClick, onHeaderClick }: Props) => {
    const { t } = useTranslation();
    const { report } = useReport();

    const statements = formatSegments(report?.segments ?? []);

    return (
      <SidebarPanel
        main
        icon={<Icon name="folder-default" />}
        header={
          <PresetSelect onClick={onHeaderClick}>{report?.name}</PresetSelect>
        }
      >
        <div className={styles.description}>
          <div className={styles.content}>
            {statements.map((statement, index) => (
              <span
                key={index}
                className={cn(styles.group, {
                  [styles.spaced]:
                    statement.length > 3 && (report?.segments.length ?? 0) > 9,
                })}
              >
                {statement.map((item, i) => {
                  const content =
                    typeof item === 'string' ? item : item.caption;
                  return (
                    <Typography
                      key={`${index}_${i}`}
                      variant="multi-13-pt"
                      className={cn({
                        [styles.main]: typeof item !== 'string',
                      })}
                    >
                      {index === 0 && i === 0 ? capitialize(content) : content}
                    </Typography>
                  );
                })}
              </span>
            ))}
          </div>
          <EditCriteriaButton
            size="11pt"
            icon={<Icon name="edit-default" data-tour="customize" />}
            onClick={onEditClick}
          >
            {t('sidebar.editCriteria', 'Edit criteria')}
          </EditCriteriaButton>
        </div>
      </SidebarPanel>
    );
  },
);

export default ReportDescription;
