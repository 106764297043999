import React from 'react';
import { ChartContainer, CHART_PADDING } from './index';
import BarSkeleton from './BarsSkeleton';
import styles from './ChartSkeleton.module.css';

const ChartSkeleton = () => (
  <ChartContainer>
    <div className={styles.barSkeleton} style={{ ...CHART_PADDING}}>
      <BarSkeleton />
    </div>
  </ChartContainer>
);

export default ChartSkeleton;
