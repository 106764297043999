import React from 'react';
import TooltipWrapper from '@piano-insight/charts/components/HTMLTooltip/TooltipWrapper';
import { TooltipContainerProps } from '@piano-insight/charts/components/HTMLTooltip/DefaultTooltipContainer';
import useTooltipInViewport from '@piano-insight/charts/components/HTMLTooltip/useTooltipInViewport';
import styles from './ChartTooltip.module.css';

const OFFSET = 10;
const TOOLTIP_DEFAULT_HEIGHT = 95;

const TooltipContainer = ({
  left = 0,
  top = 0,
  children,
}: TooltipContainerProps) => {
  const [ref, { x, y }] = useTooltipInViewport('left bottom', left, top);

  return (
    <TooltipWrapper
      className={styles.tooltip}
      x={x ? x - OFFSET : x}
      y={y ? y + OFFSET : y}
      initialX={left}
      initialY={top - TOOLTIP_DEFAULT_HEIGHT}
      ref={ref}
    >
      {children}
    </TooltipWrapper>
  );
};

export default TooltipContainer;
