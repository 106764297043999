import React from 'react';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import styles from './FilterList.module.css';

const DelimiterWrapper = withClass.div(styles.delimiterContainer);

export interface Props {
  children?: string;
}

const Delimiter = ({ children }: Props) => (
  <DelimiterWrapper>
    {children && (
      <Typography variant="single-11-pt" className={styles.delimiter}>
        {children}
      </Typography>
    )}
  </DelimiterWrapper>
);

export default Delimiter;
