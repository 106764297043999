import React from 'react';
import { useTranslation } from 'react-i18next';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import Icon from '@piano-reports/ui-kit/src/Icon';
import StickyNode from '@piano-reports/ui-kit/src/StickyHeaderStack/StickyNode';
import styles from './TitlePanel.module.css';

export const Panel = withClass.div(styles.panel);
const Button = withClass.div(styles.button);

export interface Props {
  title: string;
  onToggleClick(): void;
  collapsed?: boolean;
}

const TitlePanel = ({ title, onToggleClick, collapsed }: Props) => {
  const { t } = useTranslation();

  return (
    <StickyNode top={50}>
      <Panel>
        <Typography variant="single-15-pt">{title}</Typography>
        <Button onClick={onToggleClick}>
          <Icon name="report-bar-chart-small" className={styles.icon} />
          <Typography variant="single-9-5-pt">
            {collapsed
              ? t(
                  'segmentSummaryTile.titlePanel.summaryGraph.show',
                  'Show summary graph',
                )
              : t(
                  'segmentSummaryTile.titlePanel.summaryGraph.hide',
                  'Hide summary graph',
                )}
          </Typography>
        </Button>
      </Panel>
    </StickyNode>
  );
};

export default TitlePanel;
