import React, { FC } from 'react';
import cn from 'classnames';
import Typography from '@piano-dc/react/typography';
import withClass from '@piano-react/components/withClass';
import styles from './Singleton.module.css';

export enum SingletonSize {
  LARGE = 'large',
  SMALL = 'small',
}

export const Container = withClass.div(styles.container);
export const DotBadge = withClass.div(styles.dotBadge);
export const DotBadgeSmall = withClass(DotBadge, styles.dotBadgeSmall);
export const DotBadgeLarge = withClass(DotBadge, styles.dotBadgeLarge);
export const Title = withClass(Typography, styles.title);
export const Content = withClass.div(styles.content);

export interface SingletonProps {
  title: string;
  size?: 'large' | 'small';
  content: React.ReactNode;
  description?: string | React.ReactNode;
  color?: string;
  className?: string;
}

export interface SmallSingletonProps
  extends Omit<SingletonProps, 'description' | 'size'> {}

const SmallSingleton: FC<SmallSingletonProps> = ({
  title,
  content,
  color,
  className,
}) => (
  <Container className={cn(styles.containerSmall, className)}>
    {color && <DotBadgeSmall style={{ background: color }} />}
    <Title variant="single-13-pt">{title}</Title>
    <Content>
      <Typography variant="single-15-pt">{content}</Typography>
    </Content>
  </Container>
);

export interface LargeSingletonProps extends Omit<SingletonProps, 'size'> {}

const LargeSingleton: FC<LargeSingletonProps> = ({
  title,
  content,
  description,
  color,
  className,
}) => (
  <Container className={cn(styles.containerLarge, className)}>
    {color && <DotBadgeLarge style={{ background: color }} />}
    <Title variant="single-15-pt">{title}</Title>
    <Content>
      <Typography variant="single-24-pt">{content}</Typography>
    </Content>
    <span>{description}</span>
  </Container>
);

const Singleton: FC<SingletonProps> = ({
  size = SingletonSize.LARGE,
  ...rest
}) => {
  const Component =
    size === SingletonSize.LARGE ? LargeSingleton : SmallSingleton;
  return <Component {...rest} />;
};

export default Singleton;
