import React, { Suspense, useMemo } from 'react';
import { createPianoFetch } from '@piano-data/fetch';
import { CxClient } from '@piano-data/cx-client';
import { ModalProvider } from '@piano-react/components/Modal';
import { PopoverProvider } from '@piano-dc/react/popover';
import withClass from '@piano-react/components/withClass';
import CxDataProvider from '@piano-reports/summary-widgets/src/api/CxDataProvider';
import ErrorBoundary from '@piano-reports/summary-widgets/src/components/ErrorBoundary';
import AppHeaderSkeleton from '../AppHeader/AppHeaderSkeleton';
import ReportSettingsProvider from '../GlobalSettings/ReportSettingsProvider';
import AppHeader from '../AppHeader';
import SegmentsPage from '../SegmentsPage';
import env from '../../utils/env';
import Onboarding from '../Onboarding';
import { getAidFromUrl, getUserEmailFromUrl } from '../../utils/url';
import AppSkeleton from './AppSkeleton';
import AppError from './AppError';
import styles from './App.module.css';

export const Backdrop = withClass.div(styles.backdrop);

const { fetch, getTokenValues, logout } = createPianoFetch({
  providerName: 'vx-serverless',
  productId: env.REACT_APP_PRODUCT_ID ?? 'COMPOSER',
  accountsEndpointBase: env.REACT_APP_ACCOUNTS_ENDPOINT,
  redirectUrl: window.location.href,
  forceAuthorize: true,
  logoutIfUnauthorized: process.env.NODE_ENV === 'production',
});

const userEmail = getUserEmailFromUrl();

const tokenValues = (() => {
  let aid: string | null = null;
  let uid: string | null = null;

  const tokenPromise = getTokenValues().then((values) => {
    const aidFromUrl = getAidFromUrl();

    if (aidFromUrl) {
      aid = aidFromUrl;
    } else {
      aid = Array.isArray(values.aid) ? values.aid[0] : values.aid;
    }
    uid = Array.isArray(values.uid) ? values.uid[0] : values.uid;

    if (userEmail) {
      const email = values.email;

      if (email !== userEmail) {
        if (!window.sessionStorage.getItem('segment-summary-email-checked')) {
          window.sessionStorage.setItem(
            'segment-summary-email-checked',
            'true',
          );
          logout();
        } else {
          throw new Error('Invalid user e-mail');
        }
      }
    }
  });

  return {
    read: () => {
      if (!aid) throw tokenPromise;

      return [aid, uid] as [string, string];
    },
  };
})();

export interface Props {
  children: React.ReactNode;
}

const App = () => {
  const [aid, uid] = tokenValues.read();
  const scope = Array.isArray(aid) ? aid[0] : aid;

  const client = useMemo(
    () =>
      new CxClient({
        baseUrl: env.REACT_APP_API_ENDPOINT ?? '',
        debug: process.env.NODE_ENV === 'development',
        defaultOptions: { fetch },
        context: { aid },
      }),
    [aid],
  );

  return (
    <Suspense fallback={<AppSkeleton />}>
      <CxDataProvider client={client} scope={scope} scopeType="aid">
        <PopoverProvider>
          <ModalProvider>
            <ReportSettingsProvider userId={uid} scope={scope}>
              <Backdrop />
              <Suspense fallback={<AppHeaderSkeleton />}>
                <AppHeader />
              </Suspense>
              <SegmentsPage />
              {uid && <Onboarding className={styles.onboarding} userId={uid} />}
            </ReportSettingsProvider>
          </ModalProvider>
        </PopoverProvider>
      </CxDataProvider>
    </Suspense>
  );
};

function AppContainer() {
  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={AppError}>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default AppContainer;
