import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import { Sorting } from '@piano-reports/summary-widgets/src/hooks/useSortedSegments';
import StickyNode from '@piano-reports/ui-kit/src/StickyHeaderStack/StickyNode';
import { Report } from '../../../interfaces';
import SortingSelector from '../../SortingSelector';
import styles from './SortingPanel.module.css';

export const Container = withClass.div(styles.container);
export const Panel = withClass.div(styles.panel);
export const Text = withClass(Typography, styles.text);

export interface Props {
  report: Report;
  sorting?: Sorting;
  onSortChange: Dispatch<SetStateAction<Sorting | undefined>>;
}

const SortingPanel = ({ report, sorting, onSortChange }: Props) => {
  const { t } = useTranslation();
  const segmentsCount = t('report.segments.count', {
    defaultValue: '{{count}} segment',
    defaultValue_plural: '{{count}} segments',
    count: report.segments.length,
  });

  return (
    <StickyNode top={100}>
      <Panel>
        <Text>{segmentsCount}</Text>
        <SortingSelector
          sorting={sorting}
          onSelect={(newSorting) => onSortChange(newSorting)}
        />
      </Panel>
    </StickyNode>
  );
};

export default SortingPanel;
