import i18next from 'i18next';
import formatPercent from '@piano-react/utils/numberFormat/formatPercent';
import formatInteger from '@piano-react/utils/numberFormat/formatInteger';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { HistoryResolution } from '../../../../types';

export const leftValueTickFormat = (
  v: string | number,
  index?: number,
  isLast?: boolean,
) =>
  !isLast
    ? formatPercent(+v)
    : `${i18next.t(
        'segmentSummaryChart.valueAxis.audienceCoverage',
        '{{value}} Audience coverage',
        { value: formatPercent(+v) },
      )}`;

export const rightValueTickFormat = (
  v: string | number,
  index?: number,
  isLast?: boolean,
) => {
  if (isLast) {
    return `${i18next.t(
      'segmentSummaryChart.valueAxis.pageViews',
      '{{value}} PVs',
      { value: formatInteger(+v) },
    )}`;
  }
  return +v < 1000 ? formatInteger(+v) : formatInteger(+v, { compact: true });
};

export const indexTickFormat = (historyResolution?: HistoryResolution) => (
  v: string | number,
  index?: number,
): string => {
  try {
    switch (historyResolution) {
      case HistoryResolution.HOUR:
        return `${format(parseISO(v.toString()), 'hh:mm')} `;
      default:
        return `${format(parseISO(v.toString()), 'M/d')} `;
    }
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }

  return v.toString();
};
