import React from 'react';
import { TextPreloader } from '@piano-react/components/Preloader';
import { Container } from './index';
import styles from './SegmentDescription.module.css';

const SegmentDescriptionSkeleton = () => (
  <Container>
    <TextPreloader variant="single-15-pt" width={130} lineHeight={30} className={styles.name} />
    <div className={styles.description}>
      <TextPreloader
        variant="multi-13-pt"
        className={styles.preloaderDescription}
      />
      <TextPreloader variant="multi-13-pt" width={118} />
    </div>
  </Container>
);

export default SegmentDescriptionSkeleton;
