import React from 'react';
import Typography from '@piano-dc/react/typography';
import styles from './FilterField.module.css';

export interface Props {
  children: React.ReactNode;
  icon: React.ReactNode;
}

const Header: React.FC<Props> = ({ children, icon }) => (
  <div className={styles.title}>
    {icon}
    <Typography variant="single-11-pt" className={styles.text}>
      {children}
    </Typography>
  </div>
);

export default Header;
