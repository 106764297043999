import React from 'react';
import SingletonsSkeleton from '../Singletons/SingletonsSkeleton';
import ChartSkeleton from '../Chart/ChartSkeleton';

const SegmentDataSkeleton = () => (
  <>
    <SingletonsSkeleton />
    <ChartSkeleton />
  </>
);

export default SegmentDataSkeleton;
