import React, { useCallback, useState, useEffect } from 'react';
import Search, { SearchCustomEvent } from '@piano-dc/react/search';
import styles from './DroplistWithSearch.module.css';

export interface DroplistWithSearchContentProps {
  header: React.ReactNode;
  children: (searchString: string) => React.ReactNode;
  searchStr?: string | null;
}

const DroplistWithSearchContent = ({
  header,
  children,
  searchStr,
}: DroplistWithSearchContentProps) => {
  const [searchString, setSearchString] = useState<string>(searchStr ?? '');

  const handleChange = useCallback(
    (e: SearchCustomEvent) => {
      setSearchString(e.detail.value);
    },
    [setSearchString],
  );

  useEffect(() => {
    if (typeof searchStr !== 'undefined') {
      setSearchString(searchStr === null ? '' : searchStr);
    }
  }, [searchStr, setSearchString]);

  return (
    <div className={styles.list}>
      {header}
      <div className={styles.search}>
        <Search
          appearance="filled"
          onChange={handleChange}
          value={searchString}
        />
      </div>
      {children(searchString)}
    </div>
  );
};

export default DroplistWithSearchContent;
