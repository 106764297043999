import React from 'react';
import SegmentSummarySkeleton from '@piano-reports/summary-widgets/src/components/SegmentSummary/SegmentSummarySkeleton';
import { Collapse } from '@piano-react/components/Collapse';
import TitlePanelSkeleton from './TitlePanel/TitlePanelSkeleton';
import { Container } from './index';

const SegmentSummaryTileSkeleton = () => (
  <Container>
    <TitlePanelSkeleton />
    <Collapse isOpen>
      <SegmentSummarySkeleton />
    </Collapse>
  </Container>
);

export default SegmentSummaryTileSkeleton;
