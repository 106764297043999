import React from 'react';
import Typography from '@piano-dc/react/typography';
import formatPercent from '@piano-react/utils/numberFormat/formatPercent';
import formatInteger from '@piano-react/utils/numberFormat/formatInteger';
import { SegmentData } from '../../../api/useSegmentData';
import { DateRange } from '../../../types';
import usePageviewPercent from '../../../hooks/usePageviewPercent';
import styles from './Singletons.module.css';

export interface Props {
  data: SegmentData;
  range: DateRange;
}

const PageviewsDescription = ({ data, range }: Props) => {
  const percent = usePageviewPercent(data, range);

  return typeof data.data.events !== 'undefined' ? (
    <Typography variant="multicompact-13-pt" className={styles.description}>
      <span className={styles.value}>
        {formatPercent(percent)} ({formatInteger(data.data.events)})
      </span>{' '}
      of your total pageviews were generated by segments in the selected
      segmentation criteria.
    </Typography>
  ) : null;
};
export default PageviewsDescription;
