import React from 'react';
import HistoricalPresetsProvider from '../PresetsProvider/HistoricalPresetsProvider';
import ScopeSettingsContext from './ScopeSettingsContext';
import GlobalSettingsProvider from './GlobalSettingsProvider';

export interface Props {
  userId: string;
  scope: string;
  children: React.ReactNode;
}

const ReportSettingsProvider = ({ children, userId, scope }: Props) => (
  <ScopeSettingsContext.Provider value={{ userId, scope }}>
    <GlobalSettingsProvider>
      <HistoricalPresetsProvider>{children}</HistoricalPresetsProvider>
    </GlobalSettingsProvider>
  </ScopeSettingsContext.Provider>
);

export default ReportSettingsProvider;
