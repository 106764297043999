import { Segment } from '../types';
import { filterLikelihoodToCancel } from '../utils/filterLikelihoodToCancel';
import useC1XSegmentsQuery from './useC1XSegmentsQuery';

export interface SegmentOptions {
  segment?: Segment;
  segmentId?: string;
}

export default function useSegment({ segment, segmentId }: SegmentOptions) {
  const { data, isLoading } = useC1XSegmentsQuery();

  if (segment) {
    return segment;
  }

  if (isLoading) {
    return null;
  }

  const foundSegment = filterLikelihoodToCancel(data?.segments as Segment[]).find(
    (s) => s.id === segmentId,
  );

  if (!foundSegment)
    throw new Error(`Cannot find segment with id ${segmentId}`);

  return foundSegment;
}
