import React from 'react';
import styles from './FilterField.module.css';

export interface Props {
  children: React.ReactNode;
}

const FilterFieldPanel = ({ children }: Props) => (
  <div className={styles.panel}>{children}</div>
);

export default FilterFieldPanel;
