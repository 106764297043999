import React, { Suspense } from 'react';
import withClass from '@piano-react/components/withClass';
import GaugeChartSkeleton from '../GaugeChart/GaugeChartSkeleton';
import GaugeChart from '../GaugeChart';
import { DateRange, Segment } from '../../../types';
import SegmentDescription from '../SegmentDescription';
import styles from './SegmentHeader.module.css';

const Header = withClass.div(styles.header);
const Wrapper = withClass.div(styles.headerWrapper);

interface Props {
  segment: Segment;
  filterSegmentId?: string;
  range: DateRange;
}

export const SegmentHeaderWrapper = ({ children }: { children: React.ReactNode }) => (
  <Wrapper>
    <Header>{children}</Header>
  </Wrapper>
);

const SegmentHeader = ({ segment, range, filterSegmentId }: Props) => (
  <SegmentHeaderWrapper>
    <Suspense fallback={<GaugeChartSkeleton />}>
      <GaugeChart
        segment={segment}
        range={range}
        filterSegmentId={filterSegmentId}
      />
    </Suspense>
    <SegmentDescription segment={segment} />
  </SegmentHeaderWrapper>
);

export default SegmentHeader;
