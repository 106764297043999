import React from 'react';
import withClass from '@piano-react/components/withClass';
import { PageHeader, PageHeaderPanel } from '@piano-dc/react/page-header';
import ViewSwitcher from '../ViewSwitcher';
import TargetButton from '../TargetButton';
import Title from './Title';
import CloseButton from './CloseButton';
import styles from './AppHeader.module.css';

export const HeaderContainer = withClass.div(styles.header);
export const Spacer = withClass.div(styles.spacer);

const AppHeader: React.FC = React.memo(() => (
  <>
    <Spacer />
    <HeaderContainer>
      <PageHeader>
        <PageHeaderPanel align="left">
          <Title />
        </PageHeaderPanel>
        <PageHeaderPanel align="center">
          <ViewSwitcher />
        </PageHeaderPanel>
        <PageHeaderPanel align="right">
          <TargetButton />
          <CloseButton />
        </PageHeaderPanel>
      </PageHeader>
    </HeaderContainer>
  </>
));

export default AppHeader;
