import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import useTourState from '../../hooks/useTour';
import Onboarding from './Onboarding';
import OnboardingModal from './OnboardingModal';

export interface Props {
  className?: string;
  userId: string;
}

const OnboardingManager = ({ className, userId }: Props) => {
  const [getTour, setTour] = useTourState(userId);

  const { data, status } = useQuery<boolean>('tour', getTour, {
    suspense: false,
    enabled: !!userId,
  });

  const [isShowModal, setIsShowModal] = useState(false);
  const [showTour, setShowTour] = useState(false);

  useEffect(() => {
    if (status === 'success' && !data) {
      setIsShowModal(true);
    }
  }, [status, data, setIsShowModal]);

  const handleTour = () => {
    setIsShowModal(false);
    setShowTour(true);
  };

  const handleSkip = () => {
    setIsShowModal(false);
    setTour();
  };

  return (
    <>
      <OnboardingModal
        onTour={handleTour}
        onSkip={handleSkip}
        opened={isShowModal}
      />
      {showTour && <Onboarding className={className} onComplete={setTour} />}
    </>
  );
};

export default OnboardingManager;
