import uniqueId from 'lodash/uniqueId';


export class GroupKeyStorage {
  private map = new Map<string, string>();

  private generateNewKey = () => uniqueId(`group_`);

  public getKey(group: string): string {
    if (this.map.has(group)) {
      return this.map.get(group) as string;
    }

    const newId = this.generateNewKey();

    this.map.set(group, newId);

    return newId;
  }

  public replaceKey(oldGroup: string, newGroup: string): void {
    if (this.map.has(oldGroup)) {
      const id = this.map.get(oldGroup) ?? this.generateNewKey();

      this.map.set(newGroup, id);
      this.map.set(oldGroup, this.generateNewKey());
    }
  }
}

export default new GroupKeyStorage();
