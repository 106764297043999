import { createContext } from 'react';
import { CxClient } from '@piano-data/cx-client';

export interface CxDataContextType {
  client: CxClient;
  context: Record<string, any>;
  scopeType: 'aid' | 'siteGroupId';
  scope: string;
}

const CxDataContext = createContext<CxDataContextType>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  client: {},
  context: {},
  scopeType: 'aid',
  scope: '',
});

export default CxDataContext;
