import React from 'react';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import { HeaderCell, HeaderCellProps } from '@piano-reports/ui-kit/src/Table';
import styles from './ValueHeader.module.css';

const StyledTypography = withClass(Typography, styles.value);

export interface Props extends HeaderCellProps {}

const ValueHeader = ({ children, ...rest }: Props) => (
  <HeaderCell {...rest} className={styles.cell} sticky>
    <StyledTypography>{children}</StyledTypography>
  </HeaderCell>
);

export default ValueHeader;
