import React from 'react';
import cn from 'classnames';
import withClass from '@piano-react/components/withClass';
import RadioConfirm from '@piano-dc/react/radio-confirm';
import { Tooltip } from '@piano-dc/react/tooltip';
import Typography from '@piano-dc/react/typography';
import styles from './Card.module.css';

const Content = withClass.div(styles.content);
const Title = withClass(Typography, styles.title);
const Description = withClass(Typography, styles.description);
const SelectContainer = withClass.div(styles.selectContainer);
const Wrapper = withClass.div(styles.cardWrapper);

export interface Props {
  title: string;
  description?: string;
  onClick?: React.MouseEventHandler;
  tooltip?: string;
  selected?: boolean;
}

const Card = ({ title, description, onClick, tooltip, selected }: Props) => (
  <Wrapper className={selected ? styles.selected : undefined} onClick={onClick}>
    <Tooltip
      wrapperClassName={cn(styles.card, { [styles.selected]: selected })}
      content={tooltip}
      type="small"
    >
      <Content>
        <Title variant="multicompact-13-pt">{title}</Title>
        {description && (
          <Description variant="multicompact-13-pt">{description}</Description>
        )}
      </Content>
      <SelectContainer>
        {selected && <RadioConfirm className={styles.radio} checked />}
      </SelectContainer>
    </Tooltip>
  </Wrapper>
);

export default Card;
