import React from 'react';
import { Row } from '@piano-reports/ui-kit/src/Table';
import { TextPreloader } from '@piano-react/components/Preloader';
import SegmentCell from '../SegmentCell';
import ValueCell from '../ValueCell';

const SegmentRowSkeleton = () => (
  <Row>
    <SegmentCell isLoading />
    <ValueCell>
      <TextPreloader variant="single-15-pt" width={50} />
    </ValueCell>
    <ValueCell>
      <TextPreloader variant="single-15-pt" width={50} />
    </ValueCell>
    <ValueCell>
      <TextPreloader variant="single-15-pt" width={50} />
    </ValueCell>
    <ValueCell>
      <TextPreloader variant="single-15-pt" width={50} />
    </ValueCell>
  </Row>
);

export default SegmentRowSkeleton;
