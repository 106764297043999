import React from 'react';
import { motion } from 'framer-motion';
import { defaultTransition } from '@piano-react/components/animateConfig';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import useMeasure from '@piano-react/hooks/useMeasure';
import SegmentGroupList from '../SegmentGroupsDroplist/SegmentGroupList';
import { GroupWithKey } from '../CustomizeCriteria';
import SegmentsList from './SegmentsList';
import styles from './SegmentsDroplist.module.css';

const variants = {
  groups: {
    x: 0,
  },
  segments: {
    x: -250,
  },
};

const groupVariants = {
  groups: (maxHeight?: number) => ({
    opacity: 1,
    maxHeight,
  }),
  segments: (maxHeight?: number) => ({
    opacity: 0,
    maxHeight,
  }),
};

const segmentVariants = {
  groups: (maxHeight?: number) => ({
    opacity: 0,
    maxHeight,
  }),
  segments: (maxHeight?: number) => ({
    opacity: 1,
    maxHeight,
  }),
};

export interface Props {
  group: string;
  selected: Segment[];
  onAdd(segment: Segment): void;
  onRemove(segment: Segment): void;
  onAddAll(segments: Segment[]): void;
  onRemoveAll(group: string): void;
  onGroupReplace(newGroup: string): void;
  selectedGroups?: GroupWithKey[];
  searchString: string;
  isGroupSelection?: boolean;
  onCurrentGroupSelect(): void;
}

const SegmentsDroplistContent = ({
  group,
  selected,
  onAdd,
  onRemove,
  onAddAll,
  onRemoveAll,
  onGroupReplace,
  selectedGroups,
  searchString,
  isGroupSelection,
  onCurrentGroupSelect,
}: Props) => {
  const [{ ref: groupsRef }, { height: groupsHeight }] = useMeasure<
    HTMLDivElement
  >();
  const [{ ref: segmentsRef }, { height: segmentsHeight }] = useMeasure<
    HTMLDivElement
  >();

  const animationProps = {
    initial: false,
    animate: isGroupSelection ? 'groups' : 'segments',
    transition: defaultTransition(0.2),
    custom: (isGroupSelection ? groupsHeight : segmentsHeight) || 320,
  };

  return (
    <div className={styles.container}>
      <motion.div
        className={styles.items}
        variants={variants}
        {...animationProps}
      >
        <motion.div
          className={styles.item}
          variants={groupVariants}
          {...animationProps}
        >
          <div ref={groupsRef}>
            <SegmentGroupList
              filter={searchString}
              currentGroup={group}
              onSelect={onGroupReplace}
              onCurrentGroupSelect={onCurrentGroupSelect}
              selectedGroups={selectedGroups?.map((g) => g.id)}
            />
          </div>
        </motion.div>
        <motion.div
          className={styles.item}
          variants={segmentVariants}
          {...animationProps}
        >
          <div ref={segmentsRef}>
            <SegmentsList
              group={group}
              filter={searchString}
              selected={selected}
              onAdd={onAdd}
              onRemove={onRemove}
              onAddAll={onAddAll}
              onRemoveAll={onRemoveAll}
            />
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SegmentsDroplistContent;
