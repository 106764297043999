import React from 'react';
import { ChartContainer, CHART_PADDING } from './index';
import BarsSkeleton from './BarsSkeleton';
import AxisSkeleton from './AxisSkeleton';
import styles from './Chart.module.css';

const ChartSkeleton = () => {
  return (
    <ChartContainer>
      <div
        className={styles.barSkeleton}
        style={{
          top: CHART_PADDING.top,
          bottom: CHART_PADDING.bottom,
          left: 0,
          right: 0,
        }}
      >
        <AxisSkeleton />
      </div>
      <div className={styles.barSkeleton} style={CHART_PADDING}>
        <BarsSkeleton />
      </div>
    </ChartContainer>
  );
};

export default ChartSkeleton;
