import { Preset } from '../interfaces';

export const basePresets: Preset[] = [
  {
    name: 'High frequency users',
    description: '',
    items: ['10+ visit days last month'],
  },
  {
    name: 'Low recency user',
    description: '',
    items: ['Active last 15+ days'],
  },
];
