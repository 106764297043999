import React, { ErrorInfo, Component } from 'react';

export interface Props {
  onError?(error: Error, info: ErrorInfo): void;
  onReset?(): void;
  fallback?: React.ReactNode;
  FallbackComponent?: React.ComponentType<{
    error: Error;
    resetErrorBoundary: () => void;
    className?: string;
  }>;
  children: React.ReactNode;
}

export interface State {
  error: Error | null;
}

export default class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  state: State = { error: null };
  updatedWithError = false;
  resetErrorBoundary = () => {
    this.props.onReset?.();
    this.reset();
  };

  reset() {
    this.updatedWithError = false;
    this.setState({ error: null });
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error(error, info);
    this.props.onError?.(error, info);
  }

  render() {
    const { error } = this.state;
    const { FallbackComponent, fallback } = this.props;

    if (error !== null) {
      const props = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };
      if (React.isValidElement(fallback)) {
        return fallback;
      } else if (FallbackComponent) {
        return <FallbackComponent {...props} />;
      } else {
        throw new Error(
          'ErrorBoundary requires either a fallback or FallbackComponent prop',
        );
      }
    }

    return this.props.children;
  }
}
