import React from 'react';
import Icon from '@piano-reports/ui-kit/src/Icon';
import { RoundButton } from '@piano-dc/react/round-button';
import { useTranslation } from 'react-i18next';
import useReport from '../../hooks/useReport';
import { isTargetMode, sendVXMessage } from '../../utils/vxMessages';
import { SEGMENT_SUMMARY_TARGET } from '../../actions';

const TargetButton = () => {
  const { t } = useTranslation();

  const { report } = useReport();

  const handleClick = () => {
    sendVXMessage(SEGMENT_SUMMARY_TARGET, {
      segmentIds: report?.segments.map((segment) => segment.id),
    });
  };

  return (
    <RoundButton
      icon={<Icon name="user-segment" data-tour="target" />}
      onClick={handleClick}
      disabled={!isTargetMode()}
    >
      {t('appHeader.target', 'Target')}
    </RoundButton>
  );
};

export default TargetButton;
