import React, { Suspense } from 'react';
import { motion } from 'framer-motion';
import withClass from '@piano-react/components/withClass';
import useSettingsSaver from '../../hooks/useSettingsSaver';
import Sidebar from '../Sidebar';
import Report from '../Report';
import ReportSkeleton from '../Report/ReportSkeleton';
import styles from './SegmentsPage.module.css';

export const Page = withClass.div(styles.page);
export const SidebarContainer = withClass.div(styles.sidebar);
export const ContentContainer = withClass.div(styles.content);

const SegmentsPage: React.FC = React.memo(() => {
  useSettingsSaver();

  return (
    <Page>
      <motion.div className={styles.sidebar}>
        <Suspense fallback={<SidebarContainer />}>
          <Sidebar />
        </Suspense>
      </motion.div>
      <ContentContainer>
        <Suspense fallback={<ReportSkeleton />}>
          <Report />
        </Suspense>
      </ContentContainer>
    </Page>
  );
});

export default SegmentsPage;
