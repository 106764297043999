import React from 'react';
import { TextPreloader } from '@piano-react/components/Preloader';
import styles from './Singletons.module.css';

const DescriptionPreloader = ({ threeLine }: { threeLine?: boolean }) => (
  <>
    <TextPreloader
      variant="multicompact-13-pt"
      className={styles.descriptionPreloader}
    />
    <TextPreloader
      variant="multicompact-13-pt"
      className={`${styles.descriptionPreloader} ${styles.second}`}
    />
    {threeLine && (
      <TextPreloader
        variant="multicompact-13-pt"
        className={`${styles.descriptionPreloader} ${styles.third}`}
      />
    )}
  </>
);

export default DescriptionPreloader;
