import React, { forwardRef } from 'react';
import cn from 'classnames';
import Icon from '../../Icon';
import styles from './Header.module.css';

export interface HeaderCellContentProps {
  children?: React.ReactNode;
  className?: string;
  sorting?: 'asc' | 'desc';
  active?: boolean;
  width?: number | string;
  onClick?: React.MouseEventHandler;
  last?: boolean;
}

export const HeaderCellContent = forwardRef<
  HTMLTableHeaderCellElement,
  HeaderCellContentProps
>(({ children, className, sorting, active, width, onClick }, ref) => (
  <th
    className={cn(
      styles.headerCell,
      {
        [styles.active]: active,
        [styles.clickable]: !!onClick,
      },
      className,
    )}
    style={{ width }}
    onClick={onClick}
    ref={ref}
  >
    <div className={styles.content}>
      {children}
      {sorting && (
        <Icon
          name={sorting === 'asc' ? 'pointer-up-small' : 'pointer-down-small'}
        />
      )}
    </div>
  </th>
));

export const StuckHeaderCellContent = forwardRef<
  HTMLTableHeaderCellElement,
  HeaderCellContentProps
>(({ children, className, sorting, active, width, onClick, last }, ref) => (
  <div
    className={cn(
      styles.headerCell,
      {
        [styles.active]: active,
        [styles.clickable]: !!onClick,
        [styles.sticky]: true,
        [styles.last]: last,
      },
      className,
    )}
    style={{ width }}
    onClick={onClick}
    ref={ref}
  >
    <div className={styles.content}>
      {children}
      {sorting && (
        <Icon
          name={sorting === 'asc' ? 'pointer-up-small' : 'pointer-down-small'}
        />
      )}
    </div>
  </div>
));
