import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@piano-dc/react/typography';
import withClass from '@piano-react/components/withClass';
import Icon from '@piano-reports/ui-kit/src/Icon';
import styles from './SegmentReportError.module.css';

const Container = withClass.div(styles.container);
const Error = withClass.div(styles.error);

export interface Props {
  error: Error;
  resetErrorBoundary: () => void;
  className?: string;
}

const SegmentReportError = ({ className }: Props) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Error>
        <Icon name="status-warning-filled-default" className={styles.icon} />
        <Typography variant="single-13-pt">
          {t('chart.error', 'Error loading chart data')}
        </Typography>
      </Error>
    </Container>
  );
};

export default SegmentReportError;
