import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Droplist, DroplistProps } from '@piano-dc/react/droplist';
import { PopoverContext } from '@piano-dc/react/popover';
import DroplistWithSearchContent from '@piano-reports/ui-kit/src/DroplistWithSearch/DroplistWithSearchContent';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import { GroupWithKey } from '../CustomizeCriteria';
import SegmentsListHeader from './SegmentsListHeader';
import SegmentsDroplistContent from './SegmentsDroplistContent';

export interface Props extends DroplistProps {
  group: string;
  selected: Segment[];
  onAdd(segment: Segment): void;
  onRemove(segment: Segment): void;
  onAddAll(segments: Segment[]): void;
  onRemoveAll(group: string): void;
  onGroupReplace?(oldGroup: string, newGroup: string): void;
  selectedGroups?: GroupWithKey[];
}

const SegmentsDroplist = React.memo(
  ({
    group,
    selected,
    onAdd,
    onRemove,
    onAddAll,
    onRemoveAll,
    onGroupReplace,
    selectedGroups,
    onOpen,
    onClose,
    ...rest
  }: Props) => {
    const { hidePopover } = useContext(PopoverContext);

    const [isGroupSelection, setGroupSelectionMode] = useState<boolean>(false);

    useEffect(
      () => () => {
        hidePopover();
      },
      [hidePopover],
    );

    const handleGroupReplace = (groupName: string) => {
      if (onGroupReplace) {
        onGroupReplace(group, groupName);
        setGroupSelectionMode(false);
        setSearchStr(null);
      }
    };

    const [searchStr, setSearchStr] = useState<string | null>(null);

    const handleOpen = useCallback(() => {
      setSearchStr('');
      setGroupSelectionMode(false);
      if (onOpen) {
        onOpen();
      }
    }, [setSearchStr, setGroupSelectionMode, onOpen]);

    const handleClose = useCallback(() => {
      if (onClose) {
        onClose();
      }
      setSearchStr(null);
      setGroupSelectionMode(false);
    }, [setSearchStr, onClose]);

    const handleModeSelection = (mode: boolean) => {
      setSearchStr((value) => (value === '' ? null : ''));
      setGroupSelectionMode(mode);
    };

    return (
      <Droplist
        {...rest}
        onOpen={handleOpen}
        onClose={handleClose}
        maxHeight={400}
      >
        <DroplistWithSearchContent
          header={
            <SegmentsListHeader
              groupsAvailable={!!onGroupReplace}
              onClick={() => handleModeSelection(true)}
              isGroupsSelection={isGroupSelection}
            />
          }
          searchStr={searchStr}
        >
          {(searchString) => (
            <SegmentsDroplistContent
              group={group}
              selected={selected}
              onAdd={onAdd}
              onRemove={onRemove}
              onAddAll={onAddAll}
              onRemoveAll={onRemoveAll}
              onGroupReplace={handleGroupReplace}
              searchString={searchString}
              onCurrentGroupSelect={() => handleModeSelection(false)}
              selectedGroups={selectedGroups}
              isGroupSelection={isGroupSelection}
            />
          )}
        </DroplistWithSearchContent>
      </Droplist>
    );
  },
);

export default SegmentsDroplist;
