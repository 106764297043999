import React, { memo, Suspense } from 'react';
import withClass from '@piano-react/components/withClass';
import { DateRange, Segment } from '../../types';
import ErrorBoundary from '../ErrorBoundary';
import styles from './SegmentSummary.module.css';
import SegmentSummaryContent from './SegmentSummaryContent';
import SegmentSummaryError from './SegmentSummaryError';
import SegmentSummaryDataSkeleton from './SegmentSummaryData/SegmentSummaryDataSkeleton';

export const Container = withClass.div(styles.container);

export interface SegmentSummaryProps {
  segmentIds?: string[];
  segments?: Segment[];
  filterSegmentId?: string;
  className?: string;
  range: DateRange;
}

const SegmentSummary = memo(({ className, ...rest }: SegmentSummaryProps) => (
  <Container className={className}>
    <ErrorBoundary FallbackComponent={SegmentSummaryError}>
      <Suspense fallback={<SegmentSummaryDataSkeleton key="summary-data-skeleton" />}>
        <SegmentSummaryContent {...rest} />
      </Suspense>
    </ErrorBoundary>
  </Container>
));

export default SegmentSummary;
