import React from 'react';
import { OnboardingTour, TourStep } from '@piano-dc/react/onboarding';
import { useTranslation } from 'react-i18next';

export interface Props {
  className?: string;
  onComplete?(): void;
}

const Onboarding = ({ className, onComplete }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <OnboardingTour
        group={t('onboarding.group', 'Segment summary tour')}
        onClose={onComplete}
      >
        <TourStep
          anchor="[data-tour=preset]"
          header={<span>{t('onboarding.preset.title', 'Browse presets')}</span>}
        >
          {t(
            'onboarding.preset.description',
            'Our gallery of industry-standard segmentation presets provides a great starting point for exploration.',
          )}
        </TourStep>
        <TourStep
          anchor="[data-tour=customize]"
          header={
            <span>
              {t('onboarding.customize.title', 'Fine-tune your criteria')}
            </span>
          }
          anchorHorizontalOffset={100}
        >
          {t(
            'onboarding.customize.description',
            'Dive into edit mode to customize preset criteria and tailor it to your specific needs.',
          )}
        </TourStep>
        <TourStep
          anchor="[data-tour=chart]"
          header={
            <span>
              {t(
                'onboarding.chart.title',
                'Your segment combination, visualized',
              )}
            </span>
          }
        >
          {t(
            'onboarding.chart.description',
            'See at a glance how much of your audience is represented by your selected segment criteria.',
          )}
        </TourStep>
        <TourStep
          anchor="[data-tour=datepicker]"
          header={
            <span>
              {t('onboarding.datepicker.title', 'View changes over time')}
            </span>
          }
        >
          {t(
            'onboarding.datepicker.description',
            'Select different time periods to better understand segment performance over time.',
          )}
        </TourStep>
        <TourStep
          anchor="[data-tour=target]"
          header={<span>{t('onboarding.target.title', 'Take action')}</span>}
          anchorHorizontalOffset={100}
          anchorVerticalOffset={-30}
        >
          {t(
            'onboarding.target.description',
            'Click the Target button to apply your segmentation criteria to the User segment card in your experience.',
          )}
        </TourStep>
      </OnboardingTour>
    </div>
  );
};

export default Onboarding;
