import React from 'react';
import GaugeChartSkeleton from '../GaugeChart/GaugeChartSkeleton';
import SingletonsSkeleton from '../Singletons/SingletonsSkeleton';
import ChartSkeleton from '../Chart/ChartSkeleton';
import { Insights } from './index';

const SegmentSummaryDataSkeleton = () => (
  <>
    <Insights>
      <GaugeChartSkeleton />
      <SingletonsSkeleton />
    </Insights>
    <ChartSkeleton key="skeleton" />
  </>
);

export default SegmentSummaryDataSkeleton;
