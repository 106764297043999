import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderRow } from '@piano-dc/react/list';

const GroupsHeader: React.FC = () => {
  const { t } = useTranslation();

  return <HeaderRow>{t('customize.selectGroup', 'Select group')}</HeaderRow>;
};

export default GroupsHeader;
