import { useCallback, useContext } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { Traffic } from '@piano-data/cx-client';
import { DateRange } from '../types';
import { EVENTS_KEY, UNIQUE_USERS_KEY } from '../consts';
import CxDataContext from './CxDataContext';
import { getTrafficRequestRange } from './utils';

export interface Options {
  range: DateRange;
}

export default function useTotalTrafficQuery(
  { range }: Options,
  queryConfig: UseQueryOptions<Traffic.Response> = {},
) {
  const { client, scope } = useContext(CxDataContext);

  const request = useCallback(() => {
    return client.traffic.read({
      ...getTrafficRequestRange(range),
      fields: [EVENTS_KEY, UNIQUE_USERS_KEY],
      historyFields: [EVENTS_KEY, UNIQUE_USERS_KEY],
      historyResolution: range.historyResolution,
    });
  }, [client, range]);

  return useQuery(
    [
      'segments-total-traffic',
      scope,
      range.start,
      range.stop,
      range.offset,
      range.historyResolution,
    ],
    request,
    { staleTime: 5 * 60 * 1000, suspense: true, ...(queryConfig as any) },
  );
}
