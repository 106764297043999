import React, { useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { defaultTransition } from '@piano-react/components/animateConfig';
import { TooltipProvider } from '@piano-dc/react/tooltip/TooltipProvider';
import styles from './FilterList.module.css';
import Delimiter from './Delimiter';
import Empty from './Empty';

const variants = {
  enter: {
    zIndex: 0,
    opacity: 0,
    x: -10,
  },
  mounted: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export interface Props {
  delimiterText: string;
  children: React.ReactNodeArray | null;
  tooltipContent?: string;
  tooltipDisabled?: boolean;
  emptyText?: string;
}

const FilterList = React.memo(
  ({ children, delimiterText, emptyText }: Props) => {
    const previousCount = useRef<number>(React.Children.count(children));

    // It needs for correct animation
    useEffect(() => {
      previousCount.current = React.Children.count(children);
    }, [previousCount, children]);

    return (
      <TooltipProvider>
        <AnimatePresence
          initial={false}
          exitBeforeEnter={
            React.Children.count(children) === 0 || previousCount.current === 0
          }
        >
          {React.Children.count(children) > 0 ? (
            React.Children.map(children, (child, i) => {
              return (
                <motion.div
                  className={styles.item}
                  key={(child as any)?.key ?? i}
                  variants={variants}
                  initial="enter"
                  animate="mounted"
                  exit="exit"
                  transition={defaultTransition(0.2)}
                >
                  <Delimiter>{i > 0 ? delimiterText : undefined}</Delimiter>
                  {child}
                </motion.div>
              );
            })
          ) : (
            <motion.div
              className={styles.item}
              key="empty"
              variants={variants}
              initial="enter"
              animate="mounted"
              exit="exit"
              transition={defaultTransition(0.1)}
            >
              <Delimiter />
              <Empty>{emptyText}</Empty>
            </motion.div>
          )}
        </AnimatePresence>
      </TooltipProvider>
    );
  },
);

export default FilterList;
