import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { motion, useElementScroll } from 'framer-motion';
import cn from 'classnames';
import { defaultTransition } from '@piano-react/components/animateConfig';
import styles from './SidebarPanel.module.css';

const variants = {
  enter: (direction: number) => ({
    zIndex: 0,
    opacity: 0,
    x: direction > 0 ? 100 : -100,
  }),
  opened: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    opacity: 0,
    x: direction > 0 ? -100 : 100,
  }),
};

export interface SidebarPanelProps {
  children: React.ReactNode;
  className?: string;
  main?: boolean;
}

export const SidebarPanelContainer = forwardRef<
  HTMLDivElement,
  SidebarPanelProps
>(({ children, className, main }: SidebarPanelProps, ref) => (

    <motion.div
      ref={ref}
      className={cn(styles.panel, className)}
      custom={main ? -1 : 1}
      variants={variants}
      initial="enter"
      animate="opened"
      exit="exit"
      transition={defaultTransition(0.2)}
    >
      {children}
    </motion.div>

));

export interface HeaderProps {
  icon?: React.ReactElement;
  onIconClick?(): void;
  children: React.ReactNode;
  scrolled?: boolean;
}

export const Header = ({
  children,
  icon,
  onIconClick,
  scrolled,
}: HeaderProps) => (
  <div className={cn(styles.header, { [styles.scrolled]: scrolled })}>
    <div
      className={cn(styles.icon, { [styles.iconActive]: !!onIconClick })}
      onClick={onIconClick}
    >
      {icon}
    </div>
    <div className={styles.headerContent}>{children}</div>
  </div>
);

export interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  icon?: React.ReactElement;
  onIconClick?(): void;
  className?: string;
  main?: boolean;
}

const SidebarPanel = ({
  children,
  header,
  icon,
  onIconClick,
  className,
  main,
}: Props) => {
  const [scrolled, setScrolled] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useElementScroll(ref);

  useEffect(() => {
    scrollYProgress.onChange((v) => {
      setScrolled(() => v > 0);
    });
  }, [scrollYProgress, setScrolled]);

  return (
    <>
      <Header icon={icon} onIconClick={onIconClick} scrolled={scrolled}>
        {header}
      </Header>
      <SidebarPanelContainer className={className} main={main} ref={ref}>
        {children}
      </SidebarPanelContainer>
    </>
  );
};

export default SidebarPanel;
