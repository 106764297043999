import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { Segment } from '@piano-data/cx-client';
import CxDataContext from './CxDataContext';

export default function useSegmentsQuery(params?: Segment.Read.List.Body) {
  const { client, scope } = useContext(CxDataContext);

  const request = useCallback(() => client.segment.read(params ?? {}), [
    client,
    params,
  ]);

  return useQuery(['segments', scope, params], request, {
    staleTime: 10 * 60 * 1000,
    suspense: true,
  });
}
