import React from 'react';
import { TextPreloader } from '@piano-react/components/Preloader';
import styles from './ReportDescription.module.css';

const PresetSelectSkeleton = () => (
  <div className={styles.select}>
    <TextPreloader variant="multicompact-15-pt" width={120} />
  </div>
);

export default PresetSelectSkeleton;
