import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useSegmentData from '../../../api/useSegmentData';
import { DateRange, Segment } from '../../../types';
import Singletons from '../Singletons';
import Chart from '../Chart';

export interface Props {
  segment: Segment;
  filterSegmentId?: string;
  range: DateRange;
  onDataUpdate?(key: string, values: Record<string, number | undefined>): void;
}

const SegmentData = ({
  segment,
  filterSegmentId,
  range,
  onDataUpdate,
}: Props) => {
  const { t } = useTranslation();
  const fetchFallback = <>{t('fetch.placeholder', 'Loading...')}</>;
  const { data, status, error } = useSegmentData({
    segmentId: segment.id,
    filterSegmentId,
    range,
  });

  useEffect(() => {
    if (onDataUpdate) {
      if (data) {
        onDataUpdate(segment.id, data.data);
      } else {
        onDataUpdate(segment.id, {});
      }
    }
  }, [segment, onDataUpdate, data]);

  if (error) throw error;

  if (status === 'loading') {
    return fetchFallback;
  }

  return data ? (
    <>
      <Singletons data={data} />
      <Chart data={data} range={range} />
    </>
  ) : null;
};

export default SegmentData;
