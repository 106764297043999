import React from 'react';
import { CustomDomComponent, motion, SVGMotionProps } from 'framer-motion';
import withClass, {
  PropsWithClassName,
} from '@piano-react/components/withClass';
import { ColorsBasePrimary } from '@piano-dc/atoms/colors/colors';
import userIconUrl from '@piano-dc/atoms/icons/svg/user-fill-multiple-small.svg';
import { DATA_1, DATA_2 } from '../../colors';
import styles from './GaugeChart.module.css';

type CircleProps = SVGMotionProps<any> & PropsWithClassName;

const FirstCircle = withClass<CircleProps>(
  motion.circle as CustomDomComponent<CircleProps>,
  styles.firstCircle,
);
const SecondCircle = withClass<CircleProps>(
  motion.circle as CustomDomComponent<CircleProps>,
  styles.secondCircle,
);

const getCircumference = (radius: number) => {
  return 2 * Math.PI * radius;
};

const getDashOffset = (circumference: number, percent: number) => {
  return circumference - circumference * percent;
};

const LARGE_CIRCLE_RADIUS = 50;
const MIDDLE_CIRCLE_RADIUS = 30;
const SMALL_CIRCLE_RADIUS = 20;

const LARGE_STROKE_WIDTH = 6;
const MIDDLE_STROKE_WIDTH = 4;
const SMALL_STROKE_WIDTH = 3;

const FIRST_CIRCLE_DURATION = 0.6;
const SECOND_CIRCLE_DURATION = 0.7;

export type GaugeSize = 'large' | 'middle' | 'small';

const getDimensions = (type: GaugeSize) => {
  if (type === 'middle') {
    return {
      bgCircleRadius: MIDDLE_CIRCLE_RADIUS - MIDDLE_STROKE_WIDTH,
      firstCircleRadius: MIDDLE_CIRCLE_RADIUS - MIDDLE_STROKE_WIDTH / 2,
      secondCircleRadius:
        MIDDLE_CIRCLE_RADIUS - MIDDLE_STROKE_WIDTH - MIDDLE_STROKE_WIDTH / 2,
      strokeWidth: MIDDLE_STROKE_WIDTH,
      bgStrokeWidth: MIDDLE_STROKE_WIDTH * 2,
      iconSize: 20,
    };
  }

  if (type === 'small') {
    return {
      bgCircleRadius: SMALL_CIRCLE_RADIUS - SMALL_STROKE_WIDTH,
      firstCircleRadius: SMALL_CIRCLE_RADIUS - SMALL_STROKE_WIDTH / 2,
      secondCircleRadius:
        SMALL_CIRCLE_RADIUS - SMALL_STROKE_WIDTH - SMALL_STROKE_WIDTH / 2,
      strokeWidth: SMALL_STROKE_WIDTH,
      bgStrokeWidth: SMALL_STROKE_WIDTH * 2,
      iconSize: 16,
    };
  }

  return {
    bgCircleRadius: LARGE_CIRCLE_RADIUS - LARGE_STROKE_WIDTH,
    firstCircleRadius: LARGE_CIRCLE_RADIUS - LARGE_STROKE_WIDTH / 2,
    secondCircleRadius:
      LARGE_CIRCLE_RADIUS - LARGE_STROKE_WIDTH - LARGE_STROKE_WIDTH / 2,
    strokeWidth: LARGE_STROKE_WIDTH,
    bgStrokeWidth: LARGE_STROKE_WIDTH * 2,
    iconSize: 40,
  };
};

const getCircleRad = (type: GaugeSize) => {
  switch (type) {
    case 'small':
      return SMALL_CIRCLE_RADIUS;
    case 'middle':
      return MIDDLE_CIRCLE_RADIUS;
    default:
      return LARGE_CIRCLE_RADIUS;
  }
};

type Props = {
  type?: GaugeSize;
  pageViewPercent?: number;
  coveragePercent?: number;
  isLoading?: boolean;
};

const GaugeChart = ({
  type = 'large',
  pageViewPercent = 0,
  coveragePercent = 0,
  isLoading,
}: Props) => {
  const {
    bgCircleRadius,
    firstCircleRadius,
    secondCircleRadius,
    strokeWidth,
    bgStrokeWidth,
    iconSize,
  } = getDimensions(type);

  const firstCircumference = getCircumference(firstCircleRadius);
  const secondCircumference = getCircumference(secondCircleRadius);
  const firstDashOffset = getDashOffset(
    firstCircumference,
    Math.min(pageViewPercent, 1),
  );
  const secondDashOffset = getDashOffset(
    secondCircumference,
    Math.min(coveragePercent, 1),
  );
  const circleRad = getCircleRad(type);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circleRad * 2}
      className={styles.svgWrapper}
      height={circleRad * 2}
      viewBox={`0 0 ${circleRad * 2} ${circleRad * 2}`}
      role="img"
    >
      <circle
        cx={circleRad}
        cy={circleRad}
        r={bgCircleRadius}
        strokeWidth={bgStrokeWidth}
        stroke={ColorsBasePrimary}
        strokeOpacity={0.05}
        fill="none"
      />
      <FirstCircle
        strokeDasharray={firstCircumference}
        strokeDashoffset={firstCircumference}
        cx={circleRad}
        cy={circleRad}
        r={firstCircleRadius}
        fill="none"
        strokeWidth={strokeWidth}
        stroke={DATA_1}
        animate={{
          strokeDashoffset: firstDashOffset,
        }}
        transition={{ duration: FIRST_CIRCLE_DURATION }}
      />
      <SecondCircle
        strokeDasharray={secondCircumference}
        strokeDashoffset={secondCircumference}
        cx={circleRad}
        cy={circleRad}
        r={secondCircleRadius}
        fill="none"
        strokeWidth={strokeWidth}
        stroke={DATA_2}
        animate={{
          strokeDashoffset: secondDashOffset,
        }}
        transition={{ duration: SECOND_CIRCLE_DURATION }}
      />
      {!isLoading && (
        <image
          href={userIconUrl}
          width={iconSize}
          height={iconSize}
          x={circleRad - iconSize / 2}
          y={circleRad - iconSize / 2}
          opacity={0.4}
        />
      )}
    </svg>
  );
};

export default GaugeChart;
