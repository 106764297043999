import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AUDIENCE_COVERAGE_KEY,
  EVENTS_KEY,
  PAGE_VIEWS_PER_USER_KEY,
  SEGMENT_NAME,
  UNIQUE_USERS_KEY,
} from '@piano-reports/summary-widgets/src/consts';

export default function useLocalizedSortingVariants() {
  const { t } = useTranslation();

  return useCallback(
    (field: string | null): string => {
      switch (field) {
        case null:
          return t('sorting.default', 'Default');
        case SEGMENT_NAME:
          return t('sorting.name', 'Name');
        case AUDIENCE_COVERAGE_KEY:
          return t('sorting.audienceCoverage', 'Audience coverage');
        case EVENTS_KEY:
          return t('sorting.pageviews', 'Pageviews');
        case UNIQUE_USERS_KEY:
          return t('sorting.uniqueUsers', 'Unique visitors');
        case PAGE_VIEWS_PER_USER_KEY:
          return t('sorting.pageviewsPerUser', 'PVs / User');
        default:
          return field;
      }
    },
    [t],
  );
}
