import React, { useEffect } from 'react';
import { DateRange, Segment } from '../../../types';
import useSegmentData from '../../../api/useSegmentData';
import SegmentRow from './Row';

export interface Props {
  segment: Segment;
  filterSegmentId?: string;
  className?: string;
  range: DateRange;
  maxPageviews: number;
  onDataUpdate(key: string, values: Record<string, number | undefined>): void;
}

const SegmentRowContainer = ({
  segment,
  filterSegmentId,
  range,
  onDataUpdate,
  maxPageviews,
}: Props) => {
  const { data, status } = useSegmentData(
    {
      segmentId: segment.id,
      filterSegmentId: filterSegmentId,
      range,
    },
    { suspense: false },
  );

  useEffect(() => {
    if (data) {
      onDataUpdate(segment.id, data.data);
    } else {
      onDataUpdate(segment.id, {});
    }
  }, [segment, onDataUpdate, data]);

  return (
    <SegmentRow
      segment={segment}
      segmentData={data}
      range={range}
      isLoading={status === 'loading'}
      maxPageviews={maxPageviews}
    />
  );
};

export default SegmentRowContainer;
