import React, { useRef, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import useSticky from '../hooks/useStickyNode';
import Stuck from './Stuck';
import StickyHeaderStackContext from './StickyHeaderStackContext';

export interface Props {
  children: React.ReactElement | [React.ReactElement, React.ReactElement];
  top?: number;
  stuckContainerClassName?: string;
}

const StickyNode = ({ children, top = 0, stuckContainerClassName }: Props) => {
  const mainRef = useRef(null);
  const stickyRef = useRef<HTMLDivElement | null>(null);

  let stuck: React.ReactElement | null = null;
  let main: React.ReactElement | null = null;

  const { node } = useContext(StickyHeaderStackContext);

  const {
    isSticky,
    bounds: { width, height },
  } = useSticky(mainRef, top);

  useEffect(() => {
    if (node) {
      const container = document.createElement('div');
      if (stuckContainerClassName) {
        container.classList.add(stuckContainerClassName);
      }
      node.appendChild(container);

      stickyRef.current = container;
    }

    return () => {
      if (node && stickyRef.current) {
        node.removeChild(stickyRef.current);
      }
    };
  }, [node, stickyRef, stuckContainerClassName]);

  React.Children.forEach(children, (child) => {
    if (child?.type === Stuck) {
      stuck = child;
    } else {
      main = child;
    }
  });

  return (
    <>
      {main
        ? React.cloneElement(main, {
            ...((main as React.ReactElement).props || {}),
            ref: mainRef,
          })
        : null}
      {isSticky && stickyRef.current
        ? createPortal(
            stuck ? (
              React.cloneElement(stuck, {
                ...(stuck as React.ReactElement).props,
                width,
                height,
              })
            ) : (
              <Stuck width={width} height={height}>
                {main}
              </Stuck>
            ),
            stickyRef.current,
          )
        : null}
    </>
  );
};

export default StickyNode;
