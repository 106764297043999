import React from 'react';
import { Row } from '@piano-dc/react/list';
import { useTranslation } from 'react-i18next';
import Typography from '@piano-dc/react/typography';
import useSegmentGroups from '../../hooks/useSegmentGroups';
import { BRAND_RELATIONSHIP } from '../../constants';
import styles from './SegmentGroupList.module.css';

export interface Props {
  filter: string;
  currentGroup?: string;
  selectedGroups?: string[];
  onSelect(group: string): void;
  onCurrentGroupSelect?(): void;
}

const SegmentGroupList = ({
  filter,
  selectedGroups = [],
  onSelect,
  onCurrentGroupSelect,
  currentGroup,
}: Props) => {
  const { t } = useTranslation();

  const groups = useSegmentGroups(
    (group) =>
      (group &&
        group !== BRAND_RELATIONSHIP &&
        !selectedGroups.includes(group) &&
        (!filter || group.toLowerCase().includes(filter.toLowerCase()))) ||
      (!!currentGroup && currentGroup === group),
  );

  return (
    <>
      {groups.length > 0 ? (
        groups.map((group) => (
          <Row
            key={group}
            onClick={
              onCurrentGroupSelect && group === currentGroup
                ? onCurrentGroupSelect
                : () => onSelect(group)
            }
            className={styles.item}
            selected={group === currentGroup}
          >
            {group}
          </Row>
        ))
      ) : (
        <Typography className={styles.empty}>
          {t('customize.noResults', `No results for "{{filter}}"`, {
            filter,
          })}
        </Typography>
      )}
    </>
  );
};

export default SegmentGroupList;
