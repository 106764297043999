import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import { defaultTransition } from '@piano-react/components/animateConfig';
import SegmentGroup from '../../SegmentGroupField/SegmentGroup';
import { SegmentsDroplist } from '../../SegmentsDroplist';
import { GroupWithKey } from '../index';
import baseStyles from '../CustomizeCriteria.module.css';
import styles from './SegmentGroups.module.css';

const variants = {
  enter: {
    zIndex: 0,
    opacity: 0,
    x: -100,
  },
  mounted: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    opacity: 0,
  },
};

const Delimiter = withClass.div(styles.delimiter);
const Field = withClass.div(styles.field);

export interface Props {
  group: GroupWithKey;
  segments: Segment[];
  onSegmentAdd(segment: Segment): void;
  onSegmentListAdd(segments: Segment[]): void;
  onSegmentRemove(segment: Segment): void;
  onGroupRemove(group: string): void;
  onGroupClose(group: string): void;
  onGroupReplace?(oldGroup: string, newGroup: string): void;
  selectedGroups?: GroupWithKey[];
  initiallyOpened?: boolean;
}

const SegmentGroupItem = React.memo(
  ({
    group,
    segments,
    onSegmentAdd,
    onSegmentListAdd,
    onSegmentRemove,
    onGroupRemove,
    onGroupClose,
    onGroupReplace,
    selectedGroups,
    initiallyOpened,
  }: Props) => {
    const { t } = useTranslation();

    return (
      <motion.div
        key={group.key}
        className={baseStyles.block}
        variants={variants}
        initial="enter"
        animate="mounted"
        exit="exit"
        transition={defaultTransition(0.2)}
        layout="position"
      >
        <div className={styles.item}>
          <Delimiter>
            <Typography variant="single-11-pt" className={styles.text}>
              {t<string>('customize.and', 'and')}
            </Typography>
          </Delimiter>
          <Field>
            <SegmentsDroplist
              group={group.id}
              selected={segments}
              position="right top"
              onAdd={onSegmentAdd}
              onRemove={onSegmentRemove}
              onAddAll={onSegmentListAdd}
              onRemoveAll={onGroupRemove}
              onGroupReplace={onGroupReplace}
              selectedGroups={selectedGroups}
              ignoreTriggerAnchor="[data-anchor='group-remove-button']"
              initiallyOpened={initiallyOpened}
              initiallyOpenedDelay={300}
              trigger={({ opened, triggerProps }) => (
                <SegmentGroup
                  group={group.id}
                  segments={segments}
                  onGroupClose={onGroupClose}
                  onSegmentDelete={onSegmentRemove}
                  active={opened}
                  tooltipContent={t('customize.edit', 'Edit')}
                  tooltipDisabled={opened}
                  {...triggerProps}
                />
              )}
            />
          </Field>
        </div>
      </motion.div>
    );
  },
);

export default SegmentGroupItem;
