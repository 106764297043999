import { useState, useEffect } from 'react';
import { filterLikelihoodToCancel } from '../utils/filterLikelihoodToCancel';
import { Segment } from '../types';
import useC1XSegmentsQuery from './useC1XSegmentsQuery';

const getSegmentCxAttribute = (segment: Segment) =>
  segment.annotations?.find((annotation) => annotation.key === 'composer1x');

export default function useSegmentsByGroup(groupName: string) {
  const { data } = useC1XSegmentsQuery();

  const [segments, setSegments] = useState<Segment[]>(
    (data?.segments ?? []) as Segment[],
  );

  useEffect(() => {
    if (data?.segments) {
      const filteredSegments = filterLikelihoodToCancel(
        data.segments as Segment[],
      ).filter((segment) => segment.group === groupName);

      const orderedSegments = filteredSegments.sort((a, b) => {
        const aCxAttr = getSegmentCxAttribute(a);
        const bCxAttr = getSegmentCxAttribute(b);

        return (
          (aCxAttr && bCxAttr
            ? aCxAttr.value?.localeCompare(bCxAttr.value)
            : a.item?.localeCompare(b.item ?? '')) ?? 0
        );
      });

      setSegments(orderedSegments);
    }
  }, [data, setSegments, groupName]);

  return segments;
}
