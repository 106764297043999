import React from 'react';
import { Header, Table, TableBody } from '@piano-reports/ui-kit/src/Table';
import { DateRange, Segment } from '../../../types';
import {
  AUDIENCE_COVERAGE_KEY,
  EVENTS_KEY,
  PAGE_VIEWS_PER_USER_KEY, SEGMENT_NAME,
  UNIQUE_USERS_KEY,
} from '../../../consts';
import useSegmentTable from '../../../hooks/useSegmentTable';
import SegmentsHeader from '../SegmentsHeader';
import ValueHeader from '../ValueHeader';
import SegmentRow from '../SegmentRow';

export const tableHeaders: Record<string, string> = {
  audienceCoverage: 'Audience coverage',
  events: 'Pageviews',
  uniqueUsers: 'Unique visitors',
  pageViewsPerUser: 'PVs per user',
};

export const columns = [
  AUDIENCE_COVERAGE_KEY,
  EVENTS_KEY,
  UNIQUE_USERS_KEY,
  PAGE_VIEWS_PER_USER_KEY,
];

export interface Props {
  segmentIds?: string[];
  segments?: Segment[];
  filterSegmentId?: string;
  className?: string;
  range: DateRange;
  headerTop?: number;
}

const DataTable = ({
  segmentIds,
  segments: selectedSegments,
  filterSegmentId,
  range,
  className,
  headerTop,
}: Props) => {
  const {
    segments,
    sorting,
    setData,
    setSorting,
    maxPageviews,
  } = useSegmentTable({
    segments: selectedSegments,
    segmentIds,
    range,
  });

  return (
    <Table className={className}>
      <Header>
        <SegmentsHeader
          segments={segments}
          top={headerTop}
          sorting={sorting?.field === SEGMENT_NAME ? sorting.direction : undefined}
          active={sorting?.field === SEGMENT_NAME}
          onClick={() => setSorting(SEGMENT_NAME)}
        />
        {columns.map((key) => (
          <ValueHeader
            key={key}
            top={headerTop}
            sorting={sorting?.field === key ? sorting.direction : undefined}
            active={sorting?.field === key}
            onClick={() => setSorting(key)}
          >
            {tableHeaders[key]}
          </ValueHeader>
        ))}
      </Header>
      <TableBody>
        {segments.map((segment) => (
          <SegmentRow
            key={segment.id}
            segment={segment}
            filterSegmentId={filterSegmentId}
            range={range}
            onDataUpdate={setData}
            maxPageviews={maxPageviews}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default DataTable;
