import React, { useState } from 'react';
import { DateRange } from '@piano-reports/summary-widgets/src/types';
import { Sorting } from '@piano-reports/summary-widgets/src/hooks/useSortedSegments';
import { Report, SegmentsView, SidebarMode } from '../../interfaces';
import useDefaultSettings from '../../hooks/useDefaultSettings';
import useScopeSettings from '../../hooks/useScopeSettings';
import GlobalSettingsContext from './GlobalSettingsContext';

export interface Props {
  children: React.ReactNode;
}

const GlobalSettingsProvider = ({ children }: Props) => {
  const { userId, scope } = useScopeSettings()
  const defaultSettings = useDefaultSettings(userId, scope);

  const [report, setReport] = useState<Report>(
    defaultSettings.report as Report,
  );
  const [range, setRange] = useState<DateRange>(defaultSettings.range);
  const [view, setView] = useState<SegmentsView>(defaultSettings.view);
  const [sorting, setSorting] = useState<Sorting | undefined>(
    defaultSettings.sorting,
  );
  const [sidebarMode, setSidebarMode] = useState<SidebarMode>(SidebarMode.LIST);

  return (
    <GlobalSettingsContext.Provider
      value={{
        report,
        setReport,
        range,
        setRange,
        view,
        setView,
        sorting,
        setSorting,
        sidebarMode,
        setSidebarMode,
      }}
    >
      {children}
    </GlobalSettingsContext.Provider>
  );
};

export default GlobalSettingsProvider;
