import React, { useCallback, useEffect, useState } from 'react';
import { Preset, Report } from '../../interfaces';
import useScopeSettings from '../../hooks/useScopeSettings';
import { getLocalStoragePresetsKey } from '../../utils/settings';
import {
  convertToPreset,
  isDefaultPreset,
  shiftPreset,
} from '../../utils/presets';
import HistoricalPresetsContext from './HistoricalPresetsContext';

const MAX_SAVED_PRESET_COUNT = 5;

export interface Props {
  children: React.ReactNode;
}

const HistoricalPresetsProvider = ({ children }: Props) => {
  const { userId, scope } = useScopeSettings();
  const [savedPresets, setPresets] = useState<Preset[] | null>(null);

  const saveToLocalStorage = useCallback(
    (list: Preset[]) => {
      try {
        window.localStorage.setItem(
          getLocalStoragePresetsKey(userId, scope),
          JSON.stringify(list),
        );
      } catch (e) {
        console.error(e);
      }
    },
    [userId, scope],
  );

  const getFromLocalStorage = useCallback(() => {
    try {
      const result = window.localStorage.getItem(
        getLocalStoragePresetsKey(userId, scope),
      );
      return result ? (JSON.parse(result) as Preset[]) : [];
    } catch (e) {
      console.error(e);
    }
  }, [userId, scope]);

  const setPreset = useCallback(
    (report: Report, saveOrigin?: boolean) => {
      setPresets((oldPresets) => {
        if (report && oldPresets && !saveOrigin) {
          const newPreset: Preset = convertToPreset(report);

          const isDefaultReport = isDefaultPreset(newPreset);

          if (!isDefaultReport) {
            const presets = shiftPreset(newPreset, oldPresets).slice(
              0,
              MAX_SAVED_PRESET_COUNT,
            );
            saveToLocalStorage(presets);

            return presets;
          }
        }

        return oldPresets;
      });
    },
    [saveToLocalStorage, setPresets],
  );

  useEffect(() => {
    const presets = getFromLocalStorage();
    if (presets) {
      setPresets(presets);
    }
  }, [getFromLocalStorage, setPresets]);

  return (
    <HistoricalPresetsContext.Provider
      value={{ setPreset, savedPresets: savedPresets ?? [] }}
    >
      {children}
    </HistoricalPresetsContext.Provider>
  );
};

export default HistoricalPresetsProvider;
