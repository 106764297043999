import React, { useState } from 'react';
import withClass from '@piano-react/components/withClass';
import { Row, HeaderRow } from '@piano-dc/react/list';
import { Sorting } from '@piano-reports/summary-widgets/src/hooks/useSortedSegments';
import {
  AUDIENCE_COVERAGE_KEY,
  EVENTS_KEY,
  PAGE_VIEWS_PER_USER_KEY,
  SEGMENT_NAME,
  UNIQUE_USERS_KEY,
} from '@piano-reports/summary-widgets/src/consts';
import { useTranslation } from 'react-i18next';
import useLocalizedSortingVariants from './useLocalizedSortingVariants';
import styles from './SortingVariants.module.css';

const Container = withClass.div(styles.container);

const sortingFields = [
  null,
  SEGMENT_NAME,
  AUDIENCE_COVERAGE_KEY,
  EVENTS_KEY,
  UNIQUE_USERS_KEY,
  PAGE_VIEWS_PER_USER_KEY,
];

export interface Props {
  sorting: Sorting | undefined;
  onSelect(sorting: Sorting | undefined): void;
}

const SortingVariants = ({ sorting, onSelect }: Props) => {
  const { t } = useTranslation();
  const translateVariant = useLocalizedSortingVariants();
  const [selectedField, selectField] = useState<string | null>(
    sorting?.field ?? null,
  );

  return (
    <>
      <Container>
        <HeaderRow>{t('sorting.sortBy', 'Sort by')}</HeaderRow>
        {sortingFields.map((field) => (
          <Row
            key={field ?? 'default'}
            selected={field === selectedField}
            className={styles.row}
            onClick={() => {
              selectField(field);
              if (field === null) {
                onSelect(undefined);
              }
            }}
          >
            {translateVariant(field)}
          </Row>
        ))}
      </Container>
      {selectedField && (
        <Container className={styles.additional}>
          <HeaderRow>{t('sorting.orderBy', 'Order by')}</HeaderRow>
          {selectedField === SEGMENT_NAME ? (
            <>
              <Row
                className={styles.row}
                onClick={() =>
                  onSelect({ field: selectedField, direction: 'asc' })
                }
                selected={
                  (!sorting || sorting.field === selectedField) &&
                  sorting?.direction === 'asc'
                }
              >
                {t('sorting.AZ', 'A — Z')}
              </Row>
              <Row
                className={styles.row}
                onClick={() =>
                  onSelect({ field: selectedField, direction: 'desc' })
                }
                selected={
                  (!sorting || sorting.field === selectedField) &&
                  sorting?.direction === 'desc'
                }
              >
                {t('sorting.ZA', 'Z — A')}
              </Row>
            </>
          ) : (
            <>
              <Row
                className={styles.row}
                onClick={() =>
                  onSelect({ field: selectedField, direction: 'desc' })
                }
                selected={
                  (!sorting || sorting.field === selectedField) &&
                  sorting?.direction === 'desc'
                }
              >
                {t('sorting.desc', 'Highest first')}
              </Row>
              <Row
                className={styles.row}
                onClick={() =>
                  onSelect({ field: selectedField, direction: 'asc' })
                }
                selected={
                  (!sorting || sorting.field === selectedField) &&
                  sorting?.direction === 'asc'
                }
              >
                {t('sorting.asc', 'Lowest first')}
              </Row>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default SortingVariants;
