import React from 'react';
import { DroplistProps } from '@piano-dc/react/droplist';
import DroplistWithSearch from '@piano-reports/ui-kit/src/DroplistWithSearch';
import GroupsHeader from './GroupsHeader';
import SegmentGroupList from './SegmentGroupList';

export interface SegmentGroupDroplistProps extends DroplistProps {
  onSelect(group: string): void;
  selectedGroups?: string[];
}

const SegmentGroupDroplist = ({
  onSelect,
  selectedGroups,
  ...rest
}: SegmentGroupDroplistProps) => (
  <DroplistWithSearch header={<GroupsHeader />} {...rest}>
    {(searchString) => (
      <SegmentGroupList
        filter={searchString}
        onSelect={onSelect}
        selectedGroups={selectedGroups}
      />
    )}
  </DroplistWithSearch>
);

export default SegmentGroupDroplist;
