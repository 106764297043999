import React from 'react';
import { RoundIconButton } from '@piano-dc/react/round-button';
import Icon from '@piano-reports/ui-kit/src/Icon';
import { isIframe, sendVXMessage } from '../../utils/vxMessages';
import { SEGMENT_SUMMARY_CLOSE } from '../../actions';

const CloseButton: React.FC = React.memo(() => (
  <RoundIconButton
    appearance="inverse"
    icon={<Icon name="x-default" />}
    onClick={() => {
      if (isIframe()) {
        sendVXMessage(SEGMENT_SUMMARY_CLOSE);
      } else {
        window.history.back();
      }
    }}
  />
));

export default CloseButton;
