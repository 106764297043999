import React from 'react';
import { SegmentTag as SegmentTagBase } from '@piano-react/components/FilterTag';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import styles from './SegmentTag.module.css';

export interface Props {
  segment: Segment;
  onCloseClick(segment: Segment): void;
  removeTooltip?: string;
}

const SegmentTag = ({ segment, onCloseClick, removeTooltip }: Props) => (
  <SegmentTagBase
    onClose={(e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      onCloseClick(segment);
    }}
    className={styles.tag}
    isStatic
    animated={false}
    removeTooltip={removeTooltip}
  >
    {segment.name}
  </SegmentTagBase>
);

export default SegmentTag;
