import { useState, useCallback, useEffect } from 'react';
import { DateRange, Segment } from '../types';
import useSortedSegments, { Sorting } from './useSortedSegments';

export interface SegmentTableOptions {
  segments?: Segment[];
  segmentIds?: string[];
  initialSorting?: Sorting;
  range?: DateRange;
}

export interface SegmentTableResult {
  segments: Segment[];
  sorting?: Sorting;
  setData(key: string, data: Record<string, number | undefined>): void;
  setSorting(key: string): void;
  maxPageviews: number;
}

export default function useSegmentTable({
  segmentIds,
  segments: selectedSegments,
  initialSorting,
  range,
}: SegmentTableOptions): SegmentTableResult {
  const {
    segments,
    setData,
    setSorting: setSortingState,
    data,
    sorting,
  } = useSortedSegments({
    segmentIds,
    segments: selectedSegments,
    initialSorting,
  });

  const [maxPageviews, setMaxPageviews] = useState<number>(0);

  const setSorting = useCallback(
    (key: string) => {
      setSortingState((prevSorting) => {
        if (prevSorting?.field === key) {
          if (prevSorting.direction === 'asc') {
            return undefined;
          } else if (prevSorting.direction === 'desc') {
            return { field: key, direction: 'asc' };
          }
        } else {
          return { field: key, direction: 'desc' };
        }
      });
    },
    [setSortingState],
  );

  useEffect(() => {
    setMaxPageviews(Math.max(...data.map((item) => item.values.events ?? 0)));
  }, [data, maxPageviews, setMaxPageviews]);

  useEffect(() => {
    setMaxPageviews(0);
  }, [range, setMaxPageviews]);

  return {
    segments,
    sorting,
    setData,
    setSorting,
    maxPageviews,
  };
}
