import React from 'react';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import { Segment } from '../../../types';
import styles from './SegmentDescription.module.css';

export const Container = withClass.div(styles.container);

export interface Props {
  segment: Segment;
}

const SegmentDescription = ({ segment }: Props) => (
  <Container>
    <Typography variant="single-15-pt" className={styles.name}>
      {segment.name}
    </Typography>
    <Typography variant="multi-13-pt" className={styles.description}>
      {segment.description}
    </Typography>
  </Container>
);

export default SegmentDescription;
