import React from 'react';
import withClass from '@piano-react/components/withClass';
import { DateRange, Segment } from '../../../types';
import Singletons from '../Singletons';
import Chart from '../Chart';
import useSegmentSummaryData from '../../../api/useSegmentSummaryData';
import GaugeChart from '../GaugeChart';
import styles from './SegmentSummaryData.module.css';
import SegmentSummaryDataSkeleton from './SegmentSummaryDataSkeleton';

export const Insights = withClass.div(styles.insights);

export interface Props {
  segments: Segment[];
  filterSegmentId?: string;
  range: DateRange;
}

const SegmentSummaryData = ({ segments, filterSegmentId, range }: Props) => {
  const { data, status, error, isFetching } = useSegmentSummaryData({
    segments,
    filterSegmentId,
    range,
  });

  if (error) throw error;

  if (status === 'loading') {
    return <SegmentSummaryDataSkeleton />;
  }

  return data ? (
    <>
      <Insights>
        <GaugeChart data={data} range={range} />
        <Singletons data={data} range={range} isFetching={isFetching} />
      </Insights>
      <Chart data={data} range={range} />
    </>
  ) : null;
};

export default SegmentSummaryData;
