import React from 'react';
import { useQueryErrorResetBoundary } from 'react-query';
import '@piano-dc/atoms/icons/duotone100/FormaAnxietyDuotone100.icon';
import GhostButton from '@piano-dc/react/button-ghost';
import Typography from '@piano-dc/react/typography';
import Icon from '@piano-reports/ui-kit/src/Icon';
import ErrorHeader from '../AppHeader/ErrorHeader';
import i18next from '../../i18n';
import styles from './App.module.css';

export interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}

const AppError = ({ resetErrorBoundary }: Props) => {
  const { reset } = useQueryErrorResetBoundary();

  const handleReset = () => {
    reset();
    resetErrorBoundary();
  }

  return (
    <>
      <ErrorHeader/>
      <div className={styles.errorContainer}>
        <div className={styles.error}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <forma-anxiety-duotone100-icon className={styles.icon}></forma-anxiety-duotone100-icon>
          <Typography variant="multicompact-13-pt" className={styles.row}>
            {i18next.t<string>('appError.header', 'Something went wrong.')}
            <br/>
            {i18next.t<string>('appError.contact', 'Please try again or ')}{' '}
            <a href="mailto:support@piano.io" target="_blank" rel="noreferrer">
              {i18next.t<string>('appError.contactSupport', 'contact support')}
            </a>
          </Typography>
          <GhostButton icon={<Icon name="refresh"/>} onClick={handleReset}>
            {i18next.t<string>('appError.refresh', 'Refresh')}
          </GhostButton>
        </div>
      </div>
    </>
  );
}

export default AppError;
