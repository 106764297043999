export const BRAND_RELATIONSHIP = 'Brand Relationship';
export const USER_FREQUENCY = 'User Frequency';
export const USER_RECENCY = 'User Recency';
export const LIKELIHOOD_TO_CANCEL = 'Likelihood to Cancel';
export const EXPIRING_SUBSCRIBERS = 'Expiring Subscribers';
export const SUBSCRIPTION_LENGTH = 'Subscription Length';
export const USER_INTEREST = 'User Interest';
export const EXPIRING_CREDIT_CARDS = 'Expiring Credit Cards';
export const SUBSCRIPTION_TENURE = 'Subscription Tenure';
export const CHECKOUT_ABANDONMENT = 'Checkout Abandonment';
