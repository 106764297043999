import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query'
import { CxClient } from '@piano-data/cx-client';
import CxDataContext from '../CxDataContext';

const queryClient = new QueryClient();

export interface Props {
  client: CxClient;
  context?: Record<string, any>;
  scope: string;
  scopeType: 'aid' | 'siteGroupId';
  children: React.ReactNode;
}

const CxDataProvider = ({
  client,
  context = {},
  children,
  scope,
  scopeType = 'aid',
}: Props) => {
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    const newContext = { ...context };

    if (scope) {
      if (scopeType === 'aid') {
        newContext.aid = scope;
      } else {
        newContext.siteGroupId = scope;
      }
    }

    client.setContext(newContext);
    setReady(true);
  }, [client, context, scope, scopeType, setReady]);

  return ready ? (
    <QueryClientProvider client={queryClient}>
      <CxDataContext.Provider value={{ client, context, scopeType, scope }}>
        {children}
      </CxDataContext.Provider>
    </QueryClientProvider>
  ) : null;
};

export default CxDataProvider;
