import { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import {
  BooleanFilter,
  SegmentFilter,
  TrafficFilter,
} from '@piano-data/cx-client';
import { DateRange, Segment } from '../types';
import useDataWithCalculatedMetrics from './useDataWithCalculatedMetrics';
import { SegmentDataResult } from './useSegmentData';
import useSegmentTrafficQuery from './useSegmentTrafficQuery';

export const buildFilters = (
  segments: Segment[],
  filterSegmentId?: string,
): TrafficFilter[] => {
  const groups = groupBy(segments, 'group');

  const filters: SegmentFilter[] = Object.values(groups).map((g) => ({
    type: 'segment',
    items: g.map((segment) => segment.id),
  }));

  const baseSegmentFilter = filterSegmentId
    ? ({ type: 'segment', item: filterSegmentId } as SegmentFilter)
    : null;

  if (Object.keys(groups).length > 1 || baseSegmentFilter) {
    return [
      {
        type: 'and',
        filters: baseSegmentFilter ? [baseSegmentFilter, ...filters] : filters,
      } as BooleanFilter,
    ];
  }

  return filters;
};

export interface SegmentSetSummaryDataOptions {
  segments: Segment[];
  filterSegmentId?: string;
  range: DateRange;
}

export default function useSegmentSummaryData({
  segments,
  filterSegmentId,
  ...options
}: SegmentSetSummaryDataOptions): SegmentDataResult {
  const filters = useMemo<TrafficFilter[]>(
    () => buildFilters(segments, filterSegmentId),
    [segments, filterSegmentId],
  );

  const segmentTrafficQueryResult = useSegmentTrafficQuery({
    ...options,
    filters,
  });

  return useDataWithCalculatedMetrics(segmentTrafficQueryResult, options);
}
