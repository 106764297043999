import React from 'react';
import Gauge from '../../GaugeChart';
import { Container } from './index';

const GaugeChartSkeleton = () => {
  return (
    <Container>
      <Gauge type="large" isLoading />
    </Container>
  );
};

export default GaugeChartSkeleton;
