import React from 'react';
import withClass from '@piano-react/components/withClass';
import formatInteger from '@piano-react/utils/numberFormat/formatInteger';
import formatPercent from '@piano-react/utils/numberFormat/formatPercent';
import formatDouble from '@piano-react/utils/numberFormat/formatDouble';
import { useTranslation } from 'react-i18next';
import { SegmentData } from '../../../api/useSegmentData';
import { Singleton } from '../../Singleton';
import { DATA_1, DATA_2 } from '../../../colors';
import styles from './Singletons.module.css';

export const Container = withClass.div(styles.container);
const StyledSingleton = withClass(Singleton, styles.singleton);

export interface Props {
  data: SegmentData;
}

const Singletons = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledSingleton
        title={t('singletons.audienceCoverage', 'Audience coverage')}
        content={
          typeof data.data.audienceCoverage !== 'undefined'
            ? formatPercent(data.data.audienceCoverage)
            : '-'
        }
        color={DATA_2}
        size="small"
      />
      <StyledSingleton
        title={t('singletons.pageviews', 'Pageviews')}
        content={
          typeof data.data.events !== 'undefined'
            ? formatInteger(data.data.events)
            : '-'
        }
        color={DATA_1}
        size="small"
      />
      <StyledSingleton
        title={t('singletons.uniqueUsers', 'Unique visitors')}
        content={
          typeof data.data.uniqueUsers !== 'undefined'
            ? formatInteger(data.data.uniqueUsers)
            : '-'
        }
        size="small"
      />
      <StyledSingleton
        title={t('singletons.pageviewsPerUser', 'PVs / user')}
        content={
          typeof data.data.pageViewsPerUser !== 'undefined'
            ? formatDouble(data.data.pageViewsPerUser)
            : '-'
        }
        size="small"
      />
    </Container>
  );
}

export default Singletons;
