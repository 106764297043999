import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import difference from 'lodash/difference';
import { Segment } from '../types';
import useSegments from '../api/useSegments';
import { SEGMENT_NAME } from '../consts';

export interface Sorting {
  field: string;
  direction: 'asc' | 'desc';
}

export interface SortedSegmentsOptions {
  segments?: Segment[];
  segmentIds?: string[];
  initialSorting?: Sorting;
}

export interface SortedSegmentsResult {
  segments: Segment[];
  sorting?: Sorting;
  setData(key: string, data: Record<string, number | undefined>): void;
  data: Data[];
  setSorting: Dispatch<SetStateAction<Sorting | undefined>>;
}

interface Data {
  segmentId: string;
  values: Record<string, number | undefined>;
}

export default function useSortedSegments({
  segmentIds,
  segments: selectedSegments,
  initialSorting,
}: SortedSegmentsOptions): SortedSegmentsResult {
  // The value also memoized inside the hook
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const segments =
    useSegments({ segments: selectedSegments, segmentIds }) ?? [];

  const [data, setStateData] = useState<Data[]>([]);
  const [sorting, setSorting] = useState<Sorting | undefined>(initialSorting);

  const setData = useCallback(
    (key: string, values: Record<string, number | undefined>) => {
      setStateData((prevData) => {
        if (prevData.some((item) => item.segmentId === key)) {
          return prevData.map((item) =>
            item.segmentId === key ? { ...item, values } : item,
          );
        } else {
          return [...prevData, { segmentId: key, values }];
        }
      });
    },
    [setStateData],
  );

  useEffect(() => {
    const dataKeys = data.map((item) => item.segmentId);

    if (dataKeys.length > 0) {
      const diff = difference(
        dataKeys,
        segments.map((segment) => segment.id),
      );

      if (diff.length > 0) {
        setStateData((state) =>
          state.filter((item) => !diff.includes(item.segmentId)),
        );
      }
    }
  }, [segments, setStateData, data]);

  const orderedSegments = useMemo(() => {
    if (!sorting) {
      return segments;
    }
    const { field } = sorting;

    if (field === SEGMENT_NAME) {
      return [...segments].sort((a, b) =>
        sorting.direction === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name),
      );
    }

    const sortedKeys = [...data].sort((a, b) => {
      if (typeof a.values[field] === 'undefined') return 1;
      if (typeof b.values[field] === 'undefined') return -1;

      return sorting.direction === 'asc'
        ? (a.values[field] ?? 0) - (b.values[field] ?? 0)
        : (b.values[field] ?? 0) - (a.values[field] ?? 0);
    });

    return [
      ...sortedKeys.map(({ segmentId }) =>
        segments.find((segment) => segment.id === segmentId),
      ),
      ...segments.filter(
        (segment) => !sortedKeys.find((item) => item.segmentId === segment.id),
      ),
    ].filter(Boolean) as Segment[];
  }, [data, segments, sorting]);

  return {
    segments: orderedSegments,
    sorting,
    data,
    setData,
    setSorting,
  };
}
