import React from 'react';
import classNames from 'classnames';
import styles from './FilterField.module.css';

export interface Props {
  active?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const FilterField = ({ children, active, className, ...rest }: Props) => (
  <div
    className={classNames(styles.container, className, {
      [styles.active]: active,
    })}
    {...rest}
  >
    {children}
  </div>
);

export default FilterField;
