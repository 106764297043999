import { useMemo } from 'react';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { CxRequestError, Traffic } from '@piano-data/cx-client';
import useTotalTrafficQuery from './useTotalTrafficQuery';
import { SegmentDataResult, SegmentDataOptions } from './useSegmentData';

export default function useDataWithCalculatedMetrics(
  segmentTrafficQuery: UseQueryResult<Traffic.Response>,
  options: SegmentDataOptions,
  queryConfig?: UseQueryOptions<Traffic.Response>,
): SegmentDataResult {
  const {
    data: segmentData,
    status: segmentStatus,
    error: segmentError,
  } = segmentTrafficQuery;

  const {
    data: totalData,
    status: totalStatus,
    error: totalError,
  } = useTotalTrafficQuery(options, queryConfig);

  return useMemo<SegmentDataResult>(() => {
    const status = segmentStatus;
    const isFetching =
      segmentStatus === 'loading' ||
      totalStatus === 'loading';
    const error = (segmentError ?? totalError) as CxRequestError | undefined;

    if (!segmentData) {
      return {
        data: undefined,
        status,
        isFetching,
        error,
      };
    }

    const result: SegmentDataResult = {
      data: {
        start: new Date(segmentData.start * 1000),
        stop: new Date(segmentData.stop * 1000),
        history: segmentData.history.map(
          (timestamp) => new Date(timestamp * 1000),
        ),
        data: {
          ...segmentData.data,
          pageViewsPerUser:
            segmentData.data.events / (segmentData.data.uniqueUsers || 1),
          audienceCoverage: undefined,
        },
        historyData: {
          ...segmentData.historyData,
          pageViewsPerUser: segmentData.historyData.events.map(
            (pageViews, i) =>
              pageViews / (segmentData.historyData.uniqueUsers[i] || 1),
          ),
          audienceCoverage: undefined,
        },
      },
      status,
      isFetching,
      error,
    };

    if (totalData) {
      const { data } = result;
      if (data) {
        data.data.audienceCoverage =
          (data.data.uniqueUsers ?? 0) / (totalData.data.uniqueUsers || 1);

        data.historyData = {
          ...data.historyData,
          audienceCoverage: totalData.historyData.uniqueUsers.map(
            (totalUsers, i) =>
              (data.historyData.uniqueUsers
                ? data.historyData.uniqueUsers[i]
                : 0) / (totalUsers || 1),
          ),
        };
      }
    }

    return result;
  }, [
    segmentStatus,
    totalStatus,
    segmentData,
    totalData,
    segmentError,
    totalError,
  ]);
}
