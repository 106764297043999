import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@piano-dc/react/icon-button';
import Icon from '@piano-reports/ui-kit/src/Icon';
import { HeaderRow } from '@piano-dc/react/list';
import { motion } from 'framer-motion';
import { defaultTransition } from '@piano-react/components/animateConfig';
import styles from './SegmentsListHeader.module.css';

const variants = {
  segments: {
    width: 20,
    opacity: 1,
  },
  groups: {
    width: 0,
    opacity: 0,
  },
};

export interface Props {
  onClick: () => void;
  groupsAvailable?: boolean;
  isGroupsSelection?: boolean;
}

const SegmentsListHeader = ({
  onClick,
  groupsAvailable,
  isGroupsSelection,
}: Props) => {
  const { t } = useTranslation();

  const header = isGroupsSelection
    ? t('customize.selectGroup', 'Select group')
    : t('customize.selectSegments', 'Select segments');

  return groupsAvailable ? (
    <div className={styles.header}>
      <motion.div
        className={styles.icon}
        variants={variants}
        initial={false}
        animate={isGroupsSelection ? 'groups' : 'segments'}
        transition={defaultTransition(0.2)}
      >
        <IconButton onClick={onClick} icon={<Icon name="chevron-back" />} />
      </motion.div>
      <HeaderRow>{header}</HeaderRow>
    </div>
  ) : (
    <HeaderRow>{header}</HeaderRow>
  );
};

export default SegmentsListHeader;
