import React from 'react';
import TextPreloader from '@piano-react/components/Preloader/TextPreloader';
import styles from './SortingPanel.module.css';
import { Container, Panel } from './index';

const SortingPanelSkeleton = () => (
  <Container>
    <Panel>
      <TextPreloader variant="single-15-pt" width={72} />
      <span>
        <TextPreloader variant="single-15-pt" width={60} />
        <TextPreloader
          variant="single-15-pt"
          width={57}
          className={styles.preloaderLast}
        />
      </span>
    </Panel>
  </Container>
);

export default SortingPanelSkeleton;
