import React from 'react';
import withClass from '@piano-react/components/withClass';
import { SingletonSize } from '../../Singleton';
import SingletonSkeleton from '../../Singleton/SingletonSkeleton';
import { Container } from './index';
import DescriptionPreloader from './DescriptionPreloader';
import styles from './Singletons.module.css';

const StyledSingletonSkeleton = withClass(SingletonSkeleton, styles.singleton);

const SingletonsSkeleton = () => (
  <Container>
    <StyledSingletonSkeleton
      size={SingletonSize.LARGE}
      description={<DescriptionPreloader />}
      className={styles.extended}
      titleWidth={136}
      valueWidth={70}
    />
    <StyledSingletonSkeleton
      size={SingletonSize.LARGE}
      description={<DescriptionPreloader threeLine />}
      className={styles.extended}
      titleWidth={75}
      valueWidth={100}
    />
    <StyledSingletonSkeleton
      size={SingletonSize.LARGE}
      titleWidth={92}
      valueWidth={100}
    />
    <StyledSingletonSkeleton
      size={SingletonSize.LARGE}
      titleWidth={70}
      valueWidth={60}
    />
  </Container>
);

export default SingletonsSkeleton;
