import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import cookieUnderscoreDetector from '@piano-react/utils/i18next-cookie-underscore-detector';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(cookieUnderscoreDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    ns: ['translation'],
    defaultNS: 'translation',
    detection: {
      order: [
        'cookieUnderscore',
        'querystring',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      lookupCookie: 'LANG',
    },
  });

export default i18n;
