import React, { ReactNode } from 'react';
import Typography from '@piano-dc/react/typography';
import styles from './Empty.module.css';

const Empty: React.FC<{ children: ReactNode }> = ({ children }) => (
  <div className={styles.container}>
    <Typography variant="single-11-pt">{children}</Typography>
  </div>
);

export default Empty;
