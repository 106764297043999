import React from 'react';
import TextPreloader from '@piano-react/components/Preloader/TextPreloader';
import IconPreloader from '@piano-react/components/Preloader/IconPreloader';
import styles from './Title.module.css';

const TitleSkeleton: React.FC = () => (
  <div className={styles.title}>
    <IconPreloader className={styles.icon} />
    <TextPreloader variant="single-13-pt" width={120} />
  </div>
);

export default TitleSkeleton;
