import React from 'react';
import ModalWindow, {
  Header,
  Content,
  Footer,
} from '@piano-react/components/ModalWindow';
import { Button } from '@piano-dc/react/button';
import { ButtonsList } from '@piano-react/components/ButtonsList';
import { RoundIconButton } from '@piano-dc/react/round-button';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import Icon from '@piano-reports/ui-kit/src/Icon';
import '@piano-dc/atoms/icons/duotone100/FormaSegmentDuotone100.icon';
import { useTranslation, Trans } from 'react-i18next';
import styles from './OnboardingModal.module.css';

const StyledHeader = withClass(Header, styles.header);
const StyledContent = withClass(Content, styles.content);
const StyledFooter = withClass(Footer, styles.footer);
const DocText = withClass(Typography, styles.doc);

export interface Props {
  opened?: boolean;
  onSkip(): void;
  onTour(): void;
}

const OnboardingModal = ({ opened, onSkip, onTour }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalWindow opened={opened}>
      <StyledHeader>
        <RoundIconButton icon={<Icon name="x-default" />} onClick={onSkip} />
      </StyledHeader>
      <StyledContent>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <forma-segment-duotone100-icon></forma-segment-duotone100-icon>
        <Typography variant="single-18-pt" className={styles.welcome}>
          {t('onboarding.welcome', 'Welcome to segment summary')}
        </Typography>
        <Typography variant="multicompact-15-pt" className={styles.explanation}>
          {t(
            'onboarding.welcomeText',
            'A holistic understanding of your segments. Create custom combinations, view, analyze, and target on your segments.',
          )}
        </Typography>
      </StyledContent>
      <StyledFooter>
        <DocText>
          <Icon name="book" className={styles.docIcon} />
          <Trans i18nKey="onboarding.docLinkText">
            Learn more about this report in{' '}
            <a className={styles.docLink} href="https://docs.piano.io">
              Documentation
            </a>
          </Trans>
        </DocText>
        <ButtonsList>
          <Button appearance="secondary" onClick={onSkip}>
            {t('onboarding.skip', 'Skip')}
          </Button>
          <Button appearance="primary" onClick={onTour}>
            {t('onboarding.takeATour', 'Take a tour')}
          </Button>
        </ButtonsList>
      </StyledFooter>
    </ModalWindow>
  );
};

export default OnboardingModal;
