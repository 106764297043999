import React, { useCallback, useContext, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import withClass from '@piano-react/components/withClass';
import GlobalSettingsContext from '../GlobalSettings/GlobalSettingsContext';
import { SidebarMode } from '../../interfaces';
import usePrevious from '../../hooks/usePrevious';
import usePresetsStore from '../../hooks/usePresetsStore';
import DateRangePicker from '../DateRangePicker';
import ReportDescription from '../ReportDescription';
import CustomizeCriteria from '../CustomizeCriteria';
import PresetsList from '../PresetsList';
import styles from './Sidebar.module.css';

export const Container = withClass.div(styles.sidebar);
export const Top = withClass.div(styles.top);
export const Content = withClass.div(styles.content);

const Sidebar: React.FC = () => {
  const { sidebarMode, setSidebarMode } = useContext(GlobalSettingsContext);
  const { report } = useContext(GlobalSettingsContext);
  const { setPreset } = usePresetsStore();
  const prevSidebarMode = usePrevious(sidebarMode);

  useEffect(() => {
    if (
      report &&
      ((prevSidebarMode === SidebarMode.LIST &&
        sidebarMode === SidebarMode.CUSTOMIZE) ||
        (prevSidebarMode === SidebarMode.CUSTOMIZE &&
          sidebarMode === SidebarMode.LIST))
    ) {
      setPreset(report);
    }
  }, [sidebarMode, prevSidebarMode, setPreset, report]);

  const setCustomizeMode = useCallback(() => {
    setSidebarMode(SidebarMode.CUSTOMIZE);
  }, [setSidebarMode]);

  const setListMode = useCallback(() => {
    setSidebarMode(SidebarMode.LIST);
  }, [setSidebarMode]);

  const setPresetsMode = useCallback(() => {
    setSidebarMode(SidebarMode.PRESETS);
  }, [setSidebarMode]);

  return (
    <Container>
      <Top>
        <DateRangePicker />
      </Top>
      <AnimatePresence initial={false} exitBeforeEnter>
        <Content>
          {sidebarMode === SidebarMode.LIST ? (
            <ReportDescription
              key={SidebarMode.LIST}
              onEditClick={setCustomizeMode}
              onHeaderClick={setPresetsMode}
            />
          ) : null}
          {sidebarMode === SidebarMode.CUSTOMIZE ? (
            <CustomizeCriteria
              key={SidebarMode.CUSTOMIZE}
              onBackClick={setListMode}
              onHeaderClick={setPresetsMode}
            />
          ) : null}
          {sidebarMode === SidebarMode.PRESETS ? (
            <PresetsList key={SidebarMode.PRESETS} onBackClick={setListMode} />
          ) : null}
        </Content>
      </AnimatePresence>
    </Container>
  );
};

export default Sidebar;
