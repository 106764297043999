import React from 'react';
import DataTableSkeleton from '@piano-reports/summary-widgets/src/components/SegmentTable/DataTable/DataTableSkeleton';
import { SegmentsView } from '../../interfaces';
import useSegmentsView from '../../hooks/useSegmentsView';
import SegmentGridSkeleton from '../SegmentGrid/SegmentGridSkeleton';
import { Container } from './index';

const SegmentsSkeleton = React.memo(() => {
  const { view } = useSegmentsView();

  return (
    <Container>
      {view === SegmentsView.GRID ? (
        <SegmentGridSkeleton />
      ) : (
        <DataTableSkeleton />
      )}
    </Container>
  );
});

export default SegmentsSkeleton;
