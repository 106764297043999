import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import { DroplistTriggerProps } from '@piano-dc/react/popover/types';
import SegmentTag from './SegmentTag';
import FilterGroup from './FilterGroup';

export interface Props extends DroplistTriggerProps {
  group: string;
  segments: Segment[];
  onGroupClose(group: string): void;
  onSegmentDelete(segment: Segment): void;
  active?: boolean;
  tooltipContent?: string;
  tooltipDisabled?: boolean;
}

const SegmentGroup = React.memo(
  ({ group, segments, onGroupClose, onSegmentDelete, ...rest }: Props) => {
    const { t } = useTranslation();

    return (
      <FilterGroup
        group={group}
        onGroupClose={onGroupClose}
        emptyText={t('customize.emptyGroup', 'Select segments...')}
        tour
        {...rest}
      >
        {segments && segments.length > 0
          ? segments.map((segment) => (
              <SegmentTag
                key={segment.id}
                segment={segment}
                onCloseClick={onSegmentDelete}
                removeTooltip={t('customize.remove', 'Remove')}
              />
            ))
          : null}
      </FilterGroup>
    );
  },
);

export default SegmentGroup;
