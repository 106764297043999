import React from 'react';
import cn from 'classnames';
import { StickyNode, Stuck } from '../../StickyHeaderStack';
import styles from './Header.module.css';
import {
  HeaderCellContent,
  HeaderCellContentProps,
  StuckHeaderCellContent,
} from './HeaderCellContent';

export interface HeaderProps {
  children?: React.ReactNode;
  className?: string;
}

export const Header = ({ className, children }: HeaderProps) => {
  const count = React.Children.count(children);

  return (
    <thead className={cn(styles.header, className)}>
      <tr>
        {React.Children.map(children, (child, index) =>
          index === count - 1
            ? React.cloneElement(child as React.ReactElement, {
                ...(child as React.ReactElement).props,
                last: true,
              })
            : child,
        )}
      </tr>
    </thead>
  );
};

export interface HeaderCellProps extends HeaderCellContentProps {
  sticky?: boolean;
  top?: number;
}

export const HeaderCell = ({ top = 0, sticky, ...rest }: HeaderCellProps) =>
  sticky ? (
    <StickyNode top={top} stuckContainerClassName={styles.stuck}>
      <HeaderCellContent {...rest} />
      <Stuck>
        <StuckHeaderCellContent {...rest} />
      </Stuck>
    </StickyNode>
  ) : (
    <HeaderCellContent {...rest} />
  );
