import React from 'react';
import withClass from '@piano-react/components/withClass';
import { TextPreloader } from '@piano-react/components/Preloader';
import formatInteger from '@piano-react/utils/numberFormat/formatInteger';
import formatPercent from '@piano-react/utils/numberFormat/formatPercent';
import formatDouble from '@piano-react/utils/numberFormat/formatDouble';
import { useTranslation } from 'react-i18next';
import { SegmentData } from '../../../api/useSegmentData';
import { Singleton } from '../../Singleton';
import { DateRange } from '../../../types';
import { DATA_1, DATA_2 } from '../../../colors';
import styles from './Singletons.module.css';
import AudienceCoverageDescription from './AudienceCoverageDescription';
import PageviewsDescription from './PageviewsDescription';
import Description from './Description';

const formatData = (
  value: number | undefined,
  formatter: (v: number) => string,
) => (typeof value !== 'undefined' ? formatter(value) : '-');

export const Container = withClass.div(styles.container);
const StyledSingleton = withClass(Singleton, styles.singleton);

export interface Props {
  data: SegmentData;
  range: DateRange;
  isFetching?: boolean;
}

const Singletons = ({ data, range, isFetching }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledSingleton
        title={t('singletons.audienceCoverage', 'Audience coverage')}
        content={
          typeof data.data.audienceCoverage === 'undefined' && isFetching ? (
            <TextPreloader variant="single-24-pt" width={70} />
          ) : (
            formatData(data.data.audienceCoverage, formatPercent)
          )
        }
        color={DATA_2}
        size="large"
        description={
          <Description>
            <AudienceCoverageDescription data={data} range={range} />
          </Description>
        }
        className={styles.extended}
      />
      <StyledSingleton
        title={t('singletons.pageviews', 'Pageviews')}
        content={formatData(data.data.events, formatInteger)}
        color={DATA_1}
        size="large"
        description={
          <Description>
            <PageviewsDescription data={data} range={range} />
          </Description>
        }
        className={styles.extended}
      />
      <StyledSingleton
        title={t('singletons.uniqueUsers', 'Unique visitors')}
        content={formatData(data.data.uniqueUsers, formatInteger)}
        size="large"
      />
      <StyledSingleton
        title={t('singletons.pageviewsPerUser', 'PVs / user')}
        content={
          typeof data.data.pageViewsPerUser === 'undefined' && isFetching ? (
            <TextPreloader variant="single-24-pt" width={70} />
          ) : (
            formatData(data.data.pageViewsPerUser, formatDouble)
          )
        }
        size="large"
      />
    </Container>
  );
};

export default Singletons;
