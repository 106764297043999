import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import IconButton from '@piano-dc/react/icon-button';
import Icon from '@piano-reports/ui-kit/src/Icon';
import {
  FilterField,
  Header,
  FilterFieldPanel,
} from '@piano-reports/ui-kit/src/FilterField';
import { ButtonsList } from '@piano-react/components/ButtonsList';
import FilterList from '@piano-reports/ui-kit/src/FilterList';
import { DroplistTriggerProps } from '@piano-dc/react/popover/types';
import { Tooltip } from '@piano-dc/react/tooltip';
import styles from './FilterGroup.module.css';

export interface Props extends DroplistTriggerProps {
  group: string;
  onGroupClose?(group: string): void;
  readonly?: boolean;
  children: React.ReactNodeArray | null;
  className?: string;
  active?: boolean;
  tooltipContent?: string;
  tooltipDisabled?: boolean;
  emptyText?: string;
  tour?: boolean;
}

const FilterGroup = ({
  group,
  onGroupClose,
  children,
  readonly,
  className,
  active,
  tooltipContent,
  tooltipDisabled,
  emptyText,
  tour,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  return (
    <FilterField
      className={cn(styles.field, className)}
      active={active}
      {...rest}
    >
      <FilterFieldPanel>
        <Header icon={<Icon name="folder-default" />}>{group}</Header>
        <ButtonsList spacing="compact">
          {!readonly
            ? [
                <span key="close" onClick={(e) => e.stopPropagation()}>
                  <Tooltip
                    content={t('customize.remove', 'Remove')}
                    type="small"
                  >
                    <IconButton
                      icon={<Icon name="x-small" />}
                      data-anchor="group-remove-button"
                      data-tour="group-remove-button"
                      onClick={() => onGroupClose && onGroupClose(group)}
                    />
                  </Tooltip>
                </span>,
              ]
            : []}
        </ButtonsList>
      </FilterFieldPanel>
      <FilterList
        delimiterText={t('customize.or', 'or')}
        tooltipContent={tooltipContent}
        tooltipDisabled={tooltipDisabled}
        emptyText={emptyText}
      >
        {children}
      </FilterList>
    </FilterField>
  );
};

export default FilterGroup;
