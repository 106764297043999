import React from 'react';
import SidebarSkeleton from '../Sidebar/SidebarSkeleton';
import ReportSkeleton from '../Report/ReportSkeleton';
import { Page, SidebarContainer, ContentContainer } from './index';

const SegmentsPageSkeleton: React.FC = () => (
  <Page>
    <SidebarContainer>
      <SidebarSkeleton />
    </SidebarContainer>
    <ContentContainer>
      <ReportSkeleton />
    </ContentContainer>
  </Page>
);

export default SegmentsPageSkeleton;
