import React from 'react';
import TextPreloader from '@piano-react/components/Preloader/TextPreloader';
import { Panel } from './index';

const TitlePanelSkeleton = () => (
  <Panel>
    <TextPreloader variant="single-15-pt" width={200} />
    <TextPreloader variant="single-15-pt" width={144} />
  </Panel>
);

export default TitlePanelSkeleton;
