import React from 'react';
import { Row } from '@piano-reports/ui-kit/src/Table';
import formatPercent from '@piano-react/utils/numberFormat/formatPercent';
import formatInteger from '@piano-react/utils/numberFormat/formatInteger';
import formatDouble from '@piano-react/utils/numberFormat/formatDouble';
import { TextPreloader } from '@piano-react/components/Preloader';
import SegmentCell from '../SegmentCell';
import ValueCell from '../ValueCell';
import { DateRange, Segment } from '../../../types';
import { SegmentData } from '../../../api/useSegmentData';
import usePageviewPercent from '../../../hooks/usePageviewPercent';
import PageviewsCell from '../PageviewsCell';

const formatData = (
  value: number | undefined,
  formatter: (v: number) => string,
) => (typeof value !== 'undefined' ? formatter(value) : '-');

export interface Props {
  segment: Segment;
  segmentData?: SegmentData;
  isLoading?: boolean;
  range: DateRange;
  maxPageviews: number;
}

const SegmentRow = ({
  segment,
  segmentData,
  isLoading,
  maxPageviews,
  range,
}: Props) => {
  const { data } = segmentData || {};
  const pageViewPercent = usePageviewPercent(segmentData as SegmentData, range);

  return (
    <Row>
      <SegmentCell
        segment={segment}
        segmentData={segmentData}
        pageViewPercent={pageViewPercent}
        isLoading={isLoading}
      />
      <ValueCell>
        {isLoading ? (
          <TextPreloader variant="single-15-pt" width={50} />
        ) : (
          formatData(data?.audienceCoverage, formatPercent)
        )}
      </ValueCell>
      <PageviewsCell value={data?.events ?? 0} maxValue={maxPageviews}>
        {isLoading ? (
          <TextPreloader variant="single-15-pt" width={50} />
        ) : (
          formatData(data?.events, formatInteger)
        )}
      </PageviewsCell>
      <ValueCell>
        {isLoading ? (
          <TextPreloader variant="single-15-pt" width={50} />
        ) : (
          formatData(data?.uniqueUsers, formatInteger)
        )}
      </ValueCell>
      <ValueCell>
        {isLoading ? (
          <TextPreloader variant="single-15-pt" width={50} />
        ) : (
          formatData(data?.pageViewsPerUser, formatDouble)
        )}
      </ValueCell>
    </Row>
  );
};

export default SegmentRow;
