import React from 'react';
import { Header, HeaderCell, Table, TableBody } from '@piano-reports/ui-kit/src/Table';
import TextPreloader from '@piano-react/components/Preloader/TextPreloader';
import SegmentRowSkeleton from '../SegmentRow/RowPreloader';
import { columns } from './index';
import styles from './DataTableSkeleton.module.css';

const DataTableSkeleton = () => (
  <Table>
    <Header>
      <HeaderCell>
        <TextPreloader variant="single-13-pt" width={130} />
      </HeaderCell>
      {columns.map((key) => (
        <HeaderCell key={key}>
          <div  className={styles.value}>
            <TextPreloader variant="single-13-pt" width={67} />
          </div>
        </HeaderCell>
      ))}
    </Header>
    <TableBody>
      {new Array(5).fill(null).map((v, i) => (
        <SegmentRowSkeleton key={i} />
      ))}
    </TableBody>
  </Table>
);

export default DataTableSkeleton;
