import React from 'react';
import {
  TextPreloader,
  IconPreloader,
} from '@piano-react/components/Preloader';
import SidebarPanel from '../SidebarPanel';
import PresetSelectSkeleton from './PresetSelectSkeleton';
import styles from './ReportDescription.module.css';

const ReportDescriptionSkeleton = () => (
  <SidebarPanel
    main
    icon={<IconPreloader />}
    header={<PresetSelectSkeleton />}
  >
    <div className={styles.description}>
      <div className={styles.content}>
        <TextPreloader fontSize={16} lineHeight={22} width={225} />
        <TextPreloader fontSize={16} lineHeight={22} width={240} />
        <TextPreloader fontSize={16} lineHeight={22} width={221} />
      </div>
      <TextPreloader variant="single-18-pt" width={103} />
    </div>
  </SidebarPanel>
);

export default ReportDescriptionSkeleton;
