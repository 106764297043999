import React, { useCallback, useState } from 'react';
import { Droplist, DroplistProps } from '@piano-dc/react/droplist';
import DroplistWithSearchContent, {
  DroplistWithSearchContentProps,
} from './DroplistWithSearchContent';

export interface Props
  extends Omit<DroplistProps, 'children'>,
    DroplistWithSearchContentProps {}

const DroplistWithSearch = ({
  header,
  children,
  onOpen,
  onClose,
  ...rest
}: Props) => {
  const [searchStr, setSearchStr] = useState<string | null>(null);

  const handleOpen = useCallback(() => {
    setSearchStr('');
    if (onOpen) {
      onOpen();
    }
  }, [setSearchStr, onOpen]);

  const handleClose = useCallback(() => {
    setSearchStr(null);
    if (onClose) {
      onClose();
    }
  }, [setSearchStr, onClose]);

  return (
    <Droplist
      {...rest}
      maxHeight={400}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <DroplistWithSearchContent header={header} searchStr={searchStr}>
        {children}
      </DroplistWithSearchContent>
    </Droplist>
  );
};

export default DroplistWithSearch;
