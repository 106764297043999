import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { HistoryResolution } from '../../../../types';

export const tooltipTimeFormat = (historyResolution?: HistoryResolution) => (
  value: string,
) => {
  switch (historyResolution) {
    case HistoryResolution.HOUR:
      return format(parseISO(value), 'MMMM d, Y - hh:mm');
    default:
      return format(parseISO(value), 'MMMM d, Y');
  }
};
