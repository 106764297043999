import { niceDomain } from '@piano-insight/charts/utils/domain';
import { ticks } from 'd3-array';

type DomainType = [number, number];

const MIN_TICK_COUNT = 2;
const MAX_TICK_COUNT = 16;
const BASE_TICK_COUNT = 5;

export const fitDomains = (
  primary: DomainType,
  secondary: DomainType,
): [number, DomainType, DomainType] => {
  const primaryDomain = niceDomain(primary, BASE_TICK_COUNT);
  const secondaryDomain = niceDomain(secondary, BASE_TICK_COUNT);

  const primaryTicks = ticks(
    primaryDomain[0],
    primaryDomain[1],
    BASE_TICK_COUNT,
  );
  const secondaryTicks = ticks(
    secondaryDomain[0],
    secondaryDomain[1],
    BASE_TICK_COUNT,
  );

  if (primaryTicks.length !== secondaryTicks.length) {
    let result = getOptimalTicks(
      primaryDomain,
      secondaryDomain,
      BASE_TICK_COUNT,
    );

    if (result) {
      return result;
    }

    result = getOptimalTicks(secondaryDomain, primaryDomain, BASE_TICK_COUNT);

    if (result) {
      return result;
    }
  }

  return [BASE_TICK_COUNT, primaryDomain, secondaryDomain];
};

const getOptimalTicks = (
  primary: DomainType,
  secondary: DomainType,
  tickCount: number,
): [number, DomainType, DomainType] => {
  const domains = domainsMap(primary, secondary);

  const eqDomains = domains
    .filter(
      (d) =>
        d.baseTicks.length === d.currentTicks.length &&
        d.baseTicks.length < MAX_TICK_COUNT,
    )
    .sort(
      (a, b) =>
        Math.abs(a.baseTicks.length - tickCount) -
        Math.abs(b.baseTicks.length - tickCount),
    );

  if (eqDomains.length > 0) {
    const value = eqDomains[0];
    return [
      value.ticksCount,
      value.baseDomain as DomainType,
      value.currentDomain as DomainType,
    ];
  }

  return [tickCount, primary, secondary];
};

const domainsMap = (base: DomainType, current: DomainType) => {
  const domains = [];
  for (
    let ticksCount = MIN_TICK_COUNT;
    ticksCount <= MAX_TICK_COUNT;
    ticksCount += 1
  ) {
    const baseTicks = ticks(base[0], base[1], ticksCount);
    const currentDomain = niceDomain(current, ticksCount);
    const currentTicks = ticks(currentDomain[0], currentDomain[1], ticksCount);

    domains.push({
      ticksCount,
      baseTicks,
      baseDomain: base,
      currentTicks,
      currentDomain,
    });
  }

  return domains;
};
