import React, { FC } from 'react';
import cn from 'classnames';
import { TextPreloader } from '@piano-react/components/Preloader';
import { Container, Content, SingletonSize } from './Singleton';
import styles from './Singleton.module.css';

export interface BaseSingletonSkeletonProps {
  className?: string;
  description?: React.ReactElement;
  titleWidth?: number;
  valueWidth?: number;
}

export interface SingletonSkeletonProps extends BaseSingletonSkeletonProps {
  size: SingletonSize;
}

const SmallSingletonSkeleton: FC<BaseSingletonSkeletonProps> = ({
  className,
  titleWidth,
  valueWidth,
}) => (
  <Container className={cn(styles.containerSmall, className)}>
    <TextPreloader
      variant="single-13-pt"
      className={styles.preloader}
      lineHeight={20}
      width={titleWidth}
    />
    <Content>
      <TextPreloader
        variant="single-15-pt"
        className={styles.preloaderValue}
        width={valueWidth}
      />
    </Content>
  </Container>
);

const LargeSingletonSkeleton: FC<BaseSingletonSkeletonProps> = ({
  className,
  description,
  titleWidth,
  valueWidth,
}) => (
  <Container className={cn(styles.containerLarge, className)}>
    <TextPreloader
      variant="single-15-pt"
      className={styles.preloader}
      lineHeight={25}
      width={titleWidth}
    />
    <Content>
      <TextPreloader
        variant="single-24-pt"
        width={valueWidth}
        className={styles.preloaderValue}
      />
    </Content>
    <span>{description}</span>
  </Container>
);

const SingletonSkeleton: FC<SingletonSkeletonProps> = ({
  size = SingletonSize.LARGE,
  ...rest
}) => {
  const Component =
    size === SingletonSize.LARGE
      ? LargeSingletonSkeleton
      : SmallSingletonSkeleton;
  return <Component {...rest} />;
};

export default SingletonSkeleton;
