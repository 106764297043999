import React from 'react';
import { Cell, CellProps } from '@piano-reports/ui-kit/src/Table';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import TextPreloader from '@piano-react/components/Preloader/TextPreloader';
import { Segment } from '../../../types';
import { SegmentData } from '../../../api/useSegmentData';
import GaugeChart from '../../GaugeChart';
import styles from './SegmentCell.module.css';

const Content = withClass.div(styles.cell);
const GaugeContainer = withClass.div(styles.gauge);
const StyledTypography = withClass(Typography, styles.title);

export interface SegmentCellProps extends CellProps {
  segment?: Segment;
  segmentData?: SegmentData;
  isLoading?: boolean;
  pageViewPercent?: number;
}

const SegmentCell = ({
  segment,
  segmentData,
  isLoading,
  pageViewPercent,
  ...rest
}: SegmentCellProps) => (
  <Cell {...rest}>
    <Content>
      <GaugeContainer>
        <GaugeChart
          coveragePercent={!isLoading ? segmentData?.data.audienceCoverage : 0}
          pageViewPercent={!isLoading ? pageViewPercent : 0}
          type="small"
        />
      </GaugeContainer>
      {!segment?.name && isLoading ? (
        <TextPreloader
          variant="single-15-pt"
          width={150}
          className={styles.title}
        />
      ) : (
        <StyledTypography variant="single-15-pt">
          {segment?.name}
        </StyledTypography>
      )}
    </Content>
  </Cell>
);

export default SegmentCell;
