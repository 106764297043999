import React from 'react';
import withClass from '@piano-react/components/withClass';
import SegmentTile from '@piano-reports/summary-widgets/src/components/SegmentTile';
import useSortedSegments from '@piano-reports/summary-widgets/src/hooks/useSortedSegments';
import useDateRange from '../../hooks/useDateRange';
import { Report } from '../../interfaces';
import styles from './SegmentGrid.module.css';
import SortingPanel from './SortingPanel';

export const Grid = withClass.div(styles.grid);

const StyledSegmentTile = withClass(SegmentTile, styles.gridItem);

export interface Props {
  report: Report;
}

const SegmentGrid = ({ report }: Props) => {
  const { range } = useDateRange();
  const { segments, sorting, setSorting, setData } = useSortedSegments({
    segments: report.segments,
  });

  return (
    <>
      <SortingPanel
        report={report}
        sorting={sorting}
        onSortChange={setSorting}
      />
      <Grid>
        {segments.map((segment) => (
          <StyledSegmentTile
            key={segment.id}
            range={range}
            segment={segment}
            filterSegmentId={report.filter?.id}
            onDataUpdate={setData}
          />
        ))}
      </Grid>
    </>
  );
};

export default SegmentGrid;
