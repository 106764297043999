import React from 'react';
import { motion } from 'framer-motion';
import Typography from '@piano-dc/react/typography';
import { CellProps } from '@piano-reports/ui-kit/src/Table';
import { defaultTransition } from '@piano-react/components/animateConfig';
import ValueCell from '../ValueCell';
import styles from './PageviewsCell.module.css';

export interface BarProps {
  value: number;
  maxValue: number;
}

const Bar = ({ value, maxValue }: BarProps) => {
  const width = value && maxValue ? `${(value / maxValue) * 100}%` : 0;

  return (
    <motion.div
      className={styles.bar}
      initial={{ width: `0%` }}
      animate={{ width }}
      transition={defaultTransition(0.5)}
    />
  );
};

export interface Props extends CellProps {
  value: number;
  maxValue: number;
}

const PageviewsCell = ({ children, value, maxValue, ...rest }: Props) => (
  <ValueCell {...rest} className={styles.cell} withBorder>
    <Bar value={value} maxValue={maxValue} />
    <span className={styles.text}>
      <Typography variant="single-15-pt">{children}</Typography>
    </span>
  </ValueCell>
);

export default PageviewsCell;
