import { useCallback, useContext } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { Traffic, TrafficFilter } from '@piano-data/cx-client';
import { EVENTS_KEY, UNIQUE_USERS_KEY } from '../consts';
import { DateRange } from '../types';
import CxDataContext from './CxDataContext';
import { getTrafficRequestRange } from './utils';

export interface SegmentTrafficOptions {
  filters: TrafficFilter[];
  range: DateRange;
}

export default function useSegmentTrafficQuery(
  { filters, range }: SegmentTrafficOptions,
  queryConfig: UseQueryOptions<Traffic.Response> = {},
) {
  const { client, scope } = useContext(CxDataContext);

  const request = useCallback(() => {
    return client.traffic.read({
      ...getTrafficRequestRange(range),
      fields: [EVENTS_KEY, UNIQUE_USERS_KEY],
      historyFields: [EVENTS_KEY, UNIQUE_USERS_KEY],
      historyResolution: range.historyResolution,
      filters,
    });
  }, [client, range, filters]);

  return useQuery(
    [
      'segments-total-traffic',
      scope,
      filters,
      range.start,
      range.stop,
      range.offset,
      range.historyResolution,
    ],
    request,
    {
      staleTime: 5 * 60 * 1000,
      suspense: true,
      ...(queryConfig as any),
    },
  );
}
