import React from 'react';
import Gauge from '../../GaugeChart';
import { GaugeContainer } from './index';

const GaugeChartSkeleton = () => {
  return (
    <GaugeContainer>
      <Gauge type="middle" isLoading />
    </GaugeContainer>
  );
};

export default GaugeChartSkeleton;
