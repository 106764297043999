import React from 'react';
import Typography from '@piano-dc/react/typography';
import Icon from '@piano-reports/ui-kit/src/Icon';
import { useTranslation } from 'react-i18next';
import styles from './Title.module.css';

export interface Props {
  suspense?: boolean;
}

const Title: React.FC<Props> = ({ suspense = true }) => {
  const { t } = useTranslation(undefined, { useSuspense: suspense });

  return (
    <div className={styles.title}>
      <Icon name="user-segment" className={styles.icon} />
      <Typography variant="single-13-pt" className={styles.text}>
        {t('translation:appHeader.title', 'Segment summary')}
      </Typography>
    </div>
  );
};

export default Title;
