import format from 'date-fns/format';
import {
  DateRange,
  HistoryResolution,
  Period,
} from '@piano-reports/summary-widgets/src/types';
import { getDatesByPeriod } from '@piano-react/utils/dates/getDatesByPeriod';

const getResolutionByPeriod = (granularity: Period): HistoryResolution => {
  switch (granularity) {
    case Period.LAST_24_HOURS:
      return HistoryResolution.HOUR;
    case Period.LAST_7_DAYS:
    case Period.LAST_30_DAYS:
    default:
      return HistoryResolution.DAY;
  }
};

export const getDateRange = (period: Period): DateRange => {
  const { from: start, to: stop } = getDatesByPeriod(period);
  return {
    start,
    stop,
    historyResolution: getResolutionByPeriod(period),
    period,
  };
};

export const formatRange = (range: DateRange): string => {
  if (range.period) {
    return range.period;
  }

  const { start, stop } = range;

  return start && stop
    ? `${format(start, 'MM/dd/yyyy')} - ${format(stop, 'MM/dd/yyyy')}`
    : '';
};
