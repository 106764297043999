import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import { BrandRelationshipGroup } from '../../SegmentGroupField';
import SegmentsDroplist from '../../SegmentsDroplist/SegmentsDroplist';
import { BRAND_RELATIONSHIP } from '../../../constants';
import { GroupWithKey } from '../index';
export interface Props {
  segments: Segment[];
  className?: string;
  onSegmentAdd(segment: Segment): void;
  onSegmentListAdd(segments: Segment[]): void;
  onSegmentRemove(segment: Segment): void;
  onGroupRemove(group: string): void;
  selectedGroups?: GroupWithKey[];
}

const BrandRelationship = ({
  segments,
  className,
  onSegmentAdd,
  onSegmentRemove,
  onSegmentListAdd,
  onGroupRemove,
  selectedGroups,
}: Props) => {
  const { t } = useTranslation();

  return (
    <SegmentsDroplist
      group={BRAND_RELATIONSHIP}
      position="right top"
      selected={segments}
      onAdd={onSegmentAdd}
      onRemove={onSegmentRemove}
      onAddAll={onSegmentListAdd}
      onRemoveAll={onGroupRemove}
      selectedGroups={selectedGroups}
      trigger={({ opened, triggerProps }) => (
        <BrandRelationshipGroup
          group={BRAND_RELATIONSHIP}
          segments={segments}
          onSegmentDelete={onSegmentRemove}
          className={className}
          active={opened}
          tooltipContent={t('customize.edit', 'Edit')}
          tooltipDisabled={opened}
          {...triggerProps}
        />
      )}
    />
  );
};

export default BrandRelationship;
