import React from 'react';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import { SegmentTag as SegmentTagBase } from '@piano-react/components/FilterTag';
import { useTranslation } from 'react-i18next';
import { DroplistTriggerProps } from '@piano-dc/react/popover/types';
import SegmentTag from './SegmentTag';
import FilterGroup from './FilterGroup';

export interface Props extends DroplistTriggerProps {
  group: string;
  segments: Segment[];
  onSegmentDelete(segment: Segment): void;
  className?: string;
  active?: boolean;
  tooltipContent?: string;
  tooltipDisabled?: boolean;
}

const BrandRelationshipGroup = ({
  group,
  segments,
  onSegmentDelete,
  className,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  return (
    <FilterGroup group={group} className={className} readonly {...rest}>
      {segments && segments.length > 0
        ? segments.map((segment) => (
            <SegmentTag
              key={segment.id}
              segment={segment}
              onCloseClick={onSegmentDelete}
              removeTooltip={t('customize.remove', 'Remove')}
            />
          ))
        : [
            <SegmentTagBase key="allUsers" readonly isStatic animated={false}>
              {t<string>('customize.allUsers', 'All users')}
            </SegmentTagBase>,
          ]}
    </FilterGroup>
  );
};

export default BrandRelationshipGroup;
