import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Menu } from '@piano-dc/react/list';
import Typography from '@piano-dc/react/typography';
import withClass from '@piano-react/components/withClass';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import useSegmentsByGroup from '@piano-reports/summary-widgets/src/api/useSegmentsByGroup';
import styles from './SegmentsList.module.css';

const Container = withClass.div(styles.container);
const Header = withClass.div(styles.header);

export interface Props {
  group: string;
  filter?: string;
  selected: Segment[];
  onAdd(segment: Segment): void;
  onRemove(segment: Segment): void;
  onAddAll(segments: Segment[]): void;
  onRemoveAll(group: string): void;
}

const SegmentsList = ({
  group,
  filter,
  selected,
  onAdd,
  onRemove,
  onAddAll,
  onRemoveAll,
}: Props) => {
  const { t } = useTranslation();
  const segments = useSegmentsByGroup(group);

  const selectedFromGroup = selected.filter(
    (segment) => segment.group === group,
  );

  const filteredSegments = useMemo(() => {
    return segments.filter(
      (segment) =>
        !filter ||
        segment.name.toLowerCase().includes(filter.toLowerCase()) ||
        segment.item?.toLowerCase().includes(filter.toLowerCase()),
    );
  }, [segments, filter]);

  const allSegmentsChecked = selectedFromGroup.length === segments.length;

  return (
    <Container>
      <Header>
        <Row
          checkbox
          checked={allSegmentsChecked}
          indeterminate={
            selectedFromGroup.length > 0 &&
            selectedFromGroup.length < segments.length
          }
          onCheckboxChange={(e) => {
            if (e.detail.checked) {
              onAddAll(segments);
            } else {
              onRemoveAll(group);
            }
          }}
          onClick={() =>
            allSegmentsChecked ? onRemoveAll(group) : onAddAll(segments)
          }
        >
          {t('customize.allSegments', 'All segments')}
        </Row>
      </Header>
      <Menu maxVisibleItems={10} activateType="focus">
        {filteredSegments.length > 0 ? (
          filteredSegments.map((segment) => {
            const checked = !!selected.find((s) => s.id === segment.id);
            return (
              <Row
                key={segment.id}
                checkbox
                checked={checked}
                className={styles.item}
                onCheckboxChange={(e) => {
                  if (e.detail.checked) {
                    onAdd(segment);
                  } else {
                    onRemove(segment);
                  }
                }}
                onClick={() => (checked ? onRemove(segment) : onAdd(segment))}
              >
                {segment.name}
              </Row>
            );
          })
        ) : (
          <Typography className={styles.empty}>
            {t('customize.noResults', `No results for "{{filter}}"`, {
              filter,
            })}
          </Typography>
        )}
      </Menu>
    </Container>
  );
};

export default SegmentsList;
