import React from 'react';
import IconButton from '@piano-dc/react/icon-button';
import Icon from '@piano-reports/ui-kit/src/Icon';
import useSegmentsView from '../../hooks/useSegmentsView';
import { SegmentsView } from '../../interfaces';

const ViewSwitcher: React.FC = () => {
  const { view, setView } = useSegmentsView();

  return (
    <>
      <IconButton
        icon={<Icon name="nav-list" />}
        onClick={() => setView(SegmentsView.TABLE)}
        active={view === SegmentsView.TABLE}
        data-tour="view"
      />
      <IconButton
        icon={<Icon name="nav-grid" />}
        onClick={() => setView(SegmentsView.GRID)}
        active={view === SegmentsView.GRID}
      />
    </>
  );
};

export default ViewSwitcher;
