import React from 'react';
import withClass from '@piano-react/components/withClass';
import { SingletonSize } from '../../Singleton';
import SingletonSkeleton from '../../Singleton/SingletonSkeleton';
import { Container } from './index';
import styles from './Singletons.module.css';

const StyledSingletonSkeleton = withClass(
  SingletonSkeleton,
  styles.singleton,
);

const SingletonsSkeleton = () => (
  <Container>
    <StyledSingletonSkeleton size={SingletonSize.SMALL} />
    <StyledSingletonSkeleton size={SingletonSize.SMALL} />
    <StyledSingletonSkeleton size={SingletonSize.SMALL} />
    <StyledSingletonSkeleton size={SingletonSize.SMALL} />
  </Container>
);

export default SingletonsSkeleton;
