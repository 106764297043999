import React from 'react';

const AxisSkeleton = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 100 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <rect y="0" width="100" height="1" fill="#141B24" fillOpacity="0.05" />
    <rect y="40" width="100" height="1" fill="#141B24" fillOpacity="0.05" />
    <rect y="80" width="100" height="1" fill="#141B24" fillOpacity="0.05" />
    <rect y="120" width="100" height="1" fill="#141B24" fillOpacity="0.05" />
    <rect y="160" width="100" height="1" fill="#141B24" fillOpacity="0.05" />
    <rect y="199" width="100" height="1" fill="#141B24" fillOpacity="0.10" />
  </svg>
);

export default AxisSkeleton;
