import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import useMeasure from '@piano-react/hooks/useMeasure';
import StickyHeaderStackContext from './StickyHeaderStackContext';
import styles from './StickyNode.module.css';

export interface Props {
  children: React.ReactNode;
  className?: string;
}

const StickyHeaderStackProvider = ({ children, className }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [bind, { width }] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    setNode(ref.current);
  }, [setNode, ref]);

  return (
    <StickyHeaderStackContext.Provider value={{ node }}>
      {children}
      <div
        className={cn(styles.sticky, className)}
        style={{ maxWidth: width }}
        ref={ref}
      />
      <div {...bind} />
    </StickyHeaderStackContext.Provider>
  );
};

export default StickyHeaderStackProvider;
