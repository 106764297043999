import { useMemo } from 'react';
import useSegments from '@piano-reports/summary-widgets/src/api/useSegments';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import { commonPresets, basePresets, vxPresets } from '../presets';
import { Report } from '../interfaces';
import usePresetsStore from './usePresetsStore';

const SUBSCRIBERS = 'Subscribers';

export default function usePresets() {
  const segments = useSegments({});
  const { savedPresets } = usePresetsStore();

  return useMemo<Report[]>(() => {
    const isVxApp = !!segments?.find((segment) => segment.item === SUBSCRIBERS);
    return [
      ...(isVxApp ? vxPresets : basePresets),
      ...commonPresets,
      ...savedPresets,
    ].map((preset) => ({
      name: preset.name,
      date: preset.date,
      description: preset.description,
      segments: preset.items
        .map((item) =>
          segments?.find(
            (segment) => segment.item === item || segment.id === item,
          ),
        )
        .filter(Boolean) as Segment[],
      filter: null,
    }));
  }, [segments, savedPresets]);
}
