import React from 'react';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import { HeaderCell, HeaderCellProps } from '@piano-reports/ui-kit/src/Table';
import { Segment } from '../../../types';
import styles from './SegmentsHeader.module.css';

const StyledTypography = withClass(Typography, styles.segments);

export interface Props extends HeaderCellProps {
  segments: Segment[];
}

const SegmentsHeader = ({ segments, ...rest }: Props) => (
  <HeaderCell {...rest} className={styles.cell} sticky>
    <StyledTypography>{segments.length} segments</StyledTypography>
  </HeaderCell>
);

export default SegmentsHeader;
