import React from 'react';
import SegmentTable from '@piano-reports/summary-widgets/src/components/SegmentTable';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import useDateRange from '../../hooks/useDateRange';

export interface Props {
  segments: Segment[];
  filterSegment?: Segment | null;
}

const SegmentTableContainer = ({ segments, filterSegment }: Props) => {
  const { range } = useDateRange();

  return (
    <SegmentTable
      range={range}
      segments={segments}
      filterSegmentId={filterSegment?.id}
      headerTop={99}
    />
  );
};

export default SegmentTableContainer;
