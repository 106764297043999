import React from 'react';
import SegmentDataSkeleton from '../SegmentData/SegmentDataSkeleton';
import SegmentDescriptionSkeleton from '../SegmentDescription/SegmentDescriptionSkeleton';
import GaugeChartSkeleton from '../GaugeChart/GaugeChartSkeleton';
import { SegmentHeaderWrapper } from '../SegmentHeader';
import { Content } from './index';

const SegmentContentSkeleton = () => (
  <>
    <SegmentHeaderWrapper>
      <GaugeChartSkeleton />
      <SegmentDescriptionSkeleton />
    </SegmentHeaderWrapper>
    <Content>
      <SegmentDataSkeleton />
    </Content>
  </>
);

export default SegmentContentSkeleton;
