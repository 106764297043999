import { Segment } from '@piano-reports/summary-widgets/src/types';

export interface Preset {
  name: string;
  description: string;
  date?: Date;
  items: string[];
}

export interface Report {
  name: string;
  description?: string;
  date?: Date;
  filter: Segment | null;
  segments: Segment[];
}

export enum SegmentsView {
  GRID = 'grid',
  TABLE = 'table',
}

export enum SidebarMode {
  LIST = 'LIST',
  CUSTOMIZE = 'CUSTOMIZE',
  PRESETS = 'PRESETS',
}
