import React, { memo, Suspense } from 'react';
import withClass from '@piano-react/components/withClass';
import { DateRange, Segment } from '../../types';
import ErrorBoundary from '../ErrorBoundary';
import SegmentReportError from '../SegmentReportError';
import styles from './SegmentTile.module.css';
import SegmentContent from './SegmentContent';
import SegmentContentSkeleton from './SegmentContent/SegmentContentSkeleton';

export const Container = withClass.div(styles.container);

export interface SegmentSummaryProps {
  segmentId?: string;
  segment?: Segment;
  filterSegmentId?: string;
  className?: string;
  range: DateRange;
  onDataUpdate?(key: string, values: Record<string, number | undefined>): void;
}

const SegmentTile = memo(({ className, ...rest }: SegmentSummaryProps) => (
  <Container className={className}>
    <ErrorBoundary FallbackComponent={SegmentReportError}>
      <Suspense fallback={<SegmentContentSkeleton />}>
        <SegmentContent {...rest} />
      </Suspense>
    </ErrorBoundary>
  </Container>
));

export default SegmentTile;
