import React, { useMemo } from 'react';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import ChartComponent from '@piano-insight/charts/components/Chart';
import IndexAxis from '@piano-insight/charts/components/IndexAxis';
import Bar from '@piano-insight/charts/components/Bar';
import Line from '@piano-insight/charts/components/Line';
import withClass from '@piano-react/components/withClass';
import formatPercent from '@piano-react/utils/numberFormat/formatPercent';
import formatInteger from '@piano-react/utils/numberFormat/formatInteger';
import getDomain from '@piano-insight/charts/utils/getDomain';
import LastValueLabels from '@piano-insight/charts/components/ValueLabels';
import BottomLabel from '@piano-insight/charts/components/BottomLabel';
import StaticBar from '@piano-insight/charts/components/Bar/StaticBar';
import { SegmentData } from '../../../api/useSegmentData';
import useSegmentChartData from '../../../hooks/useSegmentChartData';
import { DateRange, Period } from '../../../types';
import { BAR_COLOR, DATA_1, DATA_2, LINE_COLOR } from '../../../colors';
import { AUDIENCE_COVERAGE_KEY, EVENTS_KEY } from '../../../consts';
import styles from '../SegmentTile.module.css';
import ValueLabel from './ValueLabel';
import EmptyAxis from './EmptyAxis';

export const ChartContainer = withClass.div(styles.chartContainer);
export const VERTICAL_PADDING = 4;
export const BOTTOM_LABEL_OFFSET = 0;
export const BAR_PADDING_RATIO = 0.2;

const LINE_KEYS = [EVENTS_KEY];
const BAR_KEYS = [AUDIENCE_COVERAGE_KEY];
const VALUE_KEYS = [EVENTS_KEY, AUDIENCE_COVERAGE_KEY];

export const CHART_PADDING = { top: 5, left: 0, bottom: 20, right: 50 };

const getLineColor = () => LINE_COLOR;
const getBarColor = () => BAR_COLOR;

const getValueLabelColor = (key: string) => {
  if (key === EVENTS_KEY) {
    return DATA_1;
  }
  return DATA_2;
};

const getBottomLabelText = (range: DateRange) => {
  switch (range.period) {
    case Period.LAST_7_DAYS:
      return 'Last 7 days';
    case Period.LAST_30_DAYS:
      return 'Last 30 days';
    case Period.LAST_24_HOURS:
      return 'Last day';
    default: {
      return range.start && range.stop
        ? `${format(range.start, 'MM/dd/yyyy')} - ${format(
            range.stop,
            'MM/dd/yyyy',
          )}`
        : '';
    }
  }
};

const valueLabelFormat = (value: string | number, key?: string) => {
  if (key === AUDIENCE_COVERAGE_KEY) {
    return formatPercent(+value);
  }
  return formatInteger(+value);
};

export interface Props {
  data: SegmentData;
  range: DateRange;
}

const Chart = React.memo(({ data, range }: Props) => {
  const { t } = useTranslation();

  const isAllZero =
    Object.values(data.data).every((item) => item === 0) || !data.data.events;

  const chartData = useSegmentChartData(data);

  const [lineDomain, barDomain] = useMemo(
    () => [getDomain(chartData, LINE_KEYS), getDomain(chartData, BAR_KEYS)],
    [chartData],
  );

  return (
    <ChartContainer>
      <ChartComponent padding={CHART_PADDING} data={chartData} indexKey="index">
        {!isAllZero ? (
          <>
            <Bar
              getColor={getBarColor}
              domain={barDomain}
              keys={BAR_KEYS}
              barPaddingRatio={BAR_PADDING_RATIO}
              BarComponent={StaticBar}
            />
            <Line
              getColor={getLineColor}
              domain={lineDomain}
              keys={LINE_KEYS}
            />
            <LastValueLabels
              keys={VALUE_KEYS}
              valueFormat={valueLabelFormat}
              getColor={getValueLabelColor}
              verticalPadding={VERTICAL_PADDING}
              LabelComponent={ValueLabel}
              sortType="value"
              animate
            />
          </>
        ) : (
          <IndexAxis
            AxisComponent={EmptyAxis}
            position="bottom"
            tickFormat={() => ''}
          />
        )}

        <BottomLabel offsetBottom={BOTTOM_LABEL_OFFSET}>
          {!isAllZero
            ? getBottomLabelText(range)
            : `${t(
                `emptyReport.noUsers`,
                'No users in the',
              )} ${getBottomLabelText(range)}`}
        </BottomLabel>
      </ChartComponent>
    </ChartContainer>
  );
});

export default Chart;
