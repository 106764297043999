import groupBy from 'lodash/groupBy';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import {
  BRAND_RELATIONSHIP,
  CHECKOUT_ABANDONMENT,
  EXPIRING_CREDIT_CARDS,
  EXPIRING_SUBSCRIBERS,
  SUBSCRIPTION_LENGTH,
  SUBSCRIPTION_TENURE,
  USER_FREQUENCY,
  USER_INTEREST,
  USER_RECENCY,
} from '../constants';

export interface StatementItem {
  caption: string;
}

export type Statement = Array<StatementItem | string>;

export const replaceComma = (str: string) => str.trimEnd().replace(/,$/, '.');
export const testComma = (str: string) => new RegExp(/,$/).test(str.trimEnd());

export const replaceLastComma = (statements: Statement[]) => {
  const lastItem = statements[statements.length - 1];
  if (Array.isArray(lastItem)) {
    const lastElement = lastItem[lastItem.length - 1];
    if (typeof lastElement === 'string' && testComma(lastElement)) {
      lastItem[lastItem.length - 1] = replaceComma(lastElement);
    }
    if (typeof lastElement === 'object' && testComma(lastElement.caption)) {
      lastItem[lastItem.length - 1] = {
        caption: replaceComma(lastElement.caption),
      };
    }
  }

  return statements;
};

export const getSegmentCaption = (segment: Segment) =>
  (segment.item ?? segment.name).toLowerCase();

export const formatBrandRelationship = (segments: Segment[]): Statement => {
  if (segments.length === 0) {
    return [{ caption: 'All users' }];
  }

  return segments
    .map((segment) => ({
      caption: getSegmentCaption(segment),
    }))
    .reduce(
      (acc, item, index) => (index === 0 ? [item] : [...acc, ' or ', item]),
      [] as Statement,
    );
};

export const getCaptionWithItemEnding = (segment: Segment, itemEnding?: string) =>
  itemEnding
    ? `${getSegmentCaption(segment)} ${itemEnding}`
    : getSegmentCaption(segment);

export const formatSegmentGroup = (
  verb: string,
  itemEnding?: string,
  statementEnding?: string,
) => (segments: Segment[]): Statement | null => {
  return verb && verb.length > 0
    ? [
        `who ${verb} `,
        ...segments
          .map((segment) => ({
            caption: getCaptionWithItemEnding(segment, itemEnding),
          }))
          .reduce(
            (acc, item, index) =>
              index === 0 ? [item] : [...acc, ' or ', item],
            [] as Statement,
          ),
        statementEnding ? ` ${statementEnding}, ` : ', ',
      ]
    : null;
};

export const formatGroup = (group: string, segments: Segment[]) => {
  switch (group) {
    case USER_FREQUENCY:
      return formatSegmentGroup('had')(segments);
    case USER_RECENCY:
      return formatSegmentGroup('were')(segments);
    case EXPIRING_SUBSCRIBERS:
      return ['who ', { caption: 'disabled autorenew' }, ', '];
    case SUBSCRIPTION_LENGTH:
      return formatSegmentGroup('are', '', '')(segments);
    case USER_INTEREST:
      return formatSegmentGroup('are', '', 'fans')(segments);
    case EXPIRING_CREDIT_CARDS:
      return formatSegmentGroup('have a credit card expiring in')(segments);
    case SUBSCRIPTION_TENURE:
      return formatSegmentGroup('are')(segments);
    case CHECKOUT_ABANDONMENT:
      return formatSegmentGroup('abandoned checkout in the')(segments);
    default:
      return formatSegmentGroup('are')(segments);
  }
};

export const insertUnion = (acc: Statement[], item: Statement, index: number) =>
  index === 0 ? [item] : [...acc, [' and ', ...item]];

export function formatSegments(segments: Segment[]): Statement[] {
  const brandRelationship = segments.filter(
    (segment) => segment.group === BRAND_RELATIONSHIP,
  );
  const otherSegments = segments.filter(
    (segment) => segment.group !== BRAND_RELATIONSHIP,
  );

  const groupedSegments = groupBy(otherSegments, 'group');

  return replaceLastComma([
    [...formatBrandRelationship(brandRelationship), ' '],
    ...([
      ...Object.entries(groupedSegments).map(([group, groupSegments]) =>
        formatGroup(group, groupSegments),
      ),
    ] as Statement[]).reduce(insertUnion, [] as Statement[]),
  ]);
}
