import React from 'react';
import Icon from '@piano-reports/ui-kit/src/Icon';
import Typography from '@piano-dc/react/typography';
import { DroplistTriggerProps } from '@piano-dc/react/popover/types';
import styles from './SidebarSelector.module.css';

export interface Props extends DroplistTriggerProps {
  children?: React.ReactNode;
}

const SidebarSelector: React.FC<Props> = ({ children, ...rest }) => (
  <div className={styles.selector} {...rest}>
    <Typography variant="single-18-pt" className={styles.caption}>
      {children}
    </Typography>
    <Icon name="chevron-down" data-tour="datepicker" />
  </div>
);

export default SidebarSelector;
