import { Preset } from '../interfaces';

export { vxPresets } from './vxPresets';
export { basePresets } from './basePresets';
export const commonPresets: Preset[] = [
  {
    name: 'All users',
    description: '',
    items: [],
  },
];
