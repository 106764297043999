import React, { memo, Suspense } from 'react';
import withClass from '@piano-react/components/withClass';
import useSegment from '../../../api/useSegment';
import { DateRange, Segment } from '../../../types';
import SegmentDataSkeleton from '../SegmentData/SegmentDataSkeleton';
import SegmentData from '../SegmentData';
import SegmentHeader from '../SegmentHeader';
import styles from './SegmentContent.module.css';

export const Content = withClass.div(styles.content);

export interface SegmentSummaryProps {
  segmentId?: string;
  segment?: Segment;
  filterSegmentId?: string;
  range: DateRange;
  onDataUpdate?(key: string, values: Record<string, number | undefined>): void;
}

const SegmentContent = memo(
  ({
    segmentId,
    segment: requestedSegment,
    filterSegmentId,
    range,
    onDataUpdate,
  }: SegmentSummaryProps) => {
    const segment = useSegment({ segment: requestedSegment, segmentId });

    return segment ? (
      <>
        <SegmentHeader
          segment={segment}
          filterSegmentId={filterSegmentId}
          range={range}
        />

        <Content>
          <Suspense fallback={<SegmentDataSkeleton />}>
            <SegmentData
              segment={segment}
              range={range}
              filterSegmentId={filterSegmentId}
              onDataUpdate={onDataUpdate}
            />
          </Suspense>
        </Content>
      </>
    ) : null;
  },
);

export default SegmentContent;
