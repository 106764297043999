import React from 'react';
import withClass from '@piano-react/components/withClass';
import { SegmentData } from '../../../api/useSegmentData';
import usePageviewPercent from '../../../hooks/usePageviewPercent';
import Gauge from '../../GaugeChart';
import { DateRange } from '../../../types';
import styles from './GaugeChart.module.css';

export const Container = withClass.div(styles.container);

export interface Props {
  data: SegmentData;
  range: DateRange;
}

const GaugeChart = ({ data, range }: Props) => {
  const percent = usePageviewPercent(data, range);

  return (
    <Container>
      <Gauge
        type="large"
        pageViewPercent={percent}
        coveragePercent={data.data.audienceCoverage}
      />
    </Container>
  );
};

export default GaugeChart;
