import React from 'react';

export interface Props {
  children: React.ReactNode;
  width?: number;
  height?: number;
  className?: string;
}

const Stuck = ({ children, width, height, className }: Props) => (
  <div style={{ width, height }} className={className}>
    {children}
  </div>
);

export default Stuck;
