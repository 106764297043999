import { createContext, Dispatch, SetStateAction } from 'react';
import { DateRange } from '@piano-reports/summary-widgets/src/types';
import { Sorting } from '@piano-reports/summary-widgets/src/hooks/useSortedSegments';
import { Report, SegmentsView, SidebarMode } from '../../interfaces';

export interface GlobalSettings {
  report: Report | null;
  range: DateRange;
  view: SegmentsView;
  sorting?: Sorting;
}

export interface GlobalSettingsContextType extends GlobalSettings {
  setReport: Dispatch<SetStateAction<Report>>;
  setRange(range: DateRange): void;
  setView(view: SegmentsView): void;
  setSorting(sorting?: Sorting): void;
  sidebarMode: SidebarMode;
  setSidebarMode(mode: SidebarMode): void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const GlobalSettingsContext = createContext<GlobalSettingsContextType>({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  report: {},
  range: { offset: '-30d' },
  setReport: noop,
  setRange: noop,
  view: SegmentsView.GRID,
  setView: noop,
  setSorting: noop,
  sidebarMode: SidebarMode.LIST,
  setSidebarMode: noop,
});

export default GlobalSettingsContext;
