import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import format from 'date-fns/format';
import { filterLikelihoodToCancel } from '@piano-reports/summary-widgets/src/utils/filterLikelihoodToCancel';
import { Preset, Report } from '../interfaces';
import { basePresets, commonPresets, vxPresets } from '../presets';

export const isPresetsEqual = (first: Preset, second: Preset) =>
  isEqual(
    omit(
      {
        ...first,
        items: [...first.items].sort(),
      },
      ['date', 'name'],
    ),
    omit(
      {
        ...second,
        items: [...second.items].sort(),
      },
      ['date', 'name'],
    ),
  );

export const getReportDescription = (report: Report) =>
  report.description ?? '';

export const convertToPreset = (report: Report): Preset => ({
  name: report.name,
  date: new Date(),
  description: getReportDescription(report),
  items: filterLikelihoodToCancel(report.segments).map(
    (segment) => segment.item ?? segment.id,
  ),
});

export const shiftPreset = (preset: Preset, list: Preset[]) => [
  preset,
  ...list.filter((item) => !isPresetsEqual(item, preset)),
];

const allDefaultPresets = [...vxPresets, ...basePresets, ...commonPresets];

export const isDefaultPreset = (preset: Preset) =>
  !!allDefaultPresets.find((item) => isPresetsEqual(item, preset));

export const formatPresetTime = (date: Date, language?: string) => {
  switch (language) {
    case 'en':
      return format(new Date(date), 'LLL dd, yyyy - hh:mm:ss aa');
    default:
      return format(new Date(date), 'yyyy-MM-dd — HH:mm:ss');
  }
};

export const getPresetCardTitle = (preset: Report, language?: string) =>
  preset?.date ? formatPresetTime(preset.date, language) : preset.name;

export const isPresetSelected = (first: Report, second: Report | null) =>
  isPresetsEqual(convertToPreset(first), convertToPreset(second as Report));

export const getLangPart = (locale: string) => locale.split('-')[0];
