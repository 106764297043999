import { useMemo } from 'react';
import { UseQueryOptions, QueryStatus } from 'react-query';
import {
  BooleanFilter,
  CxRequestError,
  FilterField,
  SegmentFilter,
  Traffic,
  TrafficFilter,
} from '@piano-data/cx-client';
import { DateRange } from '../types';
import useSegmentTrafficQuery from './useSegmentTrafficQuery';
import useDataWithCalculatedMetrics from './useDataWithCalculatedMetrics';

export interface SegmentDataOptions {
  segmentId?: string;
  subSegmentIds?: string[];
  filterSegmentId?: string;
  range: DateRange;
}

const buildFilters = (
  segmentId?: string,
  subSegmentIds?: string[],
  filterSegmentId?: string,
): TrafficFilter[] => {
  if (!segmentId && subSegmentIds) {
    throw new Error('Cannot set sub segments if base segment is not defined');
  }

  let baseFilter: TrafficFilter;
  if (subSegmentIds) {
    baseFilter = {
      type: 'and',
      filters: [
        { type: 'segment', item: segmentId },
        { type: 'segment', items: subSegmentIds },
      ],
    } as BooleanFilter;
  } else {
    baseFilter = { type: 'segment', item: segmentId } as SegmentFilter;
  }

  if (filterSegmentId) {
    return [
      {
        type: 'and',
        filters: [baseFilter, { type: 'segment', item: filterSegmentId }],
      } as BooleanFilter,
    ];
  }

  return [baseFilter];
};

export type SegmentDataFields =
  | FilterField
  | 'audienceCoverage'
  | 'pageViewsPerUser';

export interface SegmentData {
  start: Date;
  stop: Date;
  history: Date[];
  data: Record<SegmentDataFields, number | undefined>;
  historyData: Record<SegmentDataFields, number[] | undefined>;
}

export interface SegmentDataResult {
  data?: SegmentData;
  status: QueryStatus;
  isFetching?: boolean;
  error?: CxRequestError;
}

export default function useSegmentData(
  { segmentId, subSegmentIds, filterSegmentId, ...options }: SegmentDataOptions,
  queryConfig?: UseQueryOptions<Traffic.Response>,
): SegmentDataResult {
  const filters = useMemo(
    () => buildFilters(segmentId, subSegmentIds, filterSegmentId),
    [segmentId, subSegmentIds, filterSegmentId],
  );

  const segmentTrafficQueryResult = useSegmentTrafficQuery(
    {
      ...options,
      filters,
    },
    queryConfig,
  );

  return useDataWithCalculatedMetrics(
    segmentTrafficQueryResult,
    options,
    queryConfig,
  );
}
