import { useCallback, useContext } from 'react';
import CxDataContext from '@piano-reports/summary-widgets/src/api/CxDataContext';
// import env from '../../utils/env';
import { getTourStateLocalStorageKey } from '../../utils/settings';

export default function useTourState(
  userId: string,
): [() => Promise<boolean>, () => Promise<void>] {
  const { scope } = useContext(CxDataContext);

  const getTour = useCallback<() => Promise<boolean>>(
    () =>
      new Promise<boolean>((resolve) => {
        // client
        //   .get<boolean>(
        //     `${env.REACT_APP_ONBOARDING_TOUR_API}/segment-summary?${scopeType}=${scope}`,
        //     {},
        //   )
        //   .then((result) => resolve(result.data || false))
        //   .catch(() => {
        //     console.error('Cannot get tour status via http');
        //
        //     // local storage fallback
        //     const state = window.localStorage.getItem(
        //       getTourStateLocalStorageKey(userId),
        //     );
        //
        //     resolve(!!state && (JSON.parse(state) as boolean));
        //   });
        const state = window.localStorage.getItem(
          getTourStateLocalStorageKey(userId, scope),
        );

        resolve(!!state && (JSON.parse(state) as boolean));
      }),
    [scope, userId],
  );

  const setTour = useCallback<() => Promise<void>>(
    () =>
      new Promise<void>((resolve) => {
        // client
        //   .post<string>(
        //     `${env.REACT_APP_ONBOARDING_TOUR_API}/?${scopeType}=${scope}`,
        //     'segment-summary',
        //     {},
        //   )
        //   .then((result) => resolve())
        //   .catch(() => {
        //     console.error('Cannot set tour status via http');
        //
        //     // local storage fallback
        //     window.localStorage.setItem(
        //       getTourStateLocalStorageKey(userId),
        //       JSON.stringify(true),
        //     );
        //
        //     resolve();
        //   });

        window.localStorage.setItem(
          getTourStateLocalStorageKey(userId, scope),
          JSON.stringify(true),
        );

        resolve();
      }),
    [scope, userId],
  );

  return [getTour, setTour];
}
