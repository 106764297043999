import React from 'react';
import DateRangePickerSkeleton from '../DateRangePicker/DateRangePickerSkeleton';
import ReportDescriptionSkeleton from '../ReportDescription/ReportDescriptionSkeleton';
import { Container, Top, Content } from './index';

const SidebarSkeleton: React.FC = () => (
    <Container>
      <Top>
        <DateRangePickerSkeleton />
      </Top>
      <Content>
        <ReportDescriptionSkeleton />
      </Content>
    </Container>
  );

export default SidebarSkeleton;
