import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import useSegments from '@piano-reports/summary-widgets/src/api/useSegments';
import { Period, Segment } from '@piano-reports/summary-widgets/src/types';
import { GlobalSettings } from '../components/GlobalSettings/GlobalSettingsContext';
import { getLocalStorageSettingsKey } from '../utils/settings';
import { Report, SegmentsView } from '../interfaces';
import { getDateRange } from '../utils/datepicker';
import { isTargetMode } from '../utils/vxMessages';
import useDefaultReport from './useDefaultReport';

const defaultSettings: GlobalSettings = {
  report: null,
  range: getDateRange(Period.LAST_30_DAYS),
  view: SegmentsView.GRID,
  sorting: undefined,
};

export default function useDefaultSettings(
  userId: string,
  scope: string,
): GlobalSettings {
  const segments = useSegments({});
  const { t } = useTranslation();

  const localStorageSettings = useMemo<GlobalSettings | null>(() => {
    const settings = window.localStorage.getItem(
      getLocalStorageSettingsKey(userId, scope),
    );

    try {
      const currentSettings = settings
        ? (JSON.parse(settings, (key, value) => {
            if (key === 'start' || key === 'stop') {
              return new Date(value);
            }
            return value;
          }) as GlobalSettings)
        : null;

      return currentSettings && currentSettings.range.period
        ? {
            ...currentSettings,
            range: {
              ...currentSettings,
              ...getDateRange(currentSettings.range.period),
            },
          }
        : currentSettings;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [userId, scope]);

  const urlReport = useMemo<Report | null>(() => {
    if (segments) {
      const urlParams = new URLSearchParams(window.location.search);

      const segmentIds = urlParams.get('segmentIds');

      if (segmentIds || isTargetMode()) {
        const ids = (segmentIds ?? '').split(',');

        return {
          name: t('sidebar.customCriteria', 'Custom criteria'),
          description: '',
          segments: uniqBy(
            ids
              .map((id) => segments.find((segment) => segment.id === id))
              .filter(Boolean) as Segment[],
            (segment) => segment.id,
          ),
        } as Report;
      }
    }

    return null;
  }, [segments, t]);

  const defaultReport = useDefaultReport();

  const baseSettings = localStorageSettings || {
    ...defaultSettings,
    report: defaultReport,
  };

  if (urlReport) {
    // report from URL has the highest priority
    return {
      ...baseSettings,
      report: urlReport,
    };
  }

  return baseSettings;
}
