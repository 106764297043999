import { useCallback } from 'react';
import uniqBy from 'lodash/uniqBy';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import { useTranslation } from 'react-i18next';
import { Report } from '../interfaces';
import useReport from './useReport';

export default function useReportCustomize() {
  const { t } = useTranslation();
  const { report, setReport } = useReport();

  const addSegments = useCallback(
    (segments: Segment[]) => {
      setReport((currentReport: Report) => ({
        ...currentReport,
        name: t('sidebar.customCriteria', 'Custom criteria'),
        description: '',
        segments: uniqBy(
          [...currentReport.segments, ...segments],
          (segment) => segment.id,
        ),
      }));
    },
    [setReport, t],
  );

  const addSegment = useCallback(
    (segment: Segment) => {
      addSegments([segment]);
    },
    [addSegments],
  );

  const removeSegment = useCallback(
    (segment: Segment) => {
      setReport((currentReport: Report) => ({
        ...currentReport,
        name: t('sidebar.customCriteria', 'Custom criteria'),
        description: '',
        segments: currentReport.segments.filter((s) => s.id !== segment.id),
      }));
    },
    [setReport, t],
  );

  const removeSegmentGroup = useCallback(
    (group: string) => {
      setReport((currentReport: Report) => ({
        ...currentReport,
        name: t('sidebar.customCriteria', 'Custom criteria'),
        description: '',
        segments: currentReport.segments.filter((s) => s.group !== group),
      }));
    },
    [setReport, t],
  );

  return { report, addSegment, addSegments, removeSegment, removeSegmentGroup };
}
