import React from 'react';
import { Tooltip } from '@piano-dc/react/tooltip';
import styles from './FilterList.module.css';
import FilterListContent from './FilterList';

export interface Props {
  delimiterText: string;
  children: React.ReactNodeArray | null;
  tooltipContent?: string;
  tooltipDisabled?: boolean;
  emptyText?: string;
}

const FilterList = ({ tooltipContent, tooltipDisabled, ...rest }: Props) => (
  <Tooltip
    type="small"
    content={tooltipContent}
    disabled={!tooltipContent || tooltipDisabled}
    positionMode="mouse"
    wrapperClassName={styles.container}
  >
    <FilterListContent {...rest} />
  </Tooltip>
);

export default FilterList;
