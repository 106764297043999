import React from 'react';
import { Cell, CellProps } from '@piano-reports/ui-kit/src/Table';
import Typography from '@piano-dc/react/typography';
import withClass from '@piano-react/components/withClass';
import styles from './ValueCell.module.css';

const StyledTypography = withClass(Typography, styles.cell);

export interface Props extends CellProps {}

const ValueCell = ({ children, ...rest }: Props) => (
  <Cell {...rest}>
    <StyledTypography variant="single-15-pt">{children}</StyledTypography>
  </Cell>
);

export default ValueCell;
