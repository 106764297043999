import React, { useState } from 'react';
import withClass from '@piano-react/components/withClass';
import { Collapse } from '@piano-react/components/Collapse';
import SegmentSummary from '@piano-reports/summary-widgets/src/components/SegmentSummary';
import { Report } from '../../interfaces';
import useDateRange from '../../hooks/useDateRange';
import TitlePanel from './TitlePanel';
import styles from './SegmentSummaryTile.module.css';

export const Container = withClass.div(styles.container);

export interface Props {
  report: Report;
}

const SegmentSummaryTile = React.memo(({ report }: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const { range } = useDateRange();

  return (
    <Container>
      <TitlePanel
        title={report.name}
        onToggleClick={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
      />
      <Collapse isOpen={!collapsed}>
        <SegmentSummary
          range={range}
          segments={report.segments}
          filterSegmentId={report.filter?.id}
        />
      </Collapse>
    </Container>
  );
});

export default SegmentSummaryTile;
