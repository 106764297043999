import React from 'react';
import SegmentTileSkeleton from '@piano-reports/summary-widgets/src/components/SegmentTile/SegmentTileSkeleton';
import SortingPanelSkeleton from './SortingPanel/SortingPanelSkeleton';
import { Grid } from './index';
import styles from './SegmentGrid.module.css';

const SegmentGridSkeleton = () => (
  <>
    <SortingPanelSkeleton />
    <Grid>
      <SegmentTileSkeleton className={styles.gridItem} />
      <SegmentTileSkeleton className={styles.gridItem} />
      <SegmentTileSkeleton className={styles.gridItem} />
      <SegmentTileSkeleton className={styles.gridItem} />
    </Grid>
  </>
);

export default SegmentGridSkeleton;
