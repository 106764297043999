import React from 'react';
import { PageHeader, PageHeaderPanel } from '@piano-dc/react/page-header';
import Title from './Title';
import { HeaderContainer, Spacer } from './index';
import CloseButton from './CloseButton';
import styles from './ErrorHeader.module.css';

const ErrorHeader: React.FC = () => (
  <>
    <Spacer />
    <HeaderContainer className={styles.error}>
      <PageHeader>
        <PageHeaderPanel align="left">
          <Title suspense={false} />
        </PageHeaderPanel>
        <PageHeaderPanel align="right">
          <CloseButton />
        </PageHeaderPanel>
      </PageHeader>
    </HeaderContainer>
  </>
);

export default ErrorHeader;
