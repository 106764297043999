import { useMemo } from 'react';
import { filterLikelihoodToCancel } from '../utils/filterLikelihoodToCancel';
import { Segment } from '../types';
import useC1XSegmentsQuery from './useC1XSegmentsQuery';

export interface SegmentOptions {
  segments?: Segment[];
  segmentIds?: string[];
}

export default function useSegment({ segments, segmentIds }: SegmentOptions) {
  const { data } = useC1XSegmentsQuery();

  return useMemo(() => {
    if (segments) {
      return filterLikelihoodToCancel(segments);
    }

    return segmentIds
      ? filterLikelihoodToCancel(segments).filter((s) =>
          segmentIds?.includes(s.id),
        )
      : filterLikelihoodToCancel(data?.segments as Segment[]);
  }, [data, segments, segmentIds]);
}
