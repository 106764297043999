import React, { memo, useCallback, useMemo } from 'react';
import ChartComponent from '@piano-insight/charts/components/Chart';
import Bar from '@piano-insight/charts/components/Bar';
import Line from '@piano-insight/charts/components/Line';
import withClass from '@piano-react/components/withClass';
import { ValueAxisProps } from '@piano-insight/charts/components/ValueAxis/AxisComponent';
import ValueAxis from '@piano-insight/charts/components/ValueAxis';
import TwoValueAxis from '@piano-insight/charts/components/ValueAxis/TwoValueAxis';
import IndexAxis from '@piano-insight/charts/components/IndexAxis';
import getDomain from '@piano-insight/charts/scale/getDomain';
import AxisWithGaps from '@piano-insight/charts/components/IndexAxis/AxisWithGaps';
import { IndexAxisProps } from '@piano-insight/charts/components/IndexAxis/AxisComponent';
import { Tick } from '@piano-insight/charts/components/ValueAxis/utils';
import DynamicTooltipGridItem from '@piano-insight/charts/components/Grid/DynamicTooltipGridItem';
import Grid, {
  GridComponentProps,
} from '@piano-insight/charts/components/Grid';
import niceDomain from '@piano-insight/charts/scale/niceDomain';
import {
  ColorsAttributeDividerDark,
  ColorsAttributeDividerLight,
  ColorsTypeMain,
} from '@piano-dc/atoms/colors/colors';
import { SegmentData } from '../../../api/useSegmentData';
import useSegmentChartData from '../../../hooks/useSegmentChartData';
import { DateRange } from '../../../types';
import { BAR_COLOR, LINE_COLOR } from '../../../colors';
import { AUDIENCE_COVERAGE_KEY, EVENTS_KEY } from '../../../consts';
import StyledBar from '../../StyledBar';
import ChartTooltip from '../ChartTooltip';
import { tooltipTimeFormat } from '../ChartTooltip/utils/timeFormat';
import { fitDomains } from './utils/domain';
import {
  indexTickFormat,
  leftValueTickFormat,
  rightValueTickFormat,
} from './utils/format';
import styles from './Chart.module.css';

export const ChartContainer = withClass.div(styles.chartContainer);
export const BAR_PADDING_RATIO = 0.2;
export const VALUE_TICK_PADDING = 30;
export const CHART_PADDING = { top: 15, left: 100, bottom: 90, right: 100 };
const LINE_KEYS = [EVENTS_KEY];
const BAR_KEYS = [AUDIENCE_COVERAGE_KEY];
const VALUE_KEYS = [AUDIENCE_COVERAGE_KEY, EVENTS_KEY];

const getLineColor = () => LINE_COLOR;
const getBarColor = () => BAR_COLOR;

const GridItem = (props: GridComponentProps) => (
  <DynamicTooltipGridItem
    {...props}
    withLine={false}
    keys={BAR_KEYS}
    method="top"
  />
)

export interface Props {
  data: SegmentData;
  range: DateRange;
}

const IndexAxisComponent = memo((props: IndexAxisProps) => (
  <AxisWithGaps
    {...props}
    stroke={ColorsAttributeDividerDark}
    strokeOpacity={1}
  />
));

const Chart = ({ data, range }: Props) => {
  const chartData = useSegmentChartData(data);

  const [tickCount, pageviewsDomain, audienceCoverageDomain] = useMemo(() => {
    const lineDomain = niceDomain(getDomain(chartData, LINE_KEYS), 4);
    const barDomain = niceDomain(getDomain(chartData, BAR_KEYS), 5);

    return fitDomains(
      [lineDomain[0], Math.max(lineDomain[1], 5)],
      barDomain[1] > 1 ? [0, 1] : barDomain,
    );
  }, [chartData]);

  const AxisComponent = useCallback(
    (props: ValueAxisProps) => (
      <TwoValueAxis
        {...props}
        tickPadding={VALUE_TICK_PADDING}
        stroke={ColorsAttributeDividerLight}
        tickValueColor={ColorsTypeMain}
        leftKeys={BAR_KEYS}
        rightKeys={LINE_KEYS}
        smartTicks={false}
        rightTickFormat={rightValueTickFormat}
        leftTickFormat={leftValueTickFormat}
        strokeOpacity={(value: Tick) => (value.index === 0 ? 0 : 1)}
      />
    ),
    [],
  );

  return (
    <ChartContainer data-tour="chart">
      <ChartComponent padding={CHART_PADDING} data={chartData} indexKey="index">
        <Grid GridComponent={GridItem} />
        <Bar
          getColor={getBarColor}
          domain={audienceCoverageDomain}
          keys={BAR_KEYS}
          barPaddingRatio={BAR_PADDING_RATIO}
          animate
          BarComponent={StyledBar}
        />
        <Line
          getColor={getLineColor}
          domain={pageviewsDomain}
          keys={LINE_KEYS}
          animate
        />
        <ValueAxis
          keys={VALUE_KEYS}
          tickCount={tickCount}
          position="left"
          AxisComponent={AxisComponent}
        />
        <IndexAxis
          AxisComponent={IndexAxisComponent}
          position="bottom"
          tickFormat={indexTickFormat(range?.historyResolution)}
          animate
        />
        <ChartTooltip timeFormat={tooltipTimeFormat(range.historyResolution)} />
      </ChartComponent>
    </ChartContainer>
  );
};

export default Chart;
