import React from 'react';

const BarSkeleton = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 339 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <rect
      x="31"
      y="39"
      width="9"
      height="39"
      transform="rotate(-180 31 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="20"
      y="39"
      width="9"
      height="17"
      transform="rotate(-180 20 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="9"
      y="39"
      width="9"
      height="15"
      transform="rotate(-180 9 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="42"
      y="39"
      width="9"
      height="17"
      transform="rotate(-180 42 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="53"
      y="39"
      width="9"
      height="15"
      transform="rotate(-180 53 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="64"
      y="39"
      width="9"
      height="29"
      transform="rotate(-180 64 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="75"
      y="39"
      width="9"
      height="23"
      transform="rotate(-180 75 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="86"
      y="39"
      width="9"
      height="33"
      transform="rotate(-180 86 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="97"
      y="39"
      width="9"
      height="29"
      transform="rotate(-180 97 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="108"
      y="39"
      width="9"
      height="33"
      transform="rotate(-180 108 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="119"
      y="39"
      width="9"
      height="29"
      transform="rotate(-180 119 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="130"
      y="39"
      width="9"
      height="23"
      transform="rotate(-180 130 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="141"
      y="39"
      width="9"
      height="29"
      transform="rotate(-180 141 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="152"
      y="39"
      width="9"
      height="33"
      transform="rotate(-180 152 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="163"
      y="39"
      width="9"
      height="29"
      transform="rotate(-180 163 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="174"
      y="39"
      width="9"
      height="23"
      transform="rotate(-180 174 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="185"
      y="39"
      width="9"
      height="23"
      transform="rotate(-180 185 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="196"
      y="39"
      width="9"
      height="15"
      transform="rotate(-180 196 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="207"
      y="39"
      width="9"
      height="29"
      transform="rotate(-180 207 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="218"
      y="39"
      width="9"
      height="15"
      transform="rotate(-180 218 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="229"
      y="39"
      width="9"
      height="33"
      transform="rotate(-180 229 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="240"
      y="39"
      width="9"
      height="29"
      transform="rotate(-180 240 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="251"
      y="39"
      width="9"
      height="23"
      transform="rotate(-180 251 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="262"
      y="39"
      width="9"
      height="19"
      transform="rotate(-180 262 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="273"
      y="39"
      width="9"
      height="9"
      transform="rotate(-180 273 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="284"
      y="39"
      width="9"
      height="13"
      transform="rotate(-180 284 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="295"
      y="39"
      width="9"
      height="25"
      transform="rotate(-180 295 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="306"
      y="39"
      width="9"
      height="17"
      transform="rotate(-180 306 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="317"
      y="39"
      width="9"
      height="21"
      transform="rotate(-180 317 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="328"
      y="39"
      width="9"
      height="19"
      transform="rotate(-180 328 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
    <rect
      x="339"
      y="39"
      width="9"
      height="9"
      transform="rotate(-180 339 39)"
      fill="#0A0E12"
      fillOpacity="0.05"
    />
  </svg>
);

export default BarSkeleton;
