import React, { useCallback, useMemo } from 'react';
import { useTranslation, getI18n } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import Icon from '@piano-reports/ui-kit/src/Icon';
import { CardList, Card } from '@piano-reports/ui-kit/src/CardSelector';
import '@piano-dc/react/checkbox';
import Typography from '@piano-dc/react/typography';
import withClass from '@piano-react/components/withClass';
import SidebarPanel from '../SidebarPanel';
import useReport from '../../hooks/useReport';
import usePresets from '../../hooks/usePresets';
import {
  isPresetSelected,
  getPresetCardTitle,
  getLangPart,
} from '../../utils/presets';
import { Report } from '../../interfaces';
import usePresetsStore from '../../hooks/usePresetsStore';
import styles from './PresetsList.module.css';

const GroupTitle = withClass(Typography, styles.groupTitle);

export interface Props {
  onBackClick(): void;
}

const PresetsList = React.memo(({ onBackClick }: Props) => {
  const { t } = useTranslation();
  const i18n = getI18n();
  const lang = getLangPart(i18n.language);
  const { setPreset } = usePresetsStore();

  const { report: currentPreset, setReport } = useReport();
  const presets = usePresets();

  const groupedPresets = useMemo<Record<string, Report[]>>(
    () =>
      groupBy(presets, (item) =>
        item.date ? 'savedCustomCriteria' : 'pianoPresets',
      ),
    [presets],
  );

  const handleCardClick = useCallback(
    (report: Report) => {
      if (currentPreset) {
        setPreset(currentPreset);
      }
      setReport(report);
      onBackClick();
    },
    [setPreset, onBackClick, setReport, currentPreset],
  );

  return (
    <SidebarPanel
      icon={<Icon name="arrow-left" />}
      onIconClick={onBackClick}
      header={
        <Typography variant="multicompact-15-pt">
          {t('customize.selectPreset', 'Select a preset')}
        </Typography>
      }
    >
      <div className={styles.content}>
        {Object.keys(groupedPresets).map((groupName) => (
          <div key={groupName}>
            <GroupTitle variant="single-9-5-pt">
              {t(`customize.${groupName}`, 'Piano presets')}
            </GroupTitle>
            <CardList>
              {groupedPresets[groupName].map((report) => (
                <Card
                  key={report.date?.toString() || report.name}
                  selected={isPresetSelected(report, currentPreset)}
                  onClick={() => handleCardClick(report)}
                  title={getPresetCardTitle(report, lang)}
                  description={report.description}
                  tooltip={t('customize.selectPresetTip', 'Select preset')}
                />
              ))}
            </CardList>
          </div>
        ))}
      </div>
    </SidebarPanel>
  );
});

export default PresetsList;
