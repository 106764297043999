import React from 'react';
import Typography from '@piano-dc/react/typography';
import formatPercent from '@piano-react/utils/numberFormat/formatPercent';
import formatInteger from '@piano-react/utils/numberFormat/formatInteger';
import { SegmentData } from '../../../api/useSegmentData';
import { DateRange } from '../../../types';
import styles from './Singletons.module.css';

export interface Props {
  data: SegmentData;
  range: DateRange;
}

const AudienceCoverageDescription = ({ data }: Props) => {
  return typeof data.data.audienceCoverage !== 'undefined' ? (
    <Typography variant="multicompact-13-pt" className={styles.description}>
      <span className={styles.value}>
        {formatPercent(data.data.audienceCoverage)}
        {data.data.uniqueUsers
          ? ` (${formatInteger(data.data.uniqueUsers)}) `
          : ''}
      </span>{' '}
      of your total audience fit the selected segmentation criteria.
    </Typography>
  ) : null;
};

export default AudienceCoverageDescription;
