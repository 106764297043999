import React from 'react';
import { DateRange, Segment } from '../../../types';
import useSegments from '../../../api/useSegments';
import SegmentSummaryData from '../SegmentSummaryData';

export interface Props {
  segmentIds?: string[];
  segments?: Segment[];
  filterSegmentId?: string;
  range: DateRange;
}

const SegmentSummaryContent = React.memo(
  ({ segmentIds, segments: selectedSegments, ...rest }: Props) => {
    const segments = useSegments({ segments: selectedSegments, segmentIds });

    return <SegmentSummaryData segments={segments as Segment[]} {...rest} />;
  },
);

export default SegmentSummaryContent;
