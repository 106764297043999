import React from 'react';
import withClass from '@piano-react/components/withClass';
import { Report, SegmentsView } from '../../interfaces';
import useSegmentsView from '../../hooks/useSegmentsView';
import SegmentGrid from '../SegmentGrid';
import SegmentTable from '../SegmentTable';
import styles from './Segments.module.css';

export const Container = withClass.div(styles.container);

export interface Props {
  report: Report;
}

const Segments = React.memo(({ report }: Props) => {
  const { view } = useSegmentsView();

  return (
    <Container>
      {view === SegmentsView.GRID ? (
        <SegmentGrid report={report} />
      ) : (
        <SegmentTable
          segments={report.segments}
          filterSegment={report.filter}
        />
      )}
    </Container>
  );
});

export default Segments;
