import React from 'react';
import Typography from '@piano-dc/react/typography';
import Icon from '@piano-reports/ui-kit/src/Icon';
import styles from './ReportDescription.module.css';

export interface Props {
  children: React.ReactNode;
  onClick(): void;
}

const PresetSelect = ({ children, onClick }: Props) => (
  <div className={styles.select} onClick={onClick}>
    <Typography variant="multicompact-15-pt" className={styles.text}>
      {children}
    </Typography>
    <Icon className={styles.icon} name="chevron-down" data-tour="preset" />
  </div>
);

export default PresetSelect;
