import React from 'react';
import { IndexAxisProps } from '@piano-insight/charts/components/IndexAxis/AxisComponent';

const EmptyAxis = (props: IndexAxisProps) => {
  const x = props.paddingLeft;
  const y = props.containerHeight - props.paddingBottom;

  const lineSize =
    props.containerWidth - props.paddingRight - props.paddingLeft;

  return (
    <g transform={`translate(${x}, ${y})`}>
      <path
        d={`M0,0 h${lineSize}`}
        stroke={props.stroke}
        strokeWidth={props.strokeWidth}
        strokeOpacity={props.strokeOpacity}
      />
    </g>
  );
};

export default EmptyAxis;
