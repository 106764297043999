import { useEffect, useState, RefObject } from 'react';

function useSticky(ref: RefObject<HTMLElement>, top: number) {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const { width, height, top: nodeTop } = ref.current.getBoundingClientRect();
        setSticky((width !== 0 || height !== 0) && nodeTop <= top);
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref, setSticky, top]);

  return {
    isSticky,
    bounds: ref.current?.getBoundingClientRect() ?? {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    },
  };
}

export default useSticky;
