import { useRef, useContext, useEffect } from 'react';
import GlobalSettingsContext, {
  GlobalSettings,
} from '../components/GlobalSettings/GlobalSettingsContext';
import { getLocalStorageSettingsKey } from '../utils/settings';
import { isTargetMode } from '../utils/vxMessages';
import usePresetsStore from './usePresetsStore';
import useScopeSettings from './useScopeSettings';

export type SettingsGetter = () => GlobalSettings;

export default function useSettingsSaver() {
  const values = useContext(GlobalSettingsContext);
  const scopeSettings = useScopeSettings();
  const settings = useRef<SettingsGetter | null>(null);
  const { setPreset } = usePresetsStore();

  settings.current = () => ({
    report: values.report,
    range: values.range,
    view: values.view,
    sorting: values.sorting,
  });

  useEffect(() => {
    const handleUnload = () => {
      if (settings.current) {
        const currentSettings = settings.current();

        if (!isTargetMode()) {
          window.localStorage.setItem(
            getLocalStorageSettingsKey(
              scopeSettings.userId,
              scopeSettings.scope,
            ),
            JSON.stringify(currentSettings),
          );
        } else if (currentSettings.report) {
          setPreset(currentSettings.report);
        }
      }
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [setPreset, settings, scopeSettings.userId, scopeSettings.scope]);
}
