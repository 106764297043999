import { createContext } from 'react';

export interface ScopeSettingsContext {
  userId: string;
  scope: string;
}

const GlobalSettingsContext = createContext<ScopeSettingsContext>({
  userId: '',
  scope: '',
});

export default GlobalSettingsContext;
