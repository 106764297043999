import React from 'react';
import withClass from '@piano-react/components/withClass';
import Typography from '@piano-dc/react/typography';
import { useTranslation } from 'react-i18next';
import { DroplistTriggerProps } from '@piano-dc/react/popover/types';
import { GhostSelect } from '@piano-dc/react/select-ghost';
import useLocalizedSortingVariants from './useLocalizedSortingVariants';
import styles from './SortingTrigger.module.css';

const Text = withClass(Typography, styles.text);

export interface Props extends DroplistTriggerProps {
  field: string | null;
}

const SortingTrigger = ({ field, ...rest }: Props) => {
  const { t } = useTranslation();
  const translateVariant = useLocalizedSortingVariants();

  return (
    <Text {...rest}>
      {t('sortingPanel.sortedBy', 'Sorted by')}{' '}
      <GhostSelect>{translateVariant(field)}</GhostSelect>
    </Text>
  )
}

export default SortingTrigger;
