import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import union from 'lodash/union';
import { AnimatePresence } from 'framer-motion';
import Icon from '@piano-reports/ui-kit/src/Icon';
import { Segment } from '@piano-reports/summary-widgets/src/types';
import '@piano-dc/react/checkbox';
import SidebarPanel from '../SidebarPanel';
import useReportCustomize from '../../hooks/useReportCustomize';
import PresetSelect from '../ReportDescription/PresetSelect';
import { BRAND_RELATIONSHIP } from '../../constants';
import { categoriesToDisplay } from '../../hooks/useSegmentGroups';
import SegmentGroupItem from './SegmentGroups';
import BrandRelationship from './BrandRelationship';
import AddGroup from './AddGroup';
import groupKeysStore from './GroupKeyStorage';
import { Block } from './Block';
import styles from './CustomizeCriteria.module.css';

export interface GroupWithKey {
  id: string;
  key: string;
}

export interface Props {
  onBackClick(): void;
  onHeaderClick(): void;
}

const CustomizeCriteria = React.memo(
  ({ onBackClick, onHeaderClick }: Props) => {
    const {
      report,
      addSegment,
      addSegments,
      removeSegment,
      removeSegmentGroup,
    } = useReportCustomize();
    const [listModified, setListModified] = useState<boolean>(false);
    const [needScroll, setNeedScroll] = useState<boolean>(false);
    const buttonRef = useRef<HTMLDivElement>(null);

    const [groupsToDisplay, setGroups] = useState<GroupWithKey[]>(() =>
      uniqBy(
        (report?.segments ?? [])
          .map((segment) => ({
            id: segment.group ?? '',
            key: groupKeysStore.getKey(segment.group ?? ''),
          }))
          .filter((group) => group.id !== BRAND_RELATIONSHIP),
        (g) => g.id,
      ),
    );

    const [brandRelationship, groupedSegments] = useMemo(() => {
      const filteredSegments = report?.segments.filter(
        (segment) =>
          segment.category && categoriesToDisplay.includes(segment.category),
      );
      const grouped = groupBy(filteredSegments, 'group');

      const brandRelGroup = grouped[BRAND_RELATIONSHIP];

      return [brandRelGroup ?? [], grouped];
    }, [report]) as [Segment[], Record<string, Segment[]>];

    const addGroup = useCallback(
      (group: string) => {
        setGroups((groups) =>
          union(groups, [{ id: group, key: groupKeysStore.getKey(group) }]),
        );
        setListModified(true);
        setNeedScroll(true);
      },
      [setGroups, setListModified, setNeedScroll],
    );

    const replaceGroup = useCallback(
      (prevGroup: string, newGroup: string) => {
        groupKeysStore.replaceKey(prevGroup, newGroup);
        setGroups((groups) =>
          uniqBy(
            groups.map((g) =>
              g.id === prevGroup
                ? { id: newGroup, key: groupKeysStore.getKey(newGroup) }
                : g,
            ),
            (g) => g.id,
          ),
        );
        removeSegmentGroup(prevGroup);
      },
      [setGroups, removeSegmentGroup],
    );

    const removeGroupAndDeleteField = useCallback(
      (group: string) => {
        removeSegmentGroup(group);
        setGroups((groups) => groups.filter((g) => g.id !== group));
      },
      [removeSegmentGroup, setGroups],
    );

    useEffect(() => {
      if (needScroll) {
        buttonRef.current?.scrollIntoView();
        setNeedScroll(false);
      }
    }, [groupsToDisplay, needScroll, buttonRef]);

    return (
      <SidebarPanel
        icon={<Icon name="arrow-left" />}
        onIconClick={onBackClick}
        header={
          <PresetSelect onClick={onHeaderClick}>{report?.name}</PresetSelect>
        }
      >
        <div className={styles.content}>
          <Block>
            <BrandRelationship
              segments={brandRelationship}
              onSegmentAdd={addSegment}
              onSegmentListAdd={addSegments}
              onSegmentRemove={removeSegment}
              onGroupRemove={removeSegmentGroup}
              className={styles.field}
              selectedGroups={groupsToDisplay}
            />
          </Block>
          <AnimatePresence initial={false}>
            {groupsToDisplay.map((group) => (
              <SegmentGroupItem
                key={group.key}
                group={group}
                segments={groupedSegments[group.id] ?? []}
                onSegmentAdd={addSegment}
                onSegmentListAdd={addSegments}
                onSegmentRemove={removeSegment}
                onGroupRemove={removeSegmentGroup}
                onGroupClose={removeGroupAndDeleteField}
                onGroupReplace={replaceGroup}
                selectedGroups={groupsToDisplay}
                initiallyOpened={listModified}
              />
            ))}
          </AnimatePresence>
          <Block className={styles.field}>
            <AddGroup onSelect={addGroup} selectedGroups={groupsToDisplay} />
          </Block>
        </div>
      </SidebarPanel>
    );
  },
);

export default CustomizeCriteria;
