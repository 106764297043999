import React from 'react';
import AppHeaderSkeleton from '../AppHeader/AppHeaderSkeleton';
import SegmentsPageSkeleton from '../SegmentsPage/SegmentsPageSkeleton';

import { Backdrop } from './index';

const AppSkeleton = () => (
  <>
    <Backdrop />
    <AppHeaderSkeleton />
    <SegmentsPageSkeleton />
  </>
);

export default AppSkeleton;
