import React from 'react';
import { PageHeader, PageHeaderPanel } from '@piano-dc/react/page-header';
import TitleSkeleton from './Title/TitleSkeleton';
import { HeaderContainer, Spacer } from './index';
import CloseButton from './CloseButton';

const AppHeaderSkeleton: React.FC = () => (
  <>
    <Spacer />
    <HeaderContainer>
      <PageHeader>
        <PageHeaderPanel align="left">
          <TitleSkeleton />
        </PageHeaderPanel>
        <PageHeaderPanel align="right">
          <CloseButton />
        </PageHeaderPanel>
      </PageHeader>
    </HeaderContainer>
  </>
);

export default AppHeaderSkeleton;
