import React from 'react';
import SegmentSummaryTileSkeleton from '../SegmentSummaryTile/SegmentSummaryTileSkeleton';
import SegmentsSkeleton from '../Segments/SegmentsSkeleton';
import { Container } from './index';

const ReportSkeleton: React.FC = () => (
  <Container>
    <SegmentSummaryTileSkeleton />
    <SegmentsSkeleton />
  </Container>
);

export default ReportSkeleton;
