import React from 'react';
import SegmentSummaryDataSkeleton from './SegmentSummaryData/SegmentSummaryDataSkeleton';
import { Container } from './index';

const SegmentSummarySkeleton = ({ className }: { className?: string }) => (
  <Container className={className}>
    <SegmentSummaryDataSkeleton key="summary-data-skeleton" />
  </Container>
);

export default SegmentSummarySkeleton;
