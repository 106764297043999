import React from 'react';
import { text } from '@piano-insight/charts/components/Text';
import { ValueLabelProps } from '@piano-insight/charts/components/ValueLabels/DefaultLabel';

const LabelText = text({ color: '#3FA7F0' });

const ValueLabel = ({
  labelWidth,
  height,
  fontSize,
  getColor,
  labelKey,
  value,
}: ValueLabelProps) => {
  return (
    <LabelText
      fontSize={fontSize}
      textAnchor="start"
      alignmentBaseline="auto"
      fill={getColor(labelKey)}
      x={0}
      y={height / 2}
    >
      {value}
    </LabelText>
  );
};

export default ValueLabel;
