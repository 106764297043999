import React from 'react';
import '@piano-dc/atoms/icons/ArrowLeft.icon';
import '@piano-dc/atoms/icons/Book.icon';
import '@piano-dc/atoms/icons/ChevronBack.icon';
import '@piano-dc/atoms/icons/ChevronDown.icon';
import '@piano-dc/atoms/icons/ChevronForward.icon';
import '@piano-dc/atoms/icons/EditDefault.icon';
import '@piano-dc/atoms/icons/EditSmall.icon';
import '@piano-dc/atoms/icons/FolderDefault.icon';
import '@piano-dc/atoms/icons/NavList.icon';
import '@piano-dc/atoms/icons/NavGrid.icon';
import '@piano-dc/atoms/icons/PlusSmall.icon';
import '@piano-dc/atoms/icons/PointerDownSmall.icon';
import '@piano-dc/atoms/icons/PointerUpSmall.icon';
import '@piano-dc/atoms/icons/Refresh.icon';
import '@piano-dc/atoms/icons/ReportBarChartSmall.icon';
import '@piano-dc/atoms/icons/StatusWarningFilledDefault.icon';
import '@piano-dc/atoms/icons/UserSegment.icon';
import '@piano-dc/atoms/icons/XDefault.icon';
import '@piano-dc/atoms/icons/XSmall.icon';

export type IconType =
  | 'arrow-left'
  | 'book'
  | 'chevron-back'
  | 'chevron-down'
  | 'chevron-forward'
  | 'edit-default'
  | 'edit-small'
  | 'folder-default'
  | 'nav-list'
  | 'nav-grid'
  | 'plus-small'
  | 'pointer-down-small'
  | 'pointer-up-small'
  | 'refresh'
  | 'report-bar-chart-small'
  | 'status-warning-filled-default'
  | 'user-segment'
  | 'x-default'
  | 'x-small';

export interface Props {
  name: IconType;
  color?: string;
  width?: number;
  height?: number;
  className?: string;
  slot?: string;
}

const Icon = ({
  name,
  color = 'currentColor',
  width,
  height,
  className,
  slot,
  ...rest
}: Props) => {
  const IconComponent = `${name}-icon`;

  return (
    <IconComponent
      {...{ class: className }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      width={width}
      height={height}
      color={color}
      slot={slot}
      {...rest}
    />
  );
};

export default Icon;
